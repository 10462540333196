import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import { DIRECTION } from 'components/constants';
import createSystemTariffsModel from 'pages/Tariffs/components/models/createSystemTariffsModel';
import {
  addNewTariffToTariffGroupRequest,
  createTariffGroupRequest,
  createNewIndividualTariffRequest,
  deleteIndividualTariffRequest,
  deleteTariffFromTariffGroupRequest,
  deleteTariffGroupRequest,
  getCustomerTariffListRequest,
  getTariffFormDataRequest,
  getTariffGroupTariffListByIdRequest,
  getTariffListRequest,
  renameTariffGroupRequest,
  toggleTariffGroupAvailabilityRequest,
  updateCustomerTariffListRequest,
  updateTariffGroupRequest,
  applyCustomerTariffRequest,
  applyCustomerIndividualTariffRequest
} from 'services/requestAgent';

class TariffsStore {
  isInitialized = false;
  isLoading = false;
  isNewTariffGroupSuccessfullyCreated = false;
  isTariffGroupSuccessfullyDeleted = false;
  isNewTariffToTariffGroupSuccessfullyCreated = false;
  isNewIndividualTariffSuccessfullyCreated = false;
  isCustomerTariffListUpdated = false;
  error = null;
  tariffGroups = null;
  tariffFormData = null;
  currentTariffGroupDetails = null;
  customerTariffList = null;
  pagination = {
    sortBy: null,
    page: 0,
    size: 20,
    totalPages: 0,
    totalElements: 0,
    direction: DIRECTION.ASC
  };
  filters = {
    searchText: ''
  };
  tariffListFilters = {
    fee_type: '',
    scheduled: '',
    transactional: '',
    transaction_type: '',
    transfer_provider: '',
    transfer_type: '',
    payment_method: '',
    currency: '',
    network: '',
    enabled: false
  };

  constructor() {
    makeAutoObservable(this);
  }

  resetTariffsStore = () => {
    this.isInitialized = false;
    this.isLoading = false;
    this.isNewTariffGroupSuccessfullyCreated = false;
    this.isTariffGroupSuccessfullyDeleted = false;
    this.isNewTariffToTariffGroupSuccessfullyCreated = false;
    this.isNewIndividualTariffSuccessfullyCreated = false;
    this.isCustomerTariffListUpdated = false;
    this.error = null;
    this.tariffGroups = null;
    this.tariffFormData = null;
    this.currentTariffGroupDetails = null;
    this.customerTariffList = null;
    this.pagination = {
      sortBy: null,
      page: 0,
      size: 20,
      totalPages: 0,
      totalElements: 0,
      direction: DIRECTION.ASC
    };
    this.filters = {
      searchText: ''
    };
    this.tariffListFilters = {
      fee_type: '',
      scheduled: '',
      transactional: '',
      transaction_type: '',
      transfer_provider: '',
      transfer_type: '',
      payment_method: '',
      currency: '',
      network: '',
      enabled: false
    };
  };

  setIsLoading = (status) => {
    this.isLoading = status;
    this.error = null;
  };

  setIsNewTariffGroupSuccessfullyCreated = (status) => {
    this.isNewTariffGroupSuccessfullyCreated = status;
    if (!status) {
      this.pagination.size = 20;
    }
  };

  setIsTariffGroupSuccessfullyDeleted = (status) => {
    this.isTariffGroupSuccessfullyDeleted = status;
  };

  setIsNewTariffToTariffGroupSuccessfullyCreated = (status) => {
    this.isNewTariffToTariffGroupSuccessfullyCreated = status;
  };

  setIsNewIndividualTariffSuccessfullyCreated = (status) => {
    this.isNewIndividualTariffSuccessfullyCreated = status;
  };

  setIsCustomerTariffListUpdated = (status) => {
    this.isCustomerTariffListUpdated = status;
  };

  setTariffGroupsPage = (page) => {
    this.pagination.page = page;
  };

  setTariffGroupsPageSize = (size) => {
    this.pagination.size = size;
  };

  setFilter = (fieldName, value) => {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  };

  setTariffListFilter = (fieldName, value) => {
    this.tariffListFilters[fieldName] = value;
  };

  setSortData = (sortData) => {
    this.pagination.sortBy = sortData.sortBy;
    this.pagination.direction = sortData.direction;
  };

  setFiltersFromUrl = (params) => {
    // Copying parameters from the URL to the filters object properties
    this.filters.searchText = params.searchText || this.filters.searchText;

    // Converting pagination parameters from the URL
    this.pagination.page = parseInt(params.page) || this.pagination.page;
    this.pagination.size = parseInt(params.size) || this.pagination.size;

    // Converting sorting parameters from the URL
    this.pagination.sortBy = params.sort_column || this.pagination.sortBy;
    this.pagination.direction = params.sort_direction || this.pagination.direction;
  };

  setTariffListFiltersFromUrl = (params) => {
    const filters = { ...this.tariffListFilters };

    // Mapping URL filter parameters to corresponding properties in the 'tariffListFilters' object
    const filterParamsMapping = {
      fee_type: 'fee_type',
      scheduled: 'scheduled',
      transactional: 'transactional',
      transaction_type: 'transaction_type',
      transfer_provider: 'transfer_provider',
      transfer_type: 'transfer_type',
      payment_methods: 'payment_method',
      currency: 'currency',
      network: 'network',
      enabled: 'enabled'
    };

    // Iterating over each URL parameter and assigning its values to 'tariffListFilters'
    for (const param in params) {
      if (param in filterParamsMapping) {
        let value = params[param];

        if (value === 'true') {
          value = true;
        } else if (value === 'false') {
          value = false;
        }

        // Handle only array values as arrays, keep others as their original types
        if (Array.isArray(value)) {
          filters[filterParamsMapping[param]] = value;
        } else {
          filters[filterParamsMapping[param]] = value;
        }
      }
    }

    this.tariffListFilters = filters;
  };

  prepareTariffListFiltersParams = () => {
    const params = {};

    Object.entries(this.tariffListFilters).forEach(([key, value]) => {
      if (value || (key === 'scheduled' && value === false) || (key === 'transactional' && value === false)) {
        params[key] = value;
      }
    });

    return params;
  };

  getTariffGroupList = async (pageSize = undefined) => {
    this.setIsLoading(true);
    try {
      const {
        content: tariffGroupsList,
        number: page,
        size,
        total_elements: totalElements,
        total_pages: totalPages
      } = await getTariffListRequest(
        this.pagination.sortBy,
        this.pagination.page,
        pageSize ? pageSize : this.pagination.size,
        this.pagination.direction,
        this.filters.searchText
      );

      runInAction(() => {
        this.isInitialized = true;
        this.tariffGroups = tariffGroupsList;
        this.pagination = {
          ...this.pagination,
          page,
          size: !pageSize ? size : this.pagination.size,
          totalElements,
          totalPages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getCustomerTariffList = async (customerNumber) => {
    this.setIsLoading(true);
    try {
      const queryParams = this.prepareTariffListFiltersParams();
      const tariffList = await getCustomerTariffListRequest(customerNumber, queryParams);

      runInAction(() => {
        this.isInitialized = true;
        this.customerTariffList = tariffList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getTariffFormData = async () => {
    this.setIsLoading(true);
    try {
      const tariffFormData = await getTariffFormDataRequest();

      runInAction(() => {
        this.tariffFormData = tariffFormData;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  toggleTariffGroupAvailability = async (tariffGroupId, status) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(toggleTariffGroupAvailabilityRequest(tariffGroupId, status), {
      pending: i18n.getMessage('tariffsStore.toggleTariffGroupAvailability.toastPromise.pending'),
      success: i18n.getMessage('tariffsStore.toggleTariffGroupAvailability.toastPromise.success')
    });

    try {
      const tariffGroupDetails = await toastPromise;

      const updatedTariffGroupsList = this.tariffGroups.map((tariffGroup) =>
        tariffGroup.tariffGroupId === tariffGroupId ? tariffGroupDetails : tariffGroup
      );

      runInAction(() => {
        this.tariffGroups = updatedTariffGroupsList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getTariffGroupTariffListById = async (tariffGroupId) => {
    this.setIsLoading(true);
    try {
      const queryParams = this.prepareTariffListFiltersParams();
      const tariffGroupTariffList = await getTariffGroupTariffListByIdRequest(tariffGroupId, queryParams);

      const currentTariffGroupDetails = this.tariffGroups.find((group) => group.tariffGroupId === tariffGroupId);

      currentTariffGroupDetails.tariffs = tariffGroupTariffList;

      runInAction(() => {
        this.isInitialized = true;
        this.currentTariffGroupDetails = currentTariffGroupDetails;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  createTariffGroup = async (data) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(createTariffGroupRequest(data), {
      pending: i18n.getMessage('tariffsStore.createTariffGroup.toastPromise.pending'),
      success: i18n.getMessage('tariffsStore.createTariffGroup.toastPromise.success')
    });

    const newTariffGroup = await toastPromise;

    try {
      runInAction(() => {
        this.currentTariffGroupDetails = newTariffGroup;
        this.isNewTariffGroupSuccessfullyCreated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  renameTariffGroup = async (tariffGroupId, data) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(renameTariffGroupRequest(tariffGroupId, data), {
      pending: i18n.getMessage('tariffsStore.renameTariffGroup.toastPromise.pending'),
      success: i18n.getMessage('tariffsStore.renameTariffGroup.toastPromise.success')
    });

    try {
      const updatedTariffGroupDetails = await toastPromise;

      const updatedTariffGroupList = this.tariffGroups.map((tariffGroup) =>
        tariffGroup.tariffGroupId === tariffGroupId ? updatedTariffGroupDetails : tariffGroup
      );

      runInAction(() => {
        this.tariffGroups = updatedTariffGroupList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  deleteTariffGroup = async (tariffGroupId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(deleteTariffGroupRequest(tariffGroupId), {
      pending: i18n.getMessage('tariffsStore.deleteTariffGroup.toastPromise.pending'),
      success: i18n.getMessage('tariffsStore.deleteTariffGroup.toastPromise.success')
    });

    try {
      await toastPromise;

      const updatedTariffGroupList = this.tariffGroups.filter(
        (tariffGroup) => tariffGroup.tariffGroupId !== tariffGroupId
      );

      runInAction(() => {
        this.tariffGroups = updatedTariffGroupList;
        this.isTariffGroupSuccessfullyDeleted = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  updateTariffGroup = async (tariffGroupId, tariffs) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateTariffGroupRequest(tariffGroupId, tariffs), {
      pending: i18n.getMessage('tariffsStore.updateTariffGroup.toastPromise.pending'),
      success: i18n.getMessage('tariffsStore.updateTariffGroup.toastPromise.success')
    });

    try {
      const updatedTariffGroupTariffsList = await toastPromise;

      runInAction(() => {
        this.currentTariffGroupDetails.tariffs = updatedTariffGroupTariffsList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  updateCustomerTariffList = async (customerNumber, tariffs) => {
    this.setIsLoading(true);

    const data = {
      createTariffs: [],
      createSystemTariffs: [],
      updateTariffs: []
    };

    if (tariffs && tariffs?.length > 0) {
      tariffs.forEach((tariff) => {
        if (tariff?.isUpdatedTariff && !tariff?.systemTariffKey && !tariff?.individualTariff) {
          data.createTariffs.push(tariff);
        } else if (tariff?.isUpdatedTariff && tariff?.systemTariffKey && !tariff?.individualTariff) {
          data.createSystemTariffs.push(createSystemTariffsModel(tariff));
        } else if (tariff?.isUpdatedTariff && tariff?.individualTariff) {
          data.updateTariffs.push(tariff);
        }
      });
    }

    const toastPromise = toast.promise(updateCustomerTariffListRequest(customerNumber, data), {
      pending: i18n.getMessage('tariffsStore.updateCustomerTariffList.toastPromise.pending'),
      success: i18n.getMessage('tariffsStore.updateCustomerTariffList.toastPromise.success')
    });

    try {
      await toastPromise;

      runInAction(() => {
        this.isCustomerTariffListUpdated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  deleteTariffFromTariffGroup = async (tariffGroupId, tariffId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(deleteTariffFromTariffGroupRequest(tariffGroupId, tariffId), {
      pending: i18n.getMessage('tariffsStore.deleteTariffFromTariffGroup.toastPromise.pending'),
      success: i18n.getMessage('tariffsStore.deleteTariffFromTariffGroup.toastPromise.success')
    });

    try {
      await toastPromise;

      const updatedTariffList = this.currentTariffGroupDetails.tariffs.filter((tariff) => tariff.id !== tariffId);

      runInAction(() => {
        this.currentTariffGroupDetails.tariffs = updatedTariffList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  deleteIndividualTariff = async (customerNumber, tariffId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(deleteIndividualTariffRequest(customerNumber, tariffId), {
      pending: i18n.getMessage('tariffsStore.deleteIndividualTariff.toastPromise.pending'),
      success: i18n.getMessage('tariffsStore.deleteIndividualTariff.toastPromise.success')
    });

    try {
      await toastPromise;

      const updatedTariffList = this.customerTariffList.filter((tariff) => tariff.id !== tariffId);

      runInAction(() => {
        this.customerTariffList = updatedTariffList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  addNewTariffToTariffGroup = async (tariffGroupId, data) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(addNewTariffToTariffGroupRequest(tariffGroupId, data), {
      pending: i18n.getMessage('tariffsStore.addNewTariffToTariffGroup.toastPromise.pending'),
      success: i18n.getMessage('tariffsStore.addNewTariffToTariffGroup.toastPromise.success')
    });

    try {
      const newTariff = await toastPromise;

      runInAction(() => {
        this.currentTariffGroupDetails.tariffs = [...this.currentTariffGroupDetails.tariffs, newTariff];
        this.isNewTariffToTariffGroupSuccessfullyCreated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  createNewIndividualTariff = async (customerNumber, data) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(createNewIndividualTariffRequest(customerNumber, data), {
      pending: i18n.getMessage('tariffsStore.createNewIndividualTariff.toastPromise.pending'),
      success: i18n.getMessage('tariffsStore.createNewIndividualTariff.toastPromise.success')
    });

    try {
      const newTariff = await toastPromise;

      runInAction(() => {
        this.customerTariffList = [newTariff, ...this.customerTariffList];
        this.isNewIndividualTariffSuccessfullyCreated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  applyCustomerTariff = async (customerNumber, tariffId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(applyCustomerTariffRequest(customerNumber, tariffId), {
      pending: i18n.getMessage('tariffsStore.applyCustomerTariff.toastPromise.pending'),
      success: i18n.getMessage('tariffsStore.applyCustomerTariff.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  applyCustomerIndividualTariff = async (customerNumber, tariffId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(applyCustomerIndividualTariffRequest(customerNumber, tariffId), {
      pending: i18n.getMessage('tariffsStore.applyCustomerIndividualTariff.toastPromise.pending'),
      success: i18n.getMessage('tariffsStore.applyCustomerIndividualTariff.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}

export default new TariffsStore();
