import { useContext, useEffect } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toCommissionDto, toReturnDto, toWithdrawDto } from './components/CommissionTransactionModel';
import {
  AMOUNT_INPUT_VALUE_TYPE,
  COMMISSION_TRANSACTION_TYPE,
  MAX_COMMISSION_TRANSACTION_COMMENT_LENGTH,
  MAX_TRANSACTION_COMMENT_LENGTH,
  SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT,
  SEARCH_BOUND_MINIMUM_LENGTH_SYSTEM_ACCOUNT,
  SYSTEM_ACCOUNT_TYPE
} from 'components/constants';
import CustomAsyncSelect from 'components/CustomAsyncSelect';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { getAccountsRequest, getSystemAccountsRequest } from 'services/requestAgent';
import AmountInput from 'uikit/AmountInput';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './CommissionTransaction.scss';

const CommissionTransaction = ({ commissionTransactionsStore }) => {
  const i18n = useContext(i18nContext);

  const form = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      commissionTransactionType: COMMISSION_TRANSACTION_TYPE.RETURN,
      systemAccount: null,
      recipientAccount: null,
      amount: '',
      currency: '',
      comment: ''
    },
    validationSchema: Yup.object({
      systemAccount: Yup.object().when('commissionTransactionType', {
        is: (has) => has === COMMISSION_TRANSACTION_TYPE.RETURN || has === COMMISSION_TRANSACTION_TYPE.WITHDRAW,
        then: () => Yup.object().required(i18n.getMessage('createTransaction.validation.isRequired')),
        otherwise: () => Yup.object().nullable().notRequired()
      }),
      recipientAccount: Yup.object().when('commissionTransactionType', {
        is: (has) => has === COMMISSION_TRANSACTION_TYPE.RETURN || has === COMMISSION_TRANSACTION_TYPE.COMMISSION,
        then: () => Yup.object().required(i18n.getMessage('createTransaction.validation.isRequired')),
        otherwise: () => Yup.object().nullable().notRequired()
      }),
      amount: Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
      currency: Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
      comment: Yup.object().when('commissionTransactionType', {
        is: (has) => has === COMMISSION_TRANSACTION_TYPE.RETURN,
        then: () =>
          Yup.string()
            .required(i18n.getMessage('createTransaction.validation.isRequired'))
            .max(
              140,
              i18n.getMessage('createTransaction.validation.maxSize', { size: MAX_TRANSACTION_COMMENT_LENGTH })
            ),
        otherwise: () =>
          Yup.string()
            .required(i18n.getMessage('createTransaction.validation.isRequired'))
            .max(
              255,
              i18n.getMessage('createTransaction.validation.maxSize', {
                size: MAX_COMMISSION_TRANSACTION_COMMENT_LENGTH
              })
            )
      })
    }),
    onSubmit: async (values) => {
      if (values.commissionTransactionType === COMMISSION_TRANSACTION_TYPE.RETURN) {
        return commissionTransactionsStore.createCommissionReturnTransaction(toReturnDto(values));
      } else if (values.commissionTransactionType === COMMISSION_TRANSACTION_TYPE.WITHDRAW) {
        return commissionTransactionsStore.createCommissionWithdrawTransaction(toWithdrawDto(values));
      } else if (values.commissionTransactionType === COMMISSION_TRANSACTION_TYPE.COMMISSION) {
        return commissionTransactionsStore.createCommissionTransaction(toCommissionDto(values));
      }
    }
  });

  const { values, errors, handleSubmit, handleChange, setFieldValue, resetForm } = form;

  useEffect(() => {
    if (commissionTransactionsStore.isNewCommissionTransactionCreated) {
      resetForm();
      commissionTransactionsStore.setIsNewCommissionTransactionCreated(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commissionTransactionsStore.isNewCommissionTransactionCreated]);

  const commissionTransactionTypesOptions = Object.keys(COMMISSION_TRANSACTION_TYPE).map((type) => {
    return {
      key: COMMISSION_TRANSACTION_TYPE[type],
      value: i18n.getMessage('commissionTransactionType.' + COMMISSION_TRANSACTION_TYPE[type])
    };
  });

  const handleChangeValue = (name, value) => {
    return setFieldValue(name, value);
  };

  const handleChangeCommissionTransactionType = (name, type) => {
    if (type !== values.commissionTransactionType) {
      resetForm();
      setFieldValue('commissionTransactionType', type);
    }
  };

  const handleChangeSystemAccountOption = (account) => {
    handleChangeValue('systemAccount', account.value);
    handleChangeValue('currency', account.value.currency);
  };

  const handleChangeRecipientAccountOption = (account) => {
    handleChangeValue('recipientAccount', account.value);

    if (values.commissionTransactionType === COMMISSION_TRANSACTION_TYPE.COMMISSION) {
      handleChangeValue('currency', account.value.currency);
    }
  };

  const noOptionsMessage = () => i18n.getMessage('createTransaction.commission.accountSelect.noAccountsFound');
  const loadingMessage = ({ inputValue }) =>
    inputValue.trim()?.length < SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT
      ? i18n.getMessage('createTransaction.commission.accountSelect.searchWillStartWith') +
        SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT +
        i18n.getMessage('createTransaction.commission.accountSelect.characters')
      : i18n.getMessage('createTransaction.commission.accountSelect.searching');

  const systemAccountsLoadingMessage = ({ inputValue }) =>
    inputValue.trim()?.length < SEARCH_BOUND_MINIMUM_LENGTH_SYSTEM_ACCOUNT
      ? i18n.getMessage('createTransaction.commission.accountSelect.searchWillStartWith') +
        SEARCH_BOUND_MINIMUM_LENGTH_SYSTEM_ACCOUNT +
        i18n.getMessage('createTransaction.commission.accountSelect.characters')
      : i18n.getMessage('createTransaction.commission.accountSelect.searching');

  const systemAccountLoadOptions = async (searchText) => {
    let systemAccounts = null;
    if (searchText?.length >= SEARCH_BOUND_MINIMUM_LENGTH_SYSTEM_ACCOUNT) {
      systemAccounts = await getSystemAccountsRequest(searchText, SYSTEM_ACCOUNT_TYPE.COMMISSION);
    }

    return systemAccounts
      ? Object.values(systemAccounts).map((account) => ({
          value: account,
          label: account ? `${account.account_number} ${account.amount} ${account.currency}` : ''
        }))
      : [];
  };

  const recipientAccountLoadOptions = async (searchText) => {
    let response = null;
    if (searchText?.length >= SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT) {
      response = await getAccountsRequest('iban', 0, 10, 'ASC', searchText);
    }

    return response
      ? response.content.map((account) => ({
          value: account,
          label: `${account.multi_iban || account.iban} (${account.wallet_number})`
        }))
      : [];
  };

  return (
    <div className={'commission-transaction-wrapper'}>
      <div className={'commission-transaction-type-wrapper'}>
        <InputDropDown
          className={'commission-transaction-select-transaction-type'}
          label={i18n.getMessage('createTransaction.commission.transactionType')}
          id={'commissionTransactionType'}
          name={'commissionTransactionType'}
          value={values.commissionTransactionType}
          options={commissionTransactionTypesOptions}
          onChange={handleChangeCommissionTransactionType}
        />
      </div>
      <form className={'commission-transaction-form'} onSubmit={handleSubmit}>
        <div className={'commission-transaction-row-block'}>
          {(values.commissionTransactionType === COMMISSION_TRANSACTION_TYPE.RETURN ||
            values.commissionTransactionType === COMMISSION_TRANSACTION_TYPE.WITHDRAW) && (
            <CustomAsyncSelect
              key={`system-account-${values.commissionTransactionType}`}
              isRequired
              className={'commission-transaction-account-async-select'}
              // isAutoFocus={true}
              onChange={handleChangeSystemAccountOption}
              loadList={systemAccountLoadOptions}
              handleInputChange={handleChangeValue}
              name={'systemAccount'}
              noOptionsMessage={noOptionsMessage}
              loadingMessage={systemAccountsLoadingMessage}
              label={i18n.getMessage('createTransaction.commission.fromCommissionAccount')}
              placeholder={i18n.getMessage('createTransaction.commission.accountSelect.placeholder')}
              error={errors?.systemAccount}
            />
          )}

          {(values.commissionTransactionType === COMMISSION_TRANSACTION_TYPE.RETURN ||
            values.commissionTransactionType === COMMISSION_TRANSACTION_TYPE.COMMISSION) && (
            <CustomAsyncSelect
              key={`recipient-account-${values.commissionTransactionType}`}
              isRequired
              className={'commission-transaction-account-async-select'}
              // isAutoFocus={true}
              onChange={handleChangeRecipientAccountOption}
              loadList={recipientAccountLoadOptions}
              handleInputChange={handleChangeValue}
              name={'recipientAccount'}
              noOptionsMessage={noOptionsMessage}
              loadingMessage={loadingMessage}
              label={
                values.commissionTransactionType === COMMISSION_TRANSACTION_TYPE.RETURN
                  ? i18n.getMessage('createTransaction.commission.toCustomerAccount')
                  : i18n.getMessage('createTransaction.commission.fromCustomerAccount')
              }
              placeholder={i18n.getMessage('createTransaction.commission.accountSelect.placeholder')}
              error={errors?.recipientAccount}
            />
          )}
        </div>

        <div className={'commission-transaction-row-block'}>
          <AmountInput
            isRequired
            className={'commission-transaction-input'}
            label={i18n.getMessage('createTransaction.commission.amount.label')}
            placeholder={i18n.getMessage('createTransaction.commission.amount.placeholder')}
            name={'amount'}
            value={values?.amount}
            returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
            onChange={handleChange}
            error={errors?.amount}
          />

          <Input
            isRequired
            className={'commission-transaction-select'}
            label={i18n.getMessage('createTransaction.commission.currency')}
            id={'currency'}
            name={'currency'}
            value={values?.currency}
            onChange={handleChange}
            error={errors?.currency}
            isDisabled={true}
          />
        </div>

        <div className={'commission-transaction-row-block'}>
          <Input
            isRequired
            type={'textarea'}
            rows={2}
            className={'commission-transaction-textarea'}
            label={i18n.getMessage('createTransaction.commission.comment')}
            id={'comment'}
            name={'comment'}
            value={values.comment}
            onChange={handleChange}
            error={errors?.comment}
            max={
              values.commissionTransactionType === COMMISSION_TRANSACTION_TYPE.RETURN
                ? MAX_TRANSACTION_COMMENT_LENGTH
                : MAX_COMMISSION_TRANSACTION_COMMENT_LENGTH
            }
            subText={i18n.getMessage('createTransaction.commission.symbolsLeft', {
              symbolsLeft:
                values.commissionTransactionType === COMMISSION_TRANSACTION_TYPE.RETURN
                  ? MAX_TRANSACTION_COMMENT_LENGTH - values.comment?.length
                  : MAX_COMMISSION_TRANSACTION_COMMENT_LENGTH - values.comment?.length
            })}
          />
        </div>

        <div className={'commission-transaction-submit-button-wrapper'}>
          <Button
            className={'commission-transaction-submit-button'}
            type={'primary'}
            roleType={'submit'}
            size={'large'}
            onClick={() => {}}
            isDisabled={commissionTransactionsStore.isLoading}
          >
            {commissionTransactionsStore.isLoading ? (
              <Loader />
            ) : (
              i18n.getMessage('createTransaction.commission.button.createTransaction')
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

CommissionTransaction.propTypes = {
  commissionTransactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  commissionTransactionsStore: stores.commissionTransactionsStore
}))(observer(CommissionTransaction));
