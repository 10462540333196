import * as Yup from 'yup';
import { countryCodeRegExp, regularFieldRegExp } from 'services/utils';
import i18n from 'i18n';

const requiredMessage = i18n.getMessage('customer.validation.isRequired');
const invalidFormatMessage = i18n.getMessage('customer.validation.invalidFormat');
const maxSizeMessage = (size) => {
  return i18n.getMessage('customer.validation.maxSize', { size });
};

const addressValidator = Yup.object().shape({
  address1: Yup.string()
    .required(requiredMessage)
    .matches(regularFieldRegExp, invalidFormatMessage)
    .max(50, maxSizeMessage(50)),
  address2: Yup.string()
    .required(requiredMessage)
    .matches(regularFieldRegExp, invalidFormatMessage)
    .max(50, maxSizeMessage(50)),
  zipCode: Yup.string()
    .required(requiredMessage)
    .matches(regularFieldRegExp, invalidFormatMessage)
    .max(10, maxSizeMessage(10)),
  city: Yup.string()
    .required(requiredMessage)
    .matches(regularFieldRegExp, invalidFormatMessage)
    .max(50, maxSizeMessage(50)),
  country: Yup.string().required(requiredMessage).length(2).matches(countryCodeRegExp, invalidFormatMessage).uppercase()
});

const memberValidator = Yup.object().shape({
  name: Yup.string().required(requiredMessage).max(50, maxSizeMessage(50)),
  surname: Yup.string().required(requiredMessage).max(50, maxSizeMessage(50)),
  birthday: Yup.date().required(requiredMessage),
  passportNumber: Yup.string().required(requiredMessage).max(30, maxSizeMessage(30)),
  passportIssueCountry: Yup.string()
    .required(requiredMessage)
    .length(2)
    .matches(countryCodeRegExp, invalidFormatMessage)
    .uppercase(),
  passportIssueDate: Yup.date(),
  passportExpiryDate: Yup.date().required(requiredMessage),
  address: addressValidator,
  passportCheck: Yup.string().required(requiredMessage).oneOf(['PASS', 'FAIL']),
  sanctionList: Yup.string().required(requiredMessage).oneOf(['PASS', 'FAIL']),
  pepList: Yup.string().required(requiredMessage).oneOf(['PASS', 'FAIL']),
  blackList: Yup.string().required(requiredMessage).oneOf(['PASS', 'FAIL'])
});

const authorizedPersonValidator = memberValidator.shape({
  email: Yup.string().email(),
  beneficiary: Yup.boolean().required(requiredMessage),
  director: Yup.boolean().required(requiredMessage),
  residenceCountry: Yup.string()
    .required(requiredMessage)
    .length(2)
    .matches(countryCodeRegExp, invalidFormatMessage)
    .uppercase(),
  memberOfTheBoard: Yup.boolean().required(requiredMessage),
  representative: Yup.boolean().required(requiredMessage),
  shareholder: Yup.boolean().required(requiredMessage),
  percentOfEquity: Yup.string()
    .required(requiredMessage)
    .test(
      'min-value',
      i18n.getMessage('companyStructure.fields.error.amount.min', { size: 10 }),
      (value) => Number(value) >= 10
    )
    .test(
      'max-value',
      i18n.getMessage('companyStructure.fields.error.amount.max', { size: 100 }),
      (value) => Number(value) <= 100
    ),
  position: Yup.string().required(requiredMessage).max(255, maxSizeMessage(255)),
  accountOpeningPurpose: Yup.string().required(requiredMessage).max(255, maxSizeMessage(255))
});

const beneficiaryValidator = memberValidator.shape({
  percentOfEquity: Yup.string()
    .required(requiredMessage)
    .test(
      'min-value',
      i18n.getMessage('companyStructure.fields.error.amount.min', { size: 10 }),
      (value) => Number(value) >= 10
    )
    .test(
      'max-value',
      i18n.getMessage('companyStructure.fields.error.amount.max', { size: 100 }),
      (value) => Number(value) <= 100
    ),
  director: Yup.boolean().required(requiredMessage)
});

const directorValidator = memberValidator.shape({});

export default {
  beneficiaryValidator,
  addressValidator,
  authorizedPersonValidator,
  directorValidator
};
