import { Outlet, useLocation } from 'react-router-dom';
import BackButton from 'components/BackButton';
import { ROUTE_PATHS } from 'routes/constants';

const Tariffs = () => {
  const location = useLocation();

  return (
    <section className={'right-section'}>
      {location.pathname !== ROUTE_PATHS.TARIFFS && <BackButton />}
      <Outlet />
    </section>
  );
};

export default Tariffs;
