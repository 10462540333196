import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Table from 'react-bootstrap/Table';
import queryString from 'query-string';
import CustomerMarkupsTabFilters from './CustomerMarkupsTabFilters';
import { CustomerMarkupsTabHeadRow } from './CustomerMarkupsTabHeadRow';
import { CustomerMarkupsTabRowContent } from './CustomerMarkupsTabRowContent';
import EmptyTableContentPlug from 'components/EmptyTableContentPlug';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './MarkupsTab.scss';

const MarkupsTab = ({ markupsStore, customerNumber, customerMarkupGroupId, assignMarkupGroupToCustomer }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const [markupGroupId, setGroupId] = useState(customerMarkupGroupId);

  const updateMarkupListFiltersInUrl = () => {
    const queryParams = markupsStore.prepareMarkupsListFiltersParams();
    queryParams.tab = 'markups';

    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadMarkupFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    markupsStore.setMarkupsListFiltersFromUrl(params);
  };

  useEffect(() => {
    loadMarkupFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchMarkupGroupList = async () => {
      if (!markupsStore.isInitialized && customerNumber) {
        await markupsStore.getMarkupGroupsList(1000);
      }
    };

    fetchMarkupGroupList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markupsStore.isInitialized, customerNumber]);

  useEffect(() => {
    if (markupsStore.isInitialized) {
      markupsStore.resetMarkupsStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const fetchCustomerMarkupList = async () => {
      updateMarkupListFiltersInUrl();

      if (!customerMarkupGroupId || !markupsStore.isInitialized) return;

      await markupsStore.getMarkupGroupMarkupsListById(customerMarkupGroupId);
    };

    fetchCustomerMarkupList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerMarkupGroupId,
    markupsStore.isInitialized,
    markupsStore.markupsListFilters.from,
    markupsStore.markupsListFilters.to
  ]);

  const handleChangeCustomerMarkupGroup = async (name, value) => {
    setGroupId(value);
    await assignMarkupGroupToCustomer(value);
  };

  const handleEditMarkupGroup = () => {
    navigate(`${ROUTE_PATHS.MARKUPS_GROUPS}/${customerMarkupGroupId}`);
  };

  if (!markupsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <div className={'customer-markups-details-container'}>
      <table className={'customer-markup-group-details-table-wrapper'}>
        <tbody className={'customer-markup-group-details-table-body'}>
          <tr className={'customer-markup-group-details-table-row'}>
            <td className={'customer-markup-group-details-table-cell customer-markup-group-details-table-subsection'}>
              {i18n.getMessage('markups.details.customerId')}
            </td>
            <td className={'customer-markup-group-details-table-cell customer-markup-group-details-table-subsection'}>
              {customerNumber}
            </td>
          </tr>
          <tr className={'customer-markup-group-details-table-row'}>
            <td className={'customer-markup-group-details-table-cell'}>
              {i18n.getMessage('markups.details.markupGroup')}
            </td>
            <td className={'customer-markup-group-details-table-cell'}>
              <div className={'customer-markup-group-details-table-input-wrapper'}>
                <InputDropDown
                  className={'customer-markup-group-details-table-cell-input'}
                  id={'markupGroup'}
                  name={'markupGroup'}
                  options={markupsStore?.markupGroups?.map((group) => ({
                    key: group.markupGroupId,
                    value: group.markupGroupId
                  }))}
                  value={markupGroupId}
                  onChange={handleChangeCustomerMarkupGroup}
                  isDisabled={markupsStore.isLoading}
                />
                <Button
                  className={'customer-markup-group-details-edit-button'}
                  size={'small'}
                  type={'primary'}
                  onClick={handleEditMarkupGroup}
                >
                  {i18n.getMessage('markups.details.button.edit')}
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <CustomerMarkupsTabFilters
        isLoading={markupsStore.isLoading}
        markupListFilters={markupsStore.markupsListFilters}
        handleSetMarkupListFilters={markupsStore.setMarkupsListFilter}
      />

      {!markupsStore.currentMarkupGroupDetails ||
      !markupsStore.currentMarkupGroupDetails?.markups ||
      markupsStore.currentMarkupGroupDetails?.markups?.length === 0 ? (
        <EmptyTableContentPlug
          headerText={i18n.getMessage('markups.details.table.emptyPlug.header')}
          descriptionText={i18n.getMessage('markups.details.table.emptyPlug.description')}
        />
      ) : (
        <>
          <Table responsive>
            <thead>
              <CustomerMarkupsTabHeadRow />
            </thead>
            <tbody>
              {markupsStore.currentMarkupGroupDetails?.markups?.map((markupData, index) => (
                <CustomerMarkupsTabRowContent
                  key={index}
                  type={'list'}
                  isLoading={markupsStore.isLoading}
                  markupData={markupData}
                />
              ))}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};

MarkupsTab.propTypes = {
  markupsStore: MobXPropTypes.observableObject,
  customerNumber: PropTypes.string,
  customerMarkupGroupId: PropTypes.string,
  assignMarkupGroupToCustomer: PropTypes.func
};

export default inject((stores) => ({
  markupsStore: stores.markupsStore
}))(observer(MarkupsTab));
