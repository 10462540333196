import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import i18nContext from 'components/i18n-context';
import { MAX_UPLOADED_FILES_SIZE } from 'components/constants';
import { convertBytesToMegabytes } from 'services/utils';
import { AttachDoc } from 'uikit/AttachDoc/AttachDoc';
import Button from 'uikit/Button/Button';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import Input from 'uikit/Input/Input';
import './PopUpCustomerDocumentDetailsScheme.scss';

const DEFAULT_FORM_STATE = {
  note: '',
  notificationDate: null,
  expirationDate: null,
  document: []
};

export const PopUpCustomerDocumentDetailsScheme = ({ handleClose, editedDocument, handleUploadOrEditDocument }) => {
  const i18n = useContext(i18nContext);

  const validationSchema = Yup.object().shape({
    note: Yup.string().max(1000, i18n.getMessage('documents.modal.error.note.size', { size: 1000 })),
    expirationDate: Yup.date().nullable().required(i18n.getMessage('documents.modal.error.expirationDate.empty')),
    notificationDate: Yup.date().nullable().required(i18n.getMessage('documents.modal.error.notificationDate.empty')),
    document: Yup.array().min(1, i18n.getMessage('documents.modal.error.document.empty'))
  });

  const form = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: editedDocument
      ? {
          note: editedDocument.note,
          notificationDate: new Date(editedDocument.notificationDate),
          expirationDate: new Date(editedDocument.expirationDate),
          document: [{ name: editedDocument.documentName }]
        }
      : { ...DEFAULT_FORM_STATE },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await handleUploadOrEditDocument(values.note, values.notificationDate, values.expirationDate, values.document[0]);
    }
  });

  const { values, handleSubmit, setFieldValue, errors, setErrors } = form;

  const handleAttachFile = ({ target: { files } }) => {
    if (Object.keys(files)?.length) {
      setFieldValue('document', [files[0]]);
      setErrors({ document: '' });
    }
  };

  const handleRemoveDocument = () => {
    setFieldValue('document', []);
  };

  const handleInput = (name, value) => {
    setFieldValue(name, value);
    setErrors({ ...errors, [name]: '' });
  };

  return (
    <div className={'scheme-wrapper document-container'}>
      <div className={'scheme-header document-container-header'}>
        <h2>{i18n.getMessage('documents.modal.header')}</h2>
        <CloseIcon className={'document-container-header-icon'} onClick={handleClose} />
      </div>
      <form onSubmit={handleSubmit} className={'document-container-form'}>
        <div className={'document-container-form-wrapper'}>
          <div className={'document-container-form-wrapper-input'}>
            <span>{i18n.getMessage('documents.modal.notificationDate')}</span>
            <CustomDatePicker
              name={'notificationDate'}
              value={values.notificationDate}
              className={errors.notificationDate ? 'document-container-form-wrapper-attach-input-error' : ''}
              onChange={handleInput}
            />
            {errors.notificationDate && (
              <p className={`document-container-form-wrapper-attach-input-error-text`}>{errors.notificationDate}</p>
            )}
          </div>

          <div className={'document-container-form-wrapper-input'}>
            <span>{i18n.getMessage('documents.modal.expirationDate')}</span>
            <CustomDatePicker
              name={'expirationDate'}
              className={errors.expirationDate ? 'document-container-form-wrapper-attach-input-error' : ''}
              value={values.expirationDate}
              onChange={handleInput}
            />
            {errors.expirationDate && (
              <p className={`document-container-form-wrapper-attach-input-error-text`}>{errors.expirationDate}</p>
            )}
          </div>
          <div className={'document-container-form-wrapper-attach'}>
            <AttachDoc
              name={'document'}
              files={values.document}
              onRemoveFiles={handleRemoveDocument}
              size={MAX_UPLOADED_FILES_SIZE}
              hintText={i18n.getMessage('attachDoc.hint.text', {
                maxFilesSize: convertBytesToMegabytes(MAX_UPLOADED_FILES_SIZE)
              })}
              label={i18n.getMessage('documents.modal.document')}
              onChange={handleAttachFile}
              error={errors?.document}
              className={'document-container-form-wrapper-attach-input'}
              isDisabled={!!editedDocument}
            />
          </div>
        </div>
        <Input
          className={'document-container-form-note'}
          type={'textarea'}
          value={values.note}
          max={MAX_UPLOADED_FILES_SIZE}
          label={i18n.getMessage('documents.modal.note')}
          name={'note'}
          error={errors?.note}
          onChange={(e) => {
            setFieldValue('note', e.target.value);
          }}
        />
        <div className={'document-container-form-button'}>
          <Button roleType={'submit'} className={'scheme-button'}>
            {editedDocument ? i18n.getMessage('documents.modal.update') : i18n.getMessage('documents.modal.add')}
          </Button>
        </div>
      </form>
    </div>
  );
};

PopUpCustomerDocumentDetailsScheme.propTypes = {
  handleClose: PropTypes.func,
  editedDocument: PropTypes.object,
  handleUploadOrEditDocument: PropTypes.func
};
