import { useContext } from 'react';
import PropTypes from 'prop-types';
import { AMOUNT_INPUT_VALUE_TYPE, COMMON_CURRENCY_PRECISION, CRYPTO_CURRENCY_PRECISION } from 'components/constants';
import i18nContext from 'components/i18n-context';
import AmountInput from 'uikit/AmountInput';
import './LimitInputControll.scss';

export function LimitActionsInputControl({
  onChange,
  currency,
  hold,
  reject,
  editable,
  type,
  limitType,
  limitIndex,
  isCryptoProvider
}) {
  const i18n = useContext(i18nContext);

  const handleChange =
    (type) =>
    ({ target: { value } }) => {
      onChange(type, limitIndex, value);
    };

  let outgoingType = type && type === 'OUTGOING';
  let count = limitType && limitType === 'COUNT';

  return (
    <div className={'limit-value-wrapper'}>
      {count && (
        <div className={'limit-value-wrapper-hold-input'}>
          <AmountInput
            name={'count'}
            label={i18n.getMessage('limits.details.table.row.count')}
            className={'table-cell-input'}
            isDisabled={!editable}
            value={hold}
            returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
            onChange={handleChange('hold')}
            precision={isCryptoProvider ? CRYPTO_CURRENCY_PRECISION : COMMON_CURRENCY_PRECISION}
          />
        </div>
      )}
      {!count && (
        <>
          <div className={'limit-value-wrapper-hold'}>
            <div className={'limit-value-wrapper-hold-input'}>
              <AmountInput
                name={'hold'}
                label={i18n.getMessage('limits.details.table.row.hold')}
                className={'table-cell-input'}
                isDisabled={!editable}
                returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
                value={hold}
                onChange={handleChange('hold')}
                precision={isCryptoProvider ? CRYPTO_CURRENCY_PRECISION : COMMON_CURRENCY_PRECISION}
              />
              <div className={'limit-value-wrapper-hold-currency'}>{currency || 'EUR'}</div>
            </div>
          </div>
          {outgoingType && (
            <div className={'limit-value-wrapper-reject'}>
              <div className={'limit-value-wrapper-reject-input'}>
                <AmountInput
                  name={'reject'}
                  label={i18n.getMessage('limits.details.table.row.reject')}
                  className={'table-cell-input'}
                  isDisabled={!editable}
                  returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
                  value={reject}
                  onChange={handleChange('reject')}
                  precision={isCryptoProvider ? CRYPTO_CURRENCY_PRECISION : COMMON_CURRENCY_PRECISION}
                />
                <div className={'limit-value-wrapper-reject-currency'}>{currency || 'EUR'}</div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

LimitActionsInputControl.propTypes = {
  onChange: PropTypes.func,
  currency: PropTypes.string,
  hold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reject: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editable: PropTypes.bool,
  isCryptoProvider: PropTypes.bool,
  type: PropTypes.string,
  limitType: PropTypes.string,
  limitIndex: PropTypes.number
};
