import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { useApplicationContext } from 'contexts/ApplicationContext';
import Button from 'uikit/Button/Button';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';
import './PopUpCreateNewExchangeRatePairScheme.scss';

const PopUpCreateNewExchangeRatePairScheme = ({ isLoading, handleClose, handleCreateNewExchangeRatePair }) => {
  const i18n = useContext(i18nContext);
  const { constants } = useApplicationContext();

  const form = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      from: '',
      to: '',
      active: true
    },
    validationSchema: Yup.object().shape({
      from: Yup.string()
        .required(i18n.getMessage('exchangeRates.createNewPair.modal.validation.isRequired'))
        .max(50, i18n.getMessage('exchangeRates.createNewPair.modal.validation.maxSize', { size: 50 })),
      to: Yup.string()
        .required(i18n.getMessage('exchangeRates.createNewPair.modal.validation.isRequired'))
        .max(50, i18n.getMessage('exchangeRates.createNewPair.modal.validation.maxSize', { size: 50 }))
        .notOneOf([Yup.ref('from')], i18n.getMessage('exchangeRates.createNewPair.modal.validation.mustBeDifferent'))
    }),
    onSubmit: async (values) => {
      await handleCreateNewExchangeRatePair(values);
    }
  });

  const { values, handleSubmit, errors, setFieldValue } = form;

  const currencyOptions = constants?.currencies?.map((currency) => {
    return {
      key: currency,
      value: currency
    };
  });

  if (!constants) {
    return <Loader className={'application-loader scheme-add-new-markup-container'} />;
  }

  return (
    <div className={'scheme-wrapper scheme-add-new-markup-container'}>
      <div className={'scheme-add-new-markup-container-header'}>
        <h3>{i18n.getMessage('exchangeRates.createNewPair.modal.title')}</h3>
        <CloseIcon className={'scheme-add-new-markup-container-header-close-icon'} onClick={handleClose} />
      </div>
      <form className={'scheme-add-new-markup-form'} onSubmit={handleSubmit}>
        <div className={'scheme-add-new-markup-form-inputs-wrapper'}>
          <InputDropDownSearch
            className={'scheme-add-new-markup-input-dropdown-search'}
            id={'from'}
            name={'from'}
            label={i18n.getMessage('exchangeRates.createNewPair.modal.input.from')}
            options={currencyOptions}
            value={values.from || ''}
            onChange={(name, value) => setFieldValue(name, value)}
            isDisabled={isLoading}
            error={errors?.from}
          />

          <InputDropDownSearch
            className={'scheme-add-new-markup-input-dropdown-search'}
            id={'to'}
            name={'to'}
            label={i18n.getMessage('exchangeRates.createNewPair.modal.input.to')}
            options={currencyOptions}
            value={values.to || ''}
            onChange={(name, value) => setFieldValue(name, value)}
            isDisabled={isLoading}
            error={errors?.to}
          />

          <div className={'scheme-add-new-markup-status-wrapper'}>
            <CheckBox
              className={'scheme-add-new-markup-status-checkbox'}
              name={'active'}
              isActive={values.active}
              isReverseView={true}
              showCheckBox={true}
              onChange={() => setFieldValue('active', !values.active)}
              value={i18n.getMessage('exchangeRates.table.rowContent.status.active')}
              disabled={isLoading}
            />
          </div>
        </div>

        <div className={'scheme-add-new-markup-actions-wrapper'}>
          <Button
            className={'scheme-add-new-markup-button'}
            type={'outline'}
            onClick={handleClose}
            isDisabled={isLoading}
          >
            {i18n.getMessage('exchangeRates.createNewPair.modal.button.cancel')}
          </Button>
          <Button
            className={'scheme-add-new-markup-button'}
            roleType={'submit'}
            type={'primary'}
            isDisabled={isLoading}
          >
            {i18n.getMessage('exchangeRates.createNewPair.modal.button.add')}
          </Button>
        </div>
      </form>
    </div>
  );
};

PopUpCreateNewExchangeRatePairScheme.propTypes = {
  isLoading: PropTypes.bool,
  handleClose: PropTypes.func,
  handleCreateNewExchangeRatePair: PropTypes.func
};

export default PopUpCreateNewExchangeRatePairScheme;
