import { FEE_TYPE, TARIFF_TYPE } from 'components/constants';

export default (tariffData) => ({
  baseSystemTariffId: tariffData.id,
  enabled: tariffData.enabled ?? true,
  type: tariffData.type,
  ...(tariffData.type === TARIFF_TYPE.FIXED && {
    fixedValue: tariffData.fixedValue ?? null
  }),
  ...(tariffData.type === TARIFF_TYPE.SCHEDULED && {
    fixedValue: tariffData.fixedValue ?? null,
    cronExpression: tariffData.cronExpression ?? ''
  }),
  ...(tariffData.type === FEE_TYPE.PERCENT && {
    percentValue: tariffData.percentValue,
    min: tariffData.min ?? null,
    max: tariffData.max ?? null
  })
});
