import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LimitGroupDetailsTable from '../LimitGroupDetailsTable';
import { MAX_NEW_LIMIT_GROUP_NAME_LENGTH } from 'components/constants';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import PopUpAddNewLimitToLimitGroupScheme from 'components/PopUpScheme/PopUpAddNewLimitToLimitGroupScheme';
import { ROUTE_PATHS } from 'routes/constants';
import { hasRole, LIMIT_GROUPS_CREATE } from 'services/roles';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { PopUp } from 'uikit/PopUp/PopUp';
import './CreateLimitGroupPage.scss';

const CreateLimitGroupPage = ({ limitsStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const [isShowAddNewLimitPopUp, setIsShowAddNewLimitPopUp] = useState(false);
  const [isAddNewLimitButtonClicked, setIsAddNewLimitButtonClicked] = useState(false);
  const [parentGroupOptions, setParentGroupOptions] = useState([]);
  const [limitGroupDetailsForCreate, setLimitGroupDetailsForCreate] = useState(null);

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      parentGroup: '',
      newLimitGroupName: ''
    },
    validationSchema: Yup.object({
      parentGroup: Yup.string().notRequired().nullable(),
      newLimitGroupName: Yup.string()
        .required(i18n.getMessage('createLimitGroup.validation.isRequired'))
        .max(
          MAX_NEW_LIMIT_GROUP_NAME_LENGTH,
          i18n.getMessage('createLimitGroup.validation.maxSize', {
            size: MAX_NEW_LIMIT_GROUP_NAME_LENGTH
          })
        )
        .test('unique-name', i18n.getMessage('createLimitGroup.validation.duplicateName'), function (value) {
          return !parentGroupOptions.some((option) => option.value === value);
        })
    }),
    onSubmit: async (values) => {
      await limitsStore.createLimitsGroup({
        limitGroupId: values.newLimitGroupName,
        limits: limitGroupDetailsForCreate && limitGroupDetailsForCreate.limits ? limitGroupDetailsForCreate.limits : []
      });
    }
  });

  const { values, errors, handleSubmit, handleChange, setFieldValue, validateField } = form;

  useEffect(() => {
    if (!limitsStore.isInitialized) {
      limitsStore.getLimitGroupsList(1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitsStore.isInitialized]);

  useEffect(() => {
    if (limitsStore.isInitialized) {
      limitsStore.resetLimitsStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (limitsStore?.limitGroups) {
      const newParentGroupOptions = limitsStore?.limitGroups.map((limitGroup) => {
        return {
          key: limitGroup.limitGroupId,
          value: limitGroup.limitGroupId
        };
      });
      setParentGroupOptions(newParentGroupOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitsStore.limitGroups]);

  useEffect(() => {
    if (values.parentGroup) {
      limitsStore.getLimitGroupLimitsListById(values.parentGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.parentGroup]);

  useEffect(() => {
    if (limitsStore.currentLimitGroupDetails) {
      setLimitGroupDetailsForCreate((prevState) => {
        const isNewLimits = prevState?.limits?.filter((limit) => limit?.isNewLimit) || [];
        const newParentLimits = limitsStore.currentLimitGroupDetails.limits || [];

        return {
          ...limitsStore.currentLimitGroupDetails,
          limits: [...newParentLimits, ...isNewLimits]
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitsStore.currentLimitGroupDetails]);

  useEffect(() => {
    if (limitsStore.isNewLimitGroupSuccessfullyCreated) {
      limitsStore.setIsNewLimitGroupSuccessfullyCreated(false);
      navigate(`${ROUTE_PATHS.LIMITS}/${values.newLimitGroupName}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitsStore.isNewLimitGroupSuccessfullyCreated]);

  const handleClickBackButton = () => {
    navigate(ROUTE_PATHS.LIMITS);
  };

  const handleAddNewLimit = async () => {
    await validateField('newLimitGroupName');
    setIsAddNewLimitButtonClicked(true);
  };

  useEffect(() => {
    if (isAddNewLimitButtonClicked && !errors?.newLimitGroupName) {
      setIsShowAddNewLimitPopUp(true);
      setIsAddNewLimitButtonClicked(false);
    } else {
      setIsAddNewLimitButtonClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddNewLimitButtonClicked]);

  const handleCloseAddNewLimitPopUp = () => {
    setIsShowAddNewLimitPopUp(false);
  };

  const handleAddNewLimitToLimitGroup = async (newLimit) => {
    const updatedNewLimit = { ...newLimit, isNewLimit: true };

    if (!limitGroupDetailsForCreate) {
      setLimitGroupDetailsForCreate({
        limitGroupId: updatedNewLimit.limitGroupId,
        limits: [updatedNewLimit]
      });
    } else {
      setLimitGroupDetailsForCreate((previousState) => ({
        ...previousState,
        limits: [...previousState.limits, updatedNewLimit]
      }));
    }

    handleCloseAddNewLimitPopUp();
  };

  if (!limitsStore.isInitialized || !limitsStore.limitGroups) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container className={'create-limit-group-container'} header={i18n.getMessage('container.createLimitGroup')}>
      <form className={'create-limit-group-form-wrapper'} onSubmit={handleSubmit}>
        <table className={'create-limit-group-form-table-wrapper'}>
          <tbody className={'create-limit-group-form-table-body'}>
            <tr className={'create-limit-group-form-table-row'}>
              <td colSpan={2} className={'create-limit-group-form-table-cell create-limit-group-form-subsection'}>
                {i18n.getMessage('createLimitGroup.form.title')}
              </td>
            </tr>
            <tr className={'create-limit-group-form-table-row'}>
              <td className={'create-limit-group-form-table-cell'}>
                {i18n.getMessage('createLimitGroup.form.input.parentLimitGroup')}{' '}
                <i>{i18n.getMessage('createLimitGroup.form.input.parentLimitGroup.optional')}</i>
              </td>
              <td className={'create-limit-group-form-table-cell'}>
                <div className={'create-limit-group-form-table-input-wrapper'}>
                  <InputDropDown
                    className={'create-limit-group-form-table-cell-input'}
                    id={'parentGroup'}
                    name={'parentGroup'}
                    options={parentGroupOptions}
                    value={values.parentGroup}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(LIMIT_GROUPS_CREATE)}
                    error={errors?.parentGroup}
                  />
                </div>
              </td>
            </tr>
            <tr className={'create-limit-group-form-table-row'}>
              <td className={'create-limit-group-form-table-cell'}>
                {i18n.getMessage('createLimitGroup.form.input.groupName')}
              </td>
              <td className={'create-limit-group-form-table-cell'}>
                <div className={'create-limit-group-form-table-input-wrapper'}>
                  <Input
                    className={'create-limit-group-form-table-cell-input'}
                    name={'newLimitGroupName'}
                    value={values.newLimitGroupName}
                    onChange={handleChange}
                    onBlur={() => validateField('newLimitGroupName')}
                    max={MAX_NEW_LIMIT_GROUP_NAME_LENGTH}
                    isDisabled={!hasRole(LIMIT_GROUPS_CREATE)}
                    error={errors?.newLimitGroupName}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {limitGroupDetailsForCreate && (
          <LimitGroupDetailsTable
            isLoading={limitsStore.isLoading}
            isUpdateLimitGroup={false}
            limitGroupDetails={limitGroupDetailsForCreate}
            setLimitGroupDetails={setLimitGroupDetailsForCreate}
            handleClickBackButton={handleClickBackButton}
          />
        )}

        <div className={'create-limit-group-form-table-actions-wrapper'}>
          <Button
            className={'create-limit-group-form-table-button'}
            roleType={'button'}
            size={'medium'}
            type={'primary'}
            onClick={handleAddNewLimit}
            isDisabled={limitsStore.isLoading || !hasRole(LIMIT_GROUPS_CREATE)}
          >
            {i18n.getMessage('limitGroupDetails.button.addNewLimit')}
          </Button>
          <Button
            className={'create-limit-group-form-table-button'}
            roleType={'submit'}
            size={'medium'}
            type={'primary'}
            isDisabled={limitsStore.isLoading || !hasRole(LIMIT_GROUPS_CREATE)}
          >
            {i18n.getMessage('createLimitGroup.button.createGroup')}
          </Button>
        </div>
      </form>

      <PopUp alignOnCenter show={isShowAddNewLimitPopUp}>
        <PopUpAddNewLimitToLimitGroupScheme
          isLoading={limitsStore.isLoading}
          handleClose={handleCloseAddNewLimitPopUp}
          handleAddNewLimitToLimitGroup={handleAddNewLimitToLimitGroup}
          limitFormData={limitsStore.limitFormData}
          handleGetLimitFormData={limitsStore.getLimitFormData}
        />
      </PopUp>
    </Container>
  );
};

CreateLimitGroupPage.propTypes = {
  limitsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  limitsStore: stores.limitsStore
}))(observer(CreateLimitGroupPage));
