import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { LimitGroupDetailsTableHeadRow } from './components/LimitGroupDetailsTableHeadRow';
import { LimitGroupDetailsTableRowContent } from './components/LimitGroupDetailsTableRowContent';
import { POP_UP_TYPE } from 'components/constants';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { PopConfirmationScheme } from 'components/PopUpScheme/PopConfirmationScheme';
import { hasRole, LIMIT_GROUPS_UPDATE } from 'services/roles';
import Button from 'uikit/Button/Button';
import { PopUp } from 'uikit/PopUp/PopUp';
import './LimitGroupDetailsTable.scss';

const popUpInitialData = {
  isShowPopUp: false,
  popUpType: '',
  title: '',
  description: '',
  confirmButtonLabel: '',
  cancelButtonLabel: '',
  onCancel: () => {},
  onConfirm: () => {}
};

const LimitGroupDetails = ({
  isLoading,
  isUpdateLimitGroup,
  limitGroupDetails,
  setLimitGroupDetails,
  handleUpdateLimitGroup,
  handleDeleteLimitFromLimitGroup,
  handleClickBackButton
}) => {
  const i18n = useContext(i18nContext);
  const [popUpData, setPopUpData] = useState(popUpInitialData);

  const handleChangeLimitValue = (limitType, limitIndex, limitValue) => {
    setLimitGroupDetails({
      ...limitGroupDetails,
      limits: limitGroupDetails.limits.map((limit, index) => {
        if (index === limitIndex && limitType) {
          return {
            ...limit,
            [limitType]: limitValue
          };
        } else if (index === limitIndex && !limitType) {
          return {
            ...limit,
            hold: limitValue
          };
        }
        return limit;
      })
    });
  };

  const handleChangeLimitStatus = (limitIndex, limitStatus) => {
    setLimitGroupDetails({
      ...limitGroupDetails,
      limits: limitGroupDetails.limits.map((limit, index) => {
        if (index === limitIndex) {
          return {
            ...limit,
            enabled: limitStatus
          };
        }
        return limit;
      })
    });
  };

  const handleDeleteLimit = (limitIndex) => {
    setLimitGroupDetails({
      ...limitGroupDetails,
      limits: limitGroupDetails.limits.filter((limit, index) => index !== limitIndex)
    });
  };

  const resetPopUpData = () => {
    setPopUpData(popUpInitialData);
  };

  const handleConfirmLimitDeletion = async (limitIndexOrLimitId) => {
    setPopUpData({
      isShowPopUp: true,
      popUpType: POP_UP_TYPE.CONFIRMATION,
      title: i18n.getMessage('limitGroupDetails.confirmModal.deleteLimit.title'),
      description: i18n.getMessage('limitGroupDetails.confirmModal.deleteLimit.message'),
      confirmButtonLabel: i18n.getMessage('limitGroupDetails.confirmModal.button.confirm'),
      cancelButtonLabel: i18n.getMessage('limitGroupDetails.confirmModal.button.cancel'),
      onConfirm: async () => {
        if (isUpdateLimitGroup) {
          await handleDeleteLimitFromLimitGroup(limitGroupDetails.limitGroupId, limitIndexOrLimitId);
        } else {
          handleDeleteLimit(limitIndexOrLimitId);
        }
        resetPopUpData();
      },
      onCancel: resetPopUpData
    });
  };

  const handleClickSaveChangesButton = async () => {
    await handleUpdateLimitGroup(limitGroupDetails.limits);
  };

  if (!limitGroupDetails) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <div className={'limit-group-details-table-wrapper'}>
      <Table responsive>
        <thead>
          <LimitGroupDetailsTableHeadRow />
        </thead>
        <tbody>
          {limitGroupDetails?.limits?.map((limitData, index) => (
            <LimitGroupDetailsTableRowContent
              key={index}
              type={'list'}
              isLoading={isLoading}
              isUpdateLimitGroup={isUpdateLimitGroup}
              limitData={limitData}
              limitIndex={index}
              limitGroupName={limitGroupDetails?.name}
              handleChangeLimitValue={handleChangeLimitValue}
              handleChangeLimitGroupStatus={handleChangeLimitStatus}
              handleDeleteLimit={handleConfirmLimitDeletion}
            />
          ))}
        </tbody>
      </Table>

      {isUpdateLimitGroup && (
        <div className={'limit-group-details-table-actions-wrapper'}>
          <Button
            className={'limit-group-details-table-button'}
            size={'medium'}
            type={'outline'}
            onClick={handleClickBackButton}
            isDisabled={isLoading}
          >
            {i18n.getMessage('limitGroupDetails.button.back')}
          </Button>
          <Button
            className={'limit-group-details-table-button'}
            roleType={'button'}
            size={'medium'}
            type={'primary'}
            onClick={handleClickSaveChangesButton}
            isDisabled={isLoading || !hasRole(LIMIT_GROUPS_UPDATE)}
          >
            {i18n.getMessage('limitGroupDetails.button.saveChanges')}
          </Button>
        </div>
      )}

      <PopUp alignOnCenter show={popUpData.isShowPopUp}>
        <PopConfirmationScheme
          isLoading={isLoading}
          title={popUpData.title}
          description={popUpData.description}
          onConfirmButtonTitle={popUpData.confirmButtonLabel}
          onCloseButtonTitle={popUpData.cancelButtonLabel}
          onConfirm={popUpData.onConfirm}
          onClose={popUpData.onCancel}
        />
      </PopUp>
    </div>
  );
};

LimitGroupDetails.propTypes = {
  isLoading: PropTypes.bool,
  isUpdateLimitGroup: PropTypes.bool,
  handleUpdateLimitGroup: PropTypes.func,
  handleDeleteLimitFromLimitGroup: PropTypes.func,
  handleClickBackButton: PropTypes.func,
  setLimitGroupDetails: PropTypes.func,
  limitGroupDetails: PropTypes.oneOfType([
    PropTypes.shape({
      limitGroupId: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      enabled: PropTypes.bool,
      customersCount: PropTypes.number,
      limits: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          limitType: PropTypes.string,
          period: PropTypes.string,
          transactionType: PropTypes.string,
          transferProvider: PropTypes.string,
          transferType: PropTypes.string,
          currency: PropTypes.string,
          network: PropTypes.string,
          reject: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          hold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          notification: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          createdAt: PropTypes.string,
          updatedAt: PropTypes.string,
          enabled: PropTypes.bool
        })
      )
    }),
    PropTypes.oneOf([null])
  ])
};

export default LimitGroupDetails;
