import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import debounce from 'lodash-es/debounce';
import Table from 'react-bootstrap/Table';
import queryString from 'query-string';
import { TariffGroupsTableHeadRow } from './components/TariffGroupsTableHeadRow';
import { TariffGroupsTableRowContent } from './components/TariffGroupsTableRowContent';
import PopUpRenameTariffGroupScheme from './components/PopUpRenameTariffGroupScheme';
import { DIRECTION, POP_UP_TYPE } from 'components/constants';
import CustomSearchInput from 'components/CustomSearchInput';
import EmptyTableContentPlug from 'components/EmptyTableContentPlug';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import { PopConfirmationScheme } from 'components/PopUpScheme/PopConfirmationScheme';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import { PopUp } from 'uikit/PopUp/PopUp';
import './TariffGroupsTable.scss';

const popUpInitialData = {
  isShowPopUp: false,
  tariffGroupId: null,
  popUpType: '',
  title: '',
  description: '',
  confirmButtonLabel: '',
  cancelButtonLabel: '',
  onCancel: () => {},
  onConfirm: () => {}
};

const TariffGroupsTable = ({ tariffsStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(
    tariffsStore.filters.searchText ? tariffsStore.filters.searchText : ''
  );
  const [popUpData, setPopUpData] = useState(popUpInitialData);

  const updateFiltersInUrl = (filters, pagination) => {
    const queryParams = {
      page: pagination.page,
      size: pagination.size,
      sort_direction: pagination.direction
    };

    if (filters.searchText) {
      queryParams.searchText = filters.searchText;
    }

    if (pagination.sortBy) {
      queryParams.sort_column = pagination.sortBy;
    }

    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    tariffsStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tariffsStore.isInitialized) {
      // tariffsStore.setTariffGroupsPageSize(1000);
      tariffsStore.getTariffGroupList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffsStore.isInitialized]);

  useEffect(() => {
    if (tariffsStore.isInitialized) {
      tariffsStore.resetTariffsStore();
      loadFiltersFromUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(tariffsStore.filters, tariffsStore.pagination);
    if (tariffsStore.isInitialized) {
      tariffsStore.getTariffGroupList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tariffsStore.filters.searchText,
    tariffsStore.pagination.sortBy,
    tariffsStore.pagination.direction,
    tariffsStore.pagination.size,
    tariffsStore.pagination.page
  ]);

  useEffect(() => {
    if (tariffsStore.isTariffGroupSuccessfullyDeleted) {
      tariffsStore.getTariffGroupList();
      tariffsStore.setIsTariffGroupSuccessfullyDeleted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffsStore.isTariffGroupSuccessfullyDeleted]);

  const handleClickCreateTariffGroupButton = () => {
    navigate(ROUTE_PATHS.TARIFFS_CREATE_GROUP);
  };

  const handleSetCurrentPage = (pageNumber) => {
    tariffsStore.setTariffGroupsPage(pageNumber);
  };

  const handleSetPageSize = (size) => {
    tariffsStore.setTariffGroupsPageSize(size);
  };

  const handleOnSortTable = (newSortBy) => {
    tariffsStore.setSortData({
      sortBy: newSortBy,
      direction:
        tariffsStore.pagination.sortBy !== newSortBy
          ? DIRECTION.ASC
          : tariffsStore.pagination.direction === DIRECTION.ASC
            ? DIRECTION.DESC
            : DIRECTION.ASC
    });
  };

  const debouncedSearch = useRef(
    debounce((name, value) => {
      tariffsStore.setFilter(name, value);
    }, 400)
  ).current;

  const handleSearchTextInputChange = ({ target: { name, value } }) => {
    setSearchValue(value);

    debouncedSearch(name, value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const resetPopUpData = () => {
    setPopUpData(popUpInitialData);
  };

  const handleConfirmTariffDeletion = async (tariffGroupId) => {
    setPopUpData({
      isShowPopUp: true,
      popUpType: POP_UP_TYPE.CONFIRMATION,
      title: i18n.getMessage('tariffGroups.confirmModal.deleteTariff.title'),
      description: i18n.getMessage('tariffGroups.confirmModal.deleteTariff.message'),
      confirmButtonLabel: i18n.getMessage('tariffGroups.confirmModal.button.confirm'),
      cancelButtonLabel: i18n.getMessage('tariffGroups.confirmModal.button.cancel'),
      onConfirm: async () => {
        await tariffsStore.deleteTariffGroup(tariffGroupId);
        resetPopUpData();
      },
      onCancel: resetPopUpData
    });
  };

  const handleRenameTariffGroup = async (tariffGroupId) => {
    setPopUpData({
      isShowPopUp: true,
      popUpType: 'RENAME_TARIFF_GROUP',
      tariffGroupId: tariffGroupId,
      onConfirm: async (tariffGroupId, data) => {
        await tariffsStore.renameTariffGroup(tariffGroupId, data);
        resetPopUpData();
      },
      onCancel: resetPopUpData
    });
  };

  if (!tariffsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container className={'tariff-group-container'} header={i18n.getMessage('container.tariffs')}>
      <div className={'tariff-group-actions-wrapper'}>
        <CustomSearchInput
          wrapperClassName={'search-wrapper'}
          inputClassName={'tariff-group-filters-wrapper'}
          label={i18n.getMessage('tariffGroups.filter.search.label')}
          placeholder={i18n.getMessage('tariffGroups.filter.search.placeholder')}
          name={'searchText'}
          value={searchValue}
          onChange={handleSearchTextInputChange}
          totalElements={tariffsStore.pagination.totalElements}
        />

        <Button
          className={'tariff-group-button'}
          size={'medium'}
          type={'primary'}
          onClick={handleClickCreateTariffGroupButton}
          isDisabled={!tariffsStore.isInitialized}
        >
          {i18n.getMessage('tariffGroups.button.createNewTariffGroup')}
        </Button>
      </div>

      {!tariffsStore.tariffGroups || tariffsStore.tariffGroups?.length === 0 ? (
        <EmptyTableContentPlug
          headerText={i18n.getMessage('tariffGroups.table.emptyPlug.header')}
          descriptionText={i18n.getMessage('tariffGroups.table.emptyPlug.description')}
        />
      ) : (
        <>
          <Pagination
            isShowPaginationSize={true}
            paginationSize={tariffsStore.pagination.size}
            handleChangePaginationSize={handleSetPageSize}
            isLoading={tariffsStore.isLoading}
            currentPage={tariffsStore.pagination.page}
            numberOfPages={tariffsStore.pagination.totalPages}
            setCurrentPage={handleSetCurrentPage}
          />

          <Table responsive>
            <thead>
              <TariffGroupsTableHeadRow
                handleOnSortTable={handleOnSortTable}
                sortBy={tariffsStore.pagination.sortBy}
                direction={tariffsStore.pagination.direction}
              />
            </thead>
            <tbody>
              {tariffsStore.tariffGroups?.map((tariffGroup, index) => (
                <TariffGroupsTableRowContent
                  key={index}
                  type={'list'}
                  isLoading={tariffsStore.isLoading}
                  tariffGroupData={tariffGroup}
                  handleChangeTariffGroupStatus={tariffsStore.toggleTariffGroupAvailability}
                  handleDeleteTariffGroup={handleConfirmTariffDeletion}
                  handleRenameTariffGroup={handleRenameTariffGroup}
                />
              ))}
            </tbody>
          </Table>

          <Pagination
            isShowPaginationSize={true}
            paginationSize={tariffsStore.pagination.size}
            handleChangePaginationSize={handleSetPageSize}
            isLoading={tariffsStore.isLoading}
            currentPage={tariffsStore.pagination.page}
            numberOfPages={tariffsStore.pagination.totalPages}
            setCurrentPage={handleSetCurrentPage}
          />
        </>
      )}

      <PopUp alignOnCenter show={popUpData.isShowPopUp}>
        {popUpData.popUpType === POP_UP_TYPE.CONFIRMATION ? (
          <PopConfirmationScheme
            isLoading={tariffsStore.isLoading}
            title={popUpData.title}
            description={popUpData.description}
            onConfirmButtonTitle={popUpData.confirmButtonLabel}
            onCloseButtonTitle={popUpData.cancelButtonLabel}
            onConfirm={popUpData.onConfirm}
            onClose={popUpData.onCancel}
          />
        ) : (
          <PopUpRenameTariffGroupScheme
            isLoading={tariffsStore.isLoading}
            tariffGroupId={popUpData.tariffGroupId}
            handleClose={popUpData.onCancel}
            handleRenameTariffGroup={popUpData.onConfirm}
          />
        )}
      </PopUp>
    </Container>
  );
};

TariffGroupsTable.propTypes = {
  tariffsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  tariffsStore: stores.tariffsStore
}))(observer(TariffGroupsTable));
