import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowSelect } from 'assets/arrow-select.svg';
import i18nContext from 'components/i18n-context';
import './ExchangeRatesTableHeadRow.scss';

export const EXCHANGE_RATES_TABLE_HEADER = [
  { label: 'from' },
  { label: 'to' },
  { label: 'createDate' },
  { label: 'status' }
];

export const ExchangeRatesTableHeadRow = ({ handleOnSortTable, sortBy, direction }) => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {EXCHANGE_RATES_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'exchange-rates-table-head'}>
          <div className={'exchange-rates-table-head-block'}>
            <span>{i18n.getMessage(`exchangeRates.table.header.${head.label}`)}</span>
            {head.sortBy && (
              <ArrowSelect
                onClick={() => handleOnSortTable(head.sortBy)}
                className={`arrow-icon ${sortBy === head.sortBy && direction}`}
              />
            )}
          </div>
        </th>
      ))}
    </tr>
  );
};

ExchangeRatesTableHeadRow.propTypes = {
  handleOnSortTable: PropTypes.func,
  sortBy: PropTypes.string,
  direction: PropTypes.string
};
