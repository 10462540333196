import { useContext } from 'react';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { LimitActionsInputControl } from 'components/LimitInputs/LimitActionsInputControl';
import { hasRole, LIMIT_GROUPS_UPDATE } from 'services/roles';
import { formatDateTime, getLimitInfo, getLimitMessageKey, isCryptoProvider } from 'services/utils';
import Button from 'uikit/Button/Button';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import './LimitGroupDetailsTableRowContent.scss';

export const LimitGroupDetailsTableRowContent = ({
  isLoading,
  isUpdateLimitGroup,
  limitIndex,
  limitData,
  handleChangeLimitGroupStatus,
  handleChangeLimitValue,
  handleDeleteLimit
}) => {
  const i18n = useContext(i18nContext);

  const getLimitLabel = (limit) => {
    const key = 'limit.label.' + getLimitMessageKey(limit);
    let message = i18n.getMessage(key);
    if (message === key) {
      const info = getLimitInfo(limit);
      return Object.keys(info).map((key, index) => (
        <div key={'limit-label-part-' + index}>
          {i18n.getMessage('limit.label.part.' + key)}: <strong>{info[key]}</strong>
        </div>
      ));
    }
    return message;
  };

  const getLimitInfoBlock = (
    limitBlockInfo,
    isLimitInfo = false,
    isLimitValue = false,
    isLimitCreatedAtAndUpdatedAt = false,
    isStatus = false,
    isDeleteLimit = false
  ) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'limit-group-details-table-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (isLimitInfo) {
      return getLimitLabel(limitData);
    } else if (isLimitValue) {
      return (
        <LimitActionsInputControl
          onChange={handleChangeLimitValue}
          currency={limitBlockInfo.currency}
          hold={limitBlockInfo.hold}
          notification={limitBlockInfo.notification}
          reject={limitBlockInfo.reject}
          editable={hasRole(LIMIT_GROUPS_UPDATE)}
          type={limitBlockInfo.transactionType}
          limitType={limitBlockInfo.limitType}
          limitIndex={limitIndex}
          isCryptoProvider={isCryptoProvider(limitBlockInfo.transferProvider)}
        />
      );
    } else if (isLimitCreatedAtAndUpdatedAt) {
      return (
        <div className={'limit-group-details-table-date-and-time'}>
          Created:
          <br /> {formatDateTime(limitData.createdAt)} <br />
          <br />
          Updated:
          <br /> {formatDateTime(limitData.updatedAt)}
        </div>
      );
    } else if (isStatus) {
      return (
        <div className={'limit-group-details-table-status-wrapper'}>
          <CheckBox
            className={'limit-group-details-table-status-checkbox'}
            name={'hidden'}
            isActive={limitBlockInfo}
            isReverseView={true}
            showCheckBox={true}
            onChange={() => handleChangeLimitGroupStatus(limitIndex, !limitBlockInfo)}
            value={i18n.getMessage('limitGroupDetails.table.rowContent.status.enabled')}
            disabled={isLoading || !hasRole(LIMIT_GROUPS_UPDATE)}
          />
        </div>
      );
    } else if (isDeleteLimit) {
      return (
        <Button
          onClick={() => handleDeleteLimit(isUpdateLimitGroup ? limitData.id : limitIndex)}
          type={'primary'}
          size={'small'}
        >
          {i18n.getMessage('limitGroupDetails.table.button.delete')}
        </Button>
      );
    } else {
      return <p>{limitBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'limit-group-details-table-cell'}>{getLimitInfoBlock(limitData, true)}</td>
      <td className={'limit-group-details-table-cell'}>{getLimitInfoBlock(limitData, false, true)}</td>
      {isUpdateLimitGroup && (
        <td className={'limit-group-details-table-cell'}>{getLimitInfoBlock(limitData, false, false, true)}</td>
      )}
      <td className={'limit-group-details-table-cell'}>
        {getLimitInfoBlock(limitData.enabled, false, false, false, true)}
      </td>
      <td className={'limit-group-details-table-cell'}>
        {getLimitInfoBlock(limitData, false, false, false, false, true)}
      </td>
    </tr>
  );
};

LimitGroupDetailsTableRowContent.propTypes = {
  isLoading: PropTypes.bool,
  isUpdateLimitGroup: PropTypes.bool,
  limitIndex: PropTypes.number,
  handleChangeLimitGroupStatus: PropTypes.func,
  handleChangeLimitValue: PropTypes.func,
  handleDeleteLimit: PropTypes.func,
  limitData: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      limitType: PropTypes.string,
      period: PropTypes.string,
      transactionType: PropTypes.string,
      transferProvider: PropTypes.string,
      transferType: PropTypes.string,
      currency: PropTypes.string,
      network: PropTypes.string,
      reject: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      hold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      notification: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      enabled: PropTypes.bool
    }),
    PropTypes.oneOf([null])
  ])
};
