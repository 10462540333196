import { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import './CustomerMarkupsTabHeadRow.scss';

export const CUSTOMER_MARKUPS_TABLE_HEADER = [{ label: 'from' }, { label: 'to' }, { label: 'markup' }];

export const CustomerMarkupsTabHeadRow = () => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {CUSTOMER_MARKUPS_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'customer-markup-group-details-table-head'}>
          <div className={'customer-markup-group-details-table-head-block'}>
            <span>{i18n.getMessage(`markups.details.table.header.${head.label}`)}</span>
          </div>
        </th>
      ))}
    </tr>
  );
};
