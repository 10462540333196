import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import queryString from 'query-string';
import LimitGroupDetailsFiltersBar from '../LimitGroupDetailsTable/components/LimitGroupDetailsFiltersBar';
import LimitGroupDetailsTable from '../LimitGroupDetailsTable';
import EmptyTableContentPlug from 'components/EmptyTableContentPlug';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import PopUpAddNewLimitToLimitGroupScheme from 'components/PopUpScheme/PopUpAddNewLimitToLimitGroupScheme';
import { ROUTE_PATHS } from 'routes/constants';
import { hasRole, LIMIT_GROUPS_UPDATE } from 'services/roles';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import { PopUp } from 'uikit/PopUp/PopUp';
import './LimitGroupDetailsPage.scss';

const LimitGroupDetails = ({ limitsStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const { id: limitGroupId } = useParams();
  const [limitGroupDetailsForUpdate, setLimitGroupDetailsForUpdate] = useState(
    limitsStore.currentLimitGroupDetails || null
  );
  const [isShowAddNewLimitPopUp, setIsShowAddNewLimitPopUp] = useState(false);

  const updateLimitListFiltersInUrl = () => {
    const queryParams = limitsStore.prepareLimitsListFiltersParams();

    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadLimitFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    limitsStore.setLimitsListFiltersFromUrl(params);
  };

  useEffect(() => {
    loadLimitFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!limitsStore.isInitialized) {
      limitsStore.getLimitGroupsList(1000);
      limitsStore.getLimitFormData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitsStore.isInitialized]);

  useEffect(() => {
    if (limitsStore.isInitialized) {
      limitsStore.resetLimitsStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateLimitListFiltersInUrl();
    if (limitGroupId && limitsStore.isInitialized) {
      limitsStore.getLimitGroupLimitsListById(limitGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    limitGroupId,
    limitsStore.isInitialized,
    limitsStore.limitsListFilters.limit_type,
    limitsStore.limitsListFilters.period,
    limitsStore.limitsListFilters.transaction_type,
    limitsStore.limitsListFilters.transfer_provider,
    limitsStore.limitsListFilters.transfer_type,
    limitsStore.limitsListFilters.currency,
    limitsStore.limitsListFilters.network,
    limitsStore.limitsListFilters.enabled
  ]);

  useEffect(() => {
    if (limitsStore.currentLimitGroupDetails) {
      setLimitGroupDetailsForUpdate(limitsStore.currentLimitGroupDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitsStore.currentLimitGroupDetails]);

  const handleAddNewLimit = () => {
    setIsShowAddNewLimitPopUp(true);
  };

  const handleClickBackButton = () => {
    navigate(ROUTE_PATHS.LIMITS);
  };

  const handleUpdateLimitGroup = async (limits) => {
    await limitsStore.updateLimitsGroup(limitGroupId, limits);
  };

  const handleCloseAddNewLimitPopUp = () => {
    setIsShowAddNewLimitPopUp(false);
  };

  const handleAddNewLimitToLimitGroup = async (values) => {
    await limitsStore.addNewLimitToLimitGroup(limitGroupId, values);
  };

  useEffect(() => {
    if (limitsStore.isNewLimitToLimitGroupSuccessfullyCreated) {
      handleCloseAddNewLimitPopUp();
      limitsStore.setIsNewLimitToLimitGroupSuccessfullyCreated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitsStore.isNewLimitToLimitGroupSuccessfullyCreated]);

  if (!limitGroupDetailsForUpdate || !limitsStore.limitFormData) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container className={'limit-group-details-container'} header={limitsStore?.currentLimitGroupDetails?.limitGroupId}>
      <div className={'limit-group-details-actions-wrapper'}>
        <Button
          className={'limit-group-details-button'}
          size={'medium'}
          type={'primary'}
          onClick={handleAddNewLimit}
          isDisabled={!limitsStore.isInitialized || limitsStore.isLoading || !hasRole(LIMIT_GROUPS_UPDATE)}
        >
          {i18n.getMessage('limitGroupDetails.button.addNewLimit')}
        </Button>
      </div>

      <LimitGroupDetailsFiltersBar
        isLoading={limitsStore.isLoading}
        limitListFilters={limitsStore.limitsListFilters}
        limitFormData={limitsStore.limitFormData}
        handleSetLimitListFilters={limitsStore.setLimitsListFilter}
      />

      {!limitGroupDetailsForUpdate?.limits || limitGroupDetailsForUpdate?.limits?.length === 0 ? (
        <EmptyTableContentPlug
          headerText={i18n.getMessage('limitGroupDetails.table.emptyPlug.header')}
          descriptionText={i18n.getMessage('limitGroupDetails.table.emptyPlug.description')}
        />
      ) : (
        <LimitGroupDetailsTable
          isLoading={limitsStore.isLoading}
          isUpdateLimitGroup={true}
          limitGroupDetails={limitGroupDetailsForUpdate}
          setLimitGroupDetails={setLimitGroupDetailsForUpdate}
          handleUpdateLimitGroup={handleUpdateLimitGroup}
          handleDeleteLimitFromLimitGroup={limitsStore.deleteLimitFromLimitGroup}
          handleClickBackButton={handleClickBackButton}
        />
      )}

      <PopUp alignOnCenter show={isShowAddNewLimitPopUp}>
        <PopUpAddNewLimitToLimitGroupScheme
          isLoading={limitsStore.isLoading}
          handleClose={handleCloseAddNewLimitPopUp}
          handleAddNewLimitToLimitGroup={handleAddNewLimitToLimitGroup}
          limitFormData={limitsStore.limitFormData}
          handleGetLimitFormData={limitsStore.getLimitFormData}
        />
      </PopUp>
    </Container>
  );
};

LimitGroupDetails.propTypes = {
  limitsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  limitsStore: stores.limitsStore
}))(observer(LimitGroupDetails));
