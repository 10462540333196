import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { useApplicationContext } from 'contexts/ApplicationContext';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';
import './MarkupGroupDetailsFiltersBar.scss';

const MarkupGroupDetailsFiltersBar = ({ isLoading, markupListFilters, handleSetMarkupListFilters }) => {
  const i18n = useContext(i18nContext);
  const { constants } = useApplicationContext();

  const currencyOptions = constants?.currencies?.map((currency) => {
    return {
      key: currency,
      value: currency
    };
  });

  return (
    <div className={'markup-list-filters-container'}>
      <InputDropDownSearch
        className={'markup-list-filter-wrapper'}
        id={'from'}
        name={'from'}
        label={i18n.getMessage('markupGroupDetails.filter.from.label')}
        value={markupListFilters.from}
        options={currencyOptions}
        onChange={handleSetMarkupListFilters}
        isDisabled={isLoading}
      />

      <InputDropDownSearch
        className={'markup-list-filter-wrapper'}
        id={'to'}
        name={'to'}
        label={i18n.getMessage('markupGroupDetails.filter.to.label')}
        value={markupListFilters.to}
        options={currencyOptions}
        onChange={handleSetMarkupListFilters}
        isDisabled={isLoading}
      />
    </div>
  );
};

MarkupGroupDetailsFiltersBar.propTypes = {
  isLoading: PropTypes.bool,
  isInitialized: PropTypes.bool,
  markupListFilters: PropTypes.object,
  handleSetMarkupListFilters: PropTypes.func
};

export default MarkupGroupDetailsFiltersBar;
