import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import {
  AMOUNT_INPUT_VALUE_TYPE,
  COMMON_CURRENCY_PRECISION,
  CRYPTO_CURRENCY_PRECISION,
  CURRENCY,
  LIMIT_TYPES,
  TRANSACTION_TYPES
} from 'components/constants';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { useApplicationContext } from 'contexts/ApplicationContext';
import { getAllCryptoCurrencies, getNetworksByCryptoCurrency, isCryptoCurrency } from 'services/utils';
import AmountInput from 'uikit/AmountInput';
import Button from 'uikit/Button/Button';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';
import './PopUpAddNewLimitToLimitGroupScheme.scss';

const PopUpAddNewLimitToLimitGroupScheme = ({
  isLoading,
  handleClose,
  handleAddNewLimitToLimitGroup,
  limitFormData,
  handleGetLimitFormData
}) => {
  const i18n = useContext(i18nContext);
  const { constants } = useApplicationContext();
  const allCryptoCurrencies = getAllCryptoCurrencies(constants);
  const [networkOptions, setNetworkOptions] = useState(
    limitFormData
      ? limitFormData?.networks.map((value) => ({
          key: value,
          value: value
        }))
      : []
  );

  useEffect(() => {
    if (!limitFormData) {
      handleGetLimitFormData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitFormData]);

  const form = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      transactionType: '',
      transferType: '',
      limitType: '',
      transferProvider: '',
      period: '',
      enabled: true,
      currency: CURRENCY.EUR,
      network: null,
      hold: '',
      reject: '',
      notification: ''
    },
    validationSchema: Yup.object().shape({
      transactionType: Yup.string().required(i18n.getMessage('addNewLimitForLimitGroup.modal.validation.isRequired')),
      transferType: Yup.string().required(i18n.getMessage('addNewLimitForLimitGroup.modal.validation.isRequired')),
      limitType: Yup.string().required(i18n.getMessage('addNewLimitForLimitGroup.modal.validation.isRequired')),
      transferProvider: Yup.string().required(i18n.getMessage('addNewLimitForLimitGroup.modal.validation.isRequired')),
      period: Yup.string().required(i18n.getMessage('addNewLimitForLimitGroup.modal.validation.isRequired')),
      currency: Yup.string()
        .nullable()
        .required(i18n.getMessage('addNewLimitForLimitGroup.modal.validation.isRequired')),
      network: Yup.string().when('currency', {
        is: (currency) => isCryptoCurrency(currency, allCryptoCurrencies),
        then: (schema) => schema.required(i18n.getMessage('addNewLimitForLimitGroup.modal.validation.isRequired')),
        otherwise: (schema) => schema.notRequired().nullable()
      }),
      hold: Yup.string().when(['limitType', 'transactionType'], {
        is: (limitType, transactionType) =>
          limitType !== LIMIT_TYPES.COUNT && transactionType === TRANSACTION_TYPES.OUTGOING,
        then: (schema) =>
          schema.test(
            'either-or',
            i18n.getMessage('addNewLimitForLimitGroup.modal.validation.oneOfFieldIsRequired'),
            function (value) {
              return value || this.parent.reject;
            }
          ),
        otherwise: (schema) => schema.required(i18n.getMessage('addNewLimitForLimitGroup.modal.validation.isRequired'))
      }),
      reject: Yup.string().when(['limitType', 'transactionType'], {
        is: (limitType, transactionType) =>
          limitType !== LIMIT_TYPES.COUNT && transactionType === TRANSACTION_TYPES.OUTGOING,
        then: (schema) =>
          schema.test(
            'either-or',
            i18n.getMessage('addNewLimitForLimitGroup.modal.validation.oneOfFieldIsRequired'),
            function (value) {
              return value || this.parent.hold;
            }
          ),
        otherwise: (schema) => schema.notRequired().nullable()
      }),
      notification: Yup.string().nullable()
    }),
    onSubmit: async (values) => {
      const formData = {
        ...values,
        transferProvider: values.transferProvider === 'ANY' ? null : values.transferProvider
      };

      await handleAddNewLimitToLimitGroup(formData);
    }
  });

  const { values, handleSubmit, errors, handleChange, setFieldValue } = form;

  const transactionTypeOptions =
    limitFormData &&
    limitFormData?.transactionTypes.map((value) => ({
      key: value,
      value: i18n.getMessage(`transactionType.${value}`)
    }));

  const transferTypeOptions =
    limitFormData &&
    limitFormData?.transferTypes.map((value) => ({
      key: value,
      value: i18n.getMessage(`transferType.${value}`)
    }));

  const limitTypeOptions =
    limitFormData &&
    limitFormData?.limitTypes.map((value) => ({
      key: value,
      value: i18n.getMessage(`limitType.${value}`)
    }));

  const transferProviderOptions = limitFormData
    ? [
        { key: 'ANY', value: i18n.getMessage('paymentProvider.ANY') },
        ...limitFormData.transferProviders.map((value) => ({
          key: value,
          value: i18n.getMessage(`paymentProvider.${value}`)
        }))
      ]
    : [];

  const periodsOptions =
    limitFormData &&
    limitFormData?.periods.map((value) => ({
      key: value,
      value: i18n.getMessage(`limitPeriod.${value}`)
    }));

  const currencyOptions =
    limitFormData &&
    limitFormData?.currencies.map((value) => ({
      key: value,
      value: value
    }));

  useEffect(() => {
    if (values.currency && isCryptoCurrency(values.currency, allCryptoCurrencies)) {
      const allNetworks = getNetworksByCryptoCurrency(values.currency, constants);

      if (!allNetworks && allNetworks.length === 0) {
        setNetworkOptions([]);
      } else {
        setNetworkOptions(
          allNetworks.map((value) => ({
            key: value,
            value: value
          }))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.currency]);

  if (!limitFormData) {
    return <Loader className={'application-loader scheme-add-new-limit-container'} />;
  }

  return (
    <div className={'scheme-wrapper scheme-add-new-limit-container'}>
      <div className={'scheme-add-new-limit-container-header'}>
        <h3>{i18n.getMessage('addNewLimitForLimitGroup.modal.title')}</h3>
        <CloseIcon className={'scheme-add-new-limit-container-header-close-icon'} onClick={handleClose} />
      </div>
      <form className={'scheme-add-new-limit-form'} onSubmit={handleSubmit}>
        <div className={'scheme-add-new-limit-form-inputs-wrapper'}>
          <InputDropDown
            className={'scheme-add-new-limit-input'}
            id={'transactionType'}
            name={'transactionType'}
            label={i18n.getMessage('addNewLimitForLimitGroup.modal.input.transactionType')}
            options={transactionTypeOptions}
            value={values.transactionType || ''}
            onChange={(name, value) => setFieldValue(name, value)}
            isDisabled={isLoading}
            error={errors?.transactionType}
          />

          <InputDropDown
            className={'scheme-add-new-limit-input'}
            id={'transferType'}
            name={'transferType'}
            label={i18n.getMessage('addNewLimitForLimitGroup.modal.input.transferType')}
            options={transferTypeOptions}
            value={values.transferType || ''}
            onChange={(name, value) => setFieldValue(name, value)}
            isDisabled={isLoading}
            error={errors?.transferType}
          />

          <InputDropDown
            className={'scheme-add-new-limit-input'}
            id={'limitType'}
            name={'limitType'}
            label={i18n.getMessage('addNewLimitForLimitGroup.modal.input.limitType')}
            options={limitTypeOptions}
            value={values.limitType || ''}
            onChange={(name, value) => setFieldValue(name, value)}
            isDisabled={isLoading}
            error={errors?.limitType}
          />

          <InputDropDown
            className={'scheme-add-new-limit-input'}
            id={'transferProvider'}
            name={'transferProvider'}
            label={i18n.getMessage('addNewLimitForLimitGroup.modal.input.transferProvider')}
            options={transferProviderOptions}
            value={values.transferProvider || ''}
            onChange={(name, value) => setFieldValue(name, value)}
            isDisabled={isLoading}
            error={errors?.transferProvider}
          />

          <InputDropDown
            className={'scheme-add-new-limit-input'}
            id={'period'}
            name={'period'}
            label={i18n.getMessage('addNewLimitForLimitGroup.modal.input.period')}
            options={periodsOptions}
            value={values.period || ''}
            onChange={(name, value) => setFieldValue(name, value)}
            isDisabled={isLoading}
            error={errors?.period}
          />

          <InputDropDownSearch
            className={'scheme-add-new-limit-input-dropdown-search'}
            id={'currency'}
            name={'currency'}
            label={i18n.getMessage('addNewLimitForLimitGroup.modal.input.currency')}
            options={currencyOptions}
            value={values.currency || ''}
            onChange={(name, value) => setFieldValue(name, value)}
            isDisabled={isLoading}
            error={errors?.currency}
          />

          {isCryptoCurrency(values.currency, allCryptoCurrencies) && (
            <InputDropDown
              className={'scheme-add-new-limit-input'}
              id={'network'}
              name={'network'}
              label={i18n.getMessage('addNewLimitForLimitGroup.modal.input.network')}
              options={networkOptions}
              value={values.network || ''}
              onChange={(name, value) => setFieldValue(name, value)}
              isDisabled={isLoading}
              error={errors?.network}
            />
          )}

          <div className={'scheme-add-new-limit-form-checkbox-wrapper'}>
            <span>{i18n.getMessage('addNewLimitForLimitGroup.modal.input.status')}</span>
            <CheckBox
              className={'scheme-add-new-limit-form-status-checkbox'}
              id={'enabled'}
              name={'enabled'}
              isActive={values.enabled}
              isReverseView={true}
              showCheckBox={true}
              onChange={() => setFieldValue('enabled', !values.enabled)}
              value={i18n.getMessage('addNewLimitForLimitGroup.modal.input.status.enabled')}
              disabled={isLoading}
            />
          </div>

          <div
            // eslint-disable-next-line max-len
            className={`scheme-add-new-limit-form-amount-inputs-wrapper ${errors?.hold || errors?.reject ? 'error-inputs-wrapper' : ''}`}
          >
            {values.limitType === LIMIT_TYPES.COUNT && (
              <div className={'scheme-add-new-limit-form-amount-inputs-and-currency-wrapper'}>
                <AmountInput
                  className={'scheme-add-new-limit-form-amount-input'}
                  id={'hold'}
                  name={'hold'}
                  label={i18n.getMessage('addNewLimitForLimitGroup.modal.input.count')}
                  placeholder={i18n.getMessage('addNewLimitForLimitGroup.modal.input.holdOrReject.placeholder')}
                  returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
                  value={values.hold}
                  onChange={handleChange}
                  error={errors?.hold}
                  precision={
                    isCryptoCurrency(values.currency, allCryptoCurrencies)
                      ? CRYPTO_CURRENCY_PRECISION
                      : COMMON_CURRENCY_PRECISION
                  }
                />
              </div>
            )}

            {values.limitType !== LIMIT_TYPES.COUNT && (
              <div className={'scheme-add-new-limit-form-amount-inputs-and-currency-wrapper'}>
                <AmountInput
                  className={'scheme-add-new-limit-form-amount-input'}
                  id={'hold'}
                  name={'hold'}
                  label={i18n.getMessage('addNewLimitForLimitGroup.modal.input.hold')}
                  placeholder={i18n.getMessage('addNewLimitForLimitGroup.modal.input.holdOrReject.placeholder')}
                  returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
                  value={values.hold}
                  onChange={handleChange}
                  error={errors?.hold}
                  precision={
                    isCryptoCurrency(values.currency, allCryptoCurrencies)
                      ? CRYPTO_CURRENCY_PRECISION
                      : COMMON_CURRENCY_PRECISION
                  }
                />
                <div className={'scheme-add-new-limit-form-hold-or-reject-currency'}>
                  {values.currency || i18n.getMessage('addNewLimitForLimitGroup.modal.input.currency.placeholder')}
                </div>
              </div>
            )}

            {values.limitType !== LIMIT_TYPES.COUNT && values.transactionType === TRANSACTION_TYPES.OUTGOING && (
              <div className={'scheme-add-new-limit-form-amount-inputs-and-currency-wrapper'}>
                <AmountInput
                  className={'scheme-add-new-limit-form-amount-input'}
                  id={'reject'}
                  name={'reject'}
                  label={i18n.getMessage('addNewLimitForLimitGroup.modal.input.reject')}
                  placeholder={i18n.getMessage('addNewLimitForLimitGroup.modal.input.holdOrReject.placeholder')}
                  returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
                  value={values.reject}
                  onChange={handleChange}
                  error={errors?.reject}
                  precision={
                    isCryptoCurrency(values.currency, allCryptoCurrencies)
                      ? CRYPTO_CURRENCY_PRECISION
                      : COMMON_CURRENCY_PRECISION
                  }
                />
                <div className={'scheme-add-new-limit-form-hold-or-reject-currency'}>
                  {values.currency || i18n.getMessage('addNewLimitForLimitGroup.modal.input.currency.placeholder')}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={'scheme-add-new-limit-actions-wrapper'}>
          <Button
            className={'scheme-add-new-limit-button'}
            type={'outline'}
            onClick={handleClose}
            isDisabled={isLoading}
          >
            {i18n.getMessage('addNewLimitForLimitGroup.modal.button.cancel')}
          </Button>
          <Button className={'scheme-add-new-limit-button'} roleType={'submit'} type={'primary'} isDisabled={isLoading}>
            {i18n.getMessage('addNewLimitForLimitGroup.modal.button.add')}
          </Button>
        </div>
      </form>
    </div>
  );
};

PopUpAddNewLimitToLimitGroupScheme.propTypes = {
  isLoading: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAddNewLimitToLimitGroup: PropTypes.func,
  limitFormData: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  handleGetLimitFormData: PropTypes.func.isRequired
};

export default PopUpAddNewLimitToLimitGroupScheme;
