import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import BackButton from 'components/BackButton';
import { CUSTOM_TABS_DIRECTION } from 'components/constants';
import CustomTabs from 'components/CustomTabs';
import i18n from 'i18n';
import { ROUTE_PATHS } from 'routes/constants';
import { Container } from 'uikit/Container/Container';
import './Markups.scss';

const MARKUPS_TABS = [
  {
    name: 'markupGroups',
    title: i18n.getMessage('markupGroups.tab.markupGroups'),
    icon: null,
    route: ROUTE_PATHS.MARKUPS_GROUPS
  },
  {
    name: 'exchangeRates',
    title: i18n.getMessage('markupGroups.tab.exchangeRates'),
    icon: null,
    route: ROUTE_PATHS.MARKUPS_EXCHANGE_RATES
  }
];

const Markups = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    const path = location.pathname;

    const activeTabFromPath = MARKUPS_TABS.find((tab) => tab.route === path);
    if (activeTabFromPath) {
      setActiveTab(activeTabFromPath.name);
    } else if (path === ROUTE_PATHS.MARKUPS) {
      setActiveTab(MARKUPS_TABS[0].name);
      navigate(MARKUPS_TABS[0].route);
    }
  }, [location.pathname, navigate]);

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    const selectedTab = MARKUPS_TABS.find((tab) => tab.name === tabName);
    if (selectedTab) {
      navigate(selectedTab.route);
    }
  };

  return (
    <section className={'right-section'}>
      {location.pathname === ROUTE_PATHS.MARKUPS ||
      location.pathname === ROUTE_PATHS.MARKUPS_GROUPS ||
      location.pathname === ROUTE_PATHS.MARKUPS_EXCHANGE_RATES ? (
        <Container className={'markup-groups-container'} header={i18n.getMessage('container.markups')}>
          <div className={'markups-wrapper'}>
            <CustomTabs
              tabList={MARKUPS_TABS}
              direction={CUSTOM_TABS_DIRECTION.HORIZONTAL}
              activeTab={activeTab}
              setActiveTab={handleTabChange}
            />
            <Outlet />
          </div>
        </Container>
      ) : (
        <>
          {location.pathname !== ROUTE_PATHS.MARKUPS &&
            location.pathname !== ROUTE_PATHS.MARKUPS_GROUPS &&
            location.pathname !== ROUTE_PATHS.MARKUPS_EXCHANGE_RATES && <BackButton />}
          <Outlet />
        </>
      )}
    </section>
  );
};

export default Markups;
