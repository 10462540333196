import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import BlacklistsTableFiltersBar from './components/BlacklistsTableFiltersBar';
import BlacklistsTableHead from './components/BlacklistsTableHead';
import BlacklistsTableRow from './components/BlacklistsTableRow';
import { PopUpCreateOrUpdateBlacklistScheme } from './components/PopUpCreateOrUpdateBlacklistScheme';
import { DIRECTION, MENU_DIRECTION } from 'components/constants';
import EmptyTableContentPlug from 'components/EmptyTableContentPlug';
import Loader from 'components/Loader';
import i18nContext from 'components/i18n-context';
import Pagination from 'components/Pagination/Pagination';
import { PopUp } from 'uikit/PopUp/PopUp';
import './BlacklistsTab.scss';

const BlacklistsTab = ({ settingsStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();

  const updateFiltersInUrl = (filters, pagination, sortColumn) => {
    const filtersParams = settingsStore.prepareFiltersParams();
    const queryParams = {
      page: pagination.page,
      size: pagination.size,
      sort_column: sortColumn.sort_column,
      sort_direction: sortColumn.sort_direction,
      status: filtersParams.status
    };

    if (filtersParams.from) {
      queryParams.from = filtersParams.from;
    }
    if (filtersParams.to) {
      queryParams.to = filtersParams.to;
    }
    if (filtersParams.searchText) {
      queryParams.searchText = filtersParams.searchText;
    }

    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    settingsStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!settingsStore.isInitialized) {
      settingsStore.getBlacklists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsStore.isInitialized]);

  useEffect(() => {
    if (settingsStore.isInitialized) {
      settingsStore.resetSettingsStore();
      loadFiltersFromUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(settingsStore.filters, settingsStore.pagination, settingsStore.sortColumn);
    if (settingsStore.isInitialized) {
      settingsStore.getBlacklists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    settingsStore.filters.from,
    settingsStore.filters.to,
    settingsStore.filters.status,
    settingsStore.filters.searchText,
    settingsStore.pagination.size,
    settingsStore.pagination.page,
    settingsStore.sortColumn.sort_column,
    settingsStore.sortColumn.sort_direction
  ]);

  const handleOnSortTable = (newSortBy) => {
    settingsStore.setSortData({
      sortBy: newSortBy,
      direction:
        settingsStore.sortColumn.sort_column !== newSortBy
          ? DIRECTION.ASC
          : settingsStore.sortColumn.sort_direction === DIRECTION.ASC
            ? DIRECTION.DESC
            : DIRECTION.ASC
    });
  };

  const handleOpenCreateBlacklistModal = () => {
    settingsStore.setIsCreateOrUpdateBlacklistModalOpen(true);
  };

  const handleOpenUpdateBlacklistModal = (id, value) => {
    settingsStore.setUpdatableBlacklistData(id, value);
    settingsStore.setIsCreateOrUpdateBlacklistModalOpen(true);
  };

  const handleClose = () => {
    settingsStore.setIsCreateOrUpdateBlacklistModalOpen(false);
    settingsStore.setUpdatableBlacklistData(null, null);
  };

  if (!settingsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <div className={'blacklists-wrapper'}>
      <BlacklistsTableFiltersBar
        isLoading={settingsStore.isLoading}
        isInitialized={settingsStore.isInitialized}
        filters={settingsStore.filters}
        totalElements={settingsStore.totalElements}
        handleSetFilters={settingsStore.setFilter}
        handleOpenCreateBlacklistModal={handleOpenCreateBlacklistModal}
      />

      {settingsStore.blacklists?.length === 0 ? (
        <EmptyTableContentPlug
          headerText={i18n.getMessage('settings.blacklists.table.emptyPlug.header')}
          descriptionText={i18n.getMessage('settings.blacklists.table.emptyPlug.description')}
        />
      ) : (
        <>
          <Pagination
            isShowPaginationSize={true}
            paginationSize={settingsStore.pagination.size}
            handleChangePaginationSize={settingsStore.setPageSize}
            isLoading={settingsStore.isLoading}
            currentPage={settingsStore.pagination.page}
            numberOfPages={settingsStore.pagination.totalPages}
            setCurrentPage={settingsStore.setPageNumber}
          />

          <Table responsive>
            <thead>
              <BlacklistsTableHead
                handleOnSortTable={handleOnSortTable}
                sortBy={settingsStore.sortColumn.sort_column}
                direction={settingsStore.sortColumn.sort_direction}
              />
            </thead>
            <tbody>
              {settingsStore.blacklists?.map((blacklist, index) => (
                <BlacklistsTableRow
                  key={index}
                  type={'list'}
                  isLoading={settingsStore.isLoading}
                  blacklistData={blacklist}
                  handleOpenUpdateBlacklistModal={handleOpenUpdateBlacklistModal}
                  activateBlacklist={settingsStore.activateBlacklist}
                  deactivateBlacklist={settingsStore.deactivateBlacklist}
                />
              ))}
            </tbody>
          </Table>

          <Pagination
            isShowPaginationSize={true}
            paginationSize={settingsStore.pagination.size}
            handleChangePaginationSize={settingsStore.setPageSize}
            isLoading={settingsStore.isLoading}
            currentPage={settingsStore.pagination.page}
            numberOfPages={settingsStore.pagination.totalPages}
            setCurrentPage={settingsStore.setPageNumber}
            menuDirection={MENU_DIRECTION.UP}
          />
        </>
      )}

      <PopUp
        className={'blacklist-container-pop-up'}
        show={settingsStore.isCreateOrUpdateBlacklistModalOpen}
        onClose={handleClose}
        alignOnCenter
      >
        <PopUpCreateOrUpdateBlacklistScheme
          handleClose={handleClose}
          updatableBlacklistId={settingsStore.updatableBlacklist.id}
          updatableBlacklistValue={settingsStore.updatableBlacklist.value}
          handleCreateBlacklist={settingsStore.createBlacklist}
          handleUpdateBlacklist={settingsStore.updateBlacklist}
        />
      </PopUp>
    </div>
  );
};

BlacklistsTab.propTypes = {
  settingsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  settingsStore: stores.settingsStore
}))(observer(BlacklistsTab));
