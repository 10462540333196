import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { useApplicationContext } from 'contexts/ApplicationContext';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';
import './CustomerMarkupsTabFilters.scss';

const CustomerMarkupsTabFilters = ({ isLoading, markupListFilters, handleSetMarkupListFilters }) => {
  const i18n = useContext(i18nContext);

  const { constants } = useApplicationContext();

  const currencyOptions = constants?.currencies?.map((currency) => {
    return {
      key: currency,
      value: currency
    };
  });

  return (
    <div className={'customer-markups-filters-wrapper'}>
      <InputDropDownSearch
        className={'customer-markups-filter-wrapper'}
        name={'from'}
        label={i18n.getMessage('markups.details.filter.from.label')}
        value={markupListFilters.from}
        options={currencyOptions}
        onChange={handleSetMarkupListFilters}
        isDisabled={isLoading}
      />
      <InputDropDownSearch
        className={'customer-markups-filter-wrapper'}
        name={'to'}
        label={i18n.getMessage('markups.details.filter.to.label')}
        value={markupListFilters.to}
        options={currencyOptions}
        onChange={handleSetMarkupListFilters}
        isDisabled={isLoading}
      />
    </div>
  );
};

CustomerMarkupsTabFilters.propTypes = {
  isLoading: PropTypes.bool,
  markupListFilters: PropTypes.object,
  handleSetMarkupListFilters: PropTypes.func
};

export default CustomerMarkupsTabFilters;
