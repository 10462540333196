import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import queryString from 'query-string';
import MarkupGroupDetailsFiltersBar from '../MarkupGroupDetailsTable/components/MarkupGroupDetailsFiltersBar';
import MarkupGroupDetailsTable from '../MarkupGroupDetailsTable';
import EmptyTableContentPlug from 'components/EmptyTableContentPlug';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { ROUTE_PATHS } from 'routes/constants';
import { Container } from 'uikit/Container/Container';
import './MarkupGroupDetailsPage.scss';

const MarkupGroupDetailsPage = ({ markupsStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const { id: markupGroupId } = useParams();
  const [markupGroupDetailsForUpdate, setMarkupGroupDetailsForUpdate] = useState(
    markupsStore.currentMarkupGroupDetails || null
  );

  const updateMarkupListFiltersInUrl = () => {
    const queryParams = markupsStore.prepareMarkupsListFiltersParams();

    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadMarkupFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    markupsStore.setMarkupsListFiltersFromUrl(params);
  };

  useEffect(() => {
    loadMarkupFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!markupsStore.isInitialized) {
      markupsStore.getMarkupGroupsList(1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markupsStore.isInitialized]);

  useEffect(() => {
    if (markupsStore.isInitialized) {
      markupsStore.resetMarkupsStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateMarkupListFiltersInUrl();
    if (markupGroupId && markupsStore.isInitialized) {
      markupsStore.getMarkupGroupMarkupsListById(markupGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    markupGroupId,
    markupsStore.isInitialized,
    markupsStore.markupsListFilters.from,
    markupsStore.markupsListFilters.to
  ]);

  useEffect(() => {
    if (markupsStore.currentMarkupGroupDetails) {
      setMarkupGroupDetailsForUpdate(markupsStore.currentMarkupGroupDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markupsStore.currentMarkupGroupDetails]);

  const handleClickBackButton = () => {
    navigate(ROUTE_PATHS.MARKUPS);
  };

  const handleUpdateMarkupGroup = async (markups) => {
    await markupsStore.updateMarkupGroup(markupGroupId, markups);
  };

  if (!markupGroupDetailsForUpdate) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container
      className={'markup-group-details-container'}
      header={markupsStore?.currentMarkupGroupDetails?.markupGroupId}
    >
      <MarkupGroupDetailsFiltersBar
        isLoading={markupsStore.isLoading}
        isInitialized={markupsStore.isInitialized}
        markupListFilters={markupsStore.markupsListFilters}
        handleSetMarkupListFilters={markupsStore.setMarkupsListFilter}
      />

      {!markupGroupDetailsForUpdate?.markups || markupGroupDetailsForUpdate?.markups?.length === 0 ? (
        <EmptyTableContentPlug
          headerText={i18n.getMessage('markupGroupDetails.table.emptyPlug.header')}
          descriptionText={i18n.getMessage('markupGroupDetails.table.emptyPlug.description')}
        />
      ) : (
        <MarkupGroupDetailsTable
          isLoading={markupsStore.isLoading}
          isUpdateMarkupGroup={true}
          markupGroupDetails={markupGroupDetailsForUpdate}
          setMarkupGroupDetails={setMarkupGroupDetailsForUpdate}
          handleUpdateMarkupGroup={handleUpdateMarkupGroup}
          handleClickBackButton={handleClickBackButton}
        />
      )}
    </Container>
  );
};

MarkupGroupDetailsPage.propTypes = {
  markupsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  markupsStore: stores.markupsStore
}))(observer(MarkupGroupDetailsPage));
