import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as DeleteTimeIcon } from 'assets/delete-file-icon.svg';
import { IconButton } from 'uikit/IconButton/IconButton';
import { classNames } from 'uikit/utils';
import './CustomTimePicker.scss';

export const CustomTimePicker = ({
  isDisabled,
  isRequired,
  autoComplete = 'true',
  className,
  label,
  name,
  onChange,
  value,
  error,
  timeIntervals = 1,
  timeFormat = 'HH:mm',
  isShowDeleteTimeButton = true
}) => {
  const customTimePickerClasses = classNames({
    datepicker: true,
    error: !!error,
    disabled: isDisabled,
    [className]: true
  });

  const pickTime = (time) => {
    if (!isShowDeleteTimeButton && !time) {
      const currentValue = value;
      const newTime = new Date(currentValue.getFullYear(), currentValue.getMonth(), currentValue.getDate(), 0, 0);
      onChange(name, newTime);
    } else {
      onChange(name, time);
    }
  };

  return (
    <div className={customTimePickerClasses}>
      <div className={'datepicker-block'}>
        {label && <label htmlFor={name}>{label + (isRequired ? '*' : '')}</label>}
        <DatePicker
          autoComplete={autoComplete}
          className={'date-input'}
          disabled={isDisabled}
          name={name}
          id={name}
          placeholderText={'HH:mm'}
          selected={value}
          onChange={(time) => pickTime(time)}
          showTimeInput={false}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={timeIntervals}
          timeFormat={timeFormat}
          timeCaption={'Time'}
          dateFormat={'HH:mm'}
        />
        {isShowDeleteTimeButton && (
          <IconButton className={'date-icon'} Icon={DeleteTimeIcon} onClick={() => pickTime('')} />
        )}
      </div>
      {error && <p className={'date-picker-error'}>{error}</p>}
    </div>
  );
};

CustomTimePicker.propTypes = {
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.any]),
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  timeIntervals: PropTypes.number,
  timeFormat: PropTypes.string,
  isShowDeleteTimeButton: PropTypes.bool
};
