import { COMMON_CURRENCY_PRECISION, CUSTOMER_STATUSES, CUSTOMER_TYPE, PAYMENT_PROVIDERS } from 'components/constants';
import moment from 'moment-timezone';
import i18n from 'i18n';

export const emailRegExp =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line no-useless-escape
export const passwordSpecialSymbolsRegExp = /[!\"#$%&'()*+,-.:;<=>?@\[\]^_`{/|\\}~]/;
export const noNumbersRegExp = /^[^\d]*$/;
export const noCyrillicRegExp = /^[^\u0400-\u04FF]*$/;

export const convertBytesToMegabytes = (bytes) => bytes / (1024 * 1024);

export const getCustomerLink = (customerNumber, customerStatus, customerType) => {
  return `/${customerStatus === CUSTOMER_STATUSES.VERIFIED ? 'active-customers' : 'customers'}/${
    customerType === CUSTOMER_TYPE.INDIVIDUAL ? 'individual' : 'corporative'
  }/${customerNumber}?tab=customerDetails`;
};

export const formatDate = (utcDate) => {
  return utcDate ? moment.utc(utcDate).format('DD/MM/YYYY') : null;
};

export const formatTime = (utcDate) => {
  return utcDate ? moment.utc(utcDate).format('HH:mm') + ' UTC' : null;
};

export const formatDateTime = (utcDate) => {
  return utcDate ? moment.utc(utcDate).format('DD/MM/YYYY HH:mm') + ' UTC' : null;
};

export const formatDateTimeLocal = (utcDate) => {
  return utcDate ? moment.utc(utcDate).local().format('DD/MM/YYYY HH:mm') : null;
};

export const convertDate = (date) => {
  if (date) {
    return `${date.getFullYear()}-${date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${
      date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    }`;
  }

  return date;
};

export const convertDateWithTime = (date, isEndOfDay = false) => {
  if (date) {
    return `${date.getFullYear()}-${date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${
      date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    }T${isEndOfDay ? '23:59:59' : '00:00:00'}`;
  }
  return date;
};

export const convertToFullDate = (dateStr) => {
  if (!dateStr) return;

  if (dateStr.includes('T')) {
    return new Date(dateStr);
  }

  const [year, month, day] = dateStr.split('-').map(Number);

  return new Date(year, month - 1, day); // months are 0-indexed
};

export const debounce = (func, delay = 400) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      return func.apply(context, args);
    }, delay);
  };
};

export const MEDIA_QUERY = {
  HEADER_BUTTON_NAMES: '(min-width: 1050px)',
  DESKTOP: '(min-width: 769px)',
  NON_DESKTOP: '(min-width: 320px)',
  MOBILE: '(max-width: 499px)'
};

export const amountFormattedValue = (value, precision = 2) => {
  if (value === undefined || value === '' || value === null) {
    return '';
  }

  let stringValue = value.toString();

  const dotIndex = stringValue.indexOf('.');

  if (dotIndex !== -1) {
    stringValue = stringValue.slice(0, dotIndex + precision + 1);
  }

  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision
  }).format(Number(stringValue));
};

export const format = (val) => {
  const formatted = val?.split('_').join(' ').toLowerCase();
  const capitalized = formatted?.charAt(0).toUpperCase() + formatted?.slice(1);
  return capitalized;
};

export const formatMoney = (amount, precision = COMMON_CURRENCY_PRECISION) => {
  if (amount === undefined || amount === '' || amount === null) {
    return '';
  }

  let stringValue = amount.toString();
  const dotIndex = stringValue.indexOf('.');

  if (dotIndex !== -1) {
    stringValue = stringValue.slice(0, dotIndex + precision + 1);
  }

  const formattedValue = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision
  }).format(Number(stringValue));

  const [integerPart, fractionalPart] = formattedValue.split('.');

  if (!fractionalPart || fractionalPart?.length < 2) {
    return formattedValue;
  }

  return `${integerPart}.${fractionalPart.replace(/0+$/, '').padEnd(2, '0')}`;
};

export const regularFieldRegExp = /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~\s]*$/;
export const countryCodeRegExp = /^[A-Z]+$/;

export const parseExternalKycState = (kycState) => {
  if (!kycState) {
    return {
      statuses: '',
      tags: '',
      identifier: ''
    };
  }

  const { tags, initiated_at, approved_at, rejected_at, identifier } = kycState;
  const statuses = [];
  let parsedTags = '';
  let parsedStatuses = '';

  if (initiated_at) {
    statuses.push(
      i18n.getMessage('customers.externalKycProvider.status.documentsRequested', {
        date: formatDate(initiated_at)
      })
    );
  }
  if (approved_at) {
    statuses.push(
      i18n.getMessage('customers.externalKycProvider.status.approved', {
        date: formatDate(approved_at)
      })
    );
  }
  if (rejected_at) {
    statuses.push(
      i18n.getMessage('customers.externalKycProvider.status.rejected', {
        date: formatDate(rejected_at)
      })
    );
  }

  if (tags) {
    parsedTags = tags.join(', ');
  }
  if (statuses?.length > 0) {
    parsedStatuses = statuses.join(', \n');
  }

  return {
    statuses: parsedStatuses,
    tags: parsedTags,
    identifier: identifier
  };
};

export const isObjectEmpty = (object) => {
  if (!object) return true;

  return Object.keys(object)?.length === 0;
};

export const convertMessageToHtmlAndTrimSpaces = (message) => {
  return message.toString('html').replace(/&nbsp;/g, '');
};

export const convertHtmlToString = (value) => {
  if (!value) return '';

  const cleanedString = value.toString('html').replace(/<[^>]+>/g, '');
  const tempElement = document.createElement('textarea');
  tempElement.innerHTML = cleanedString;

  return tempElement.value;
};

export const convertToTimeDate = (time) => {
  const parts = time.split(':');
  const date = new Date();
  date.setHours(parseInt(parts[0], 10), parseInt(parts[1], 10), parseInt(parts[2] || 0, 10), 0);

  return date;
};

export const convertDateToServerTimeString = (datetime) => {
  if (!datetime) {
    return datetime;
  }

  const dateObject = new Date(datetime);
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
};

export const truncateFileName = (name, maxLength = 30) => {
  if (name?.length <= maxLength) return name;
  const start = name.slice(0, maxLength / 2 - 2);
  const end = name.slice(-maxLength / 2 + 2);
  return `${start}...${end}`;
};

export const getAccountTitle = (account) => {
  return account.transfer_provider === PAYMENT_PROVIDERS.WALLESTER
    ? i18n.getMessage('account.title.cardAccount', { accountNumber: account.wallet_number })
    : account.transfer_provider === PAYMENT_PROVIDERS.MANUAL
      ? i18n.getMessage('account.title.manual', { accountNumber: account.wallet_number })
      : account.multi_wallet_number
        ? account.multi_iban?.iban || account.multi_wallet_number
        : account.iban?.iban || account.wallet_number;
};

export const isCryptoProvider = (paymentProvider) => {
  return paymentProvider === PAYMENT_PROVIDERS.GRAPHEREX;
};

export const formatAccountNumber = (accountNumber) => {
  if (!accountNumber || accountNumber?.length < 12) {
    return accountNumber;
  }

  const firstPart = accountNumber.slice(0, 8);
  const lastPart = accountNumber.slice(-8);

  return `${firstPart}....${lastPart}`;
};

export const getAllCryptoCurrencies = (constants) => {
  if (!constants) {
    return [];
  }

  const providerProperties = constants.providerProperties.GRAPHEREX;

  const allCryptoNetworksCurrencies = Object.values(providerProperties).flatMap(
    (networkData) => networkData.currencies || []
  );

  return Array.from(new Set(allCryptoNetworksCurrencies));
};

export const getCryptoCurrenciesByNetwork = (network, constants) => {
  if (!constants?.providerProperties?.GRAPHEREX || !network) {
    return [];
  }
  const networksArray = Object.values(constants.providerProperties.GRAPHEREX);
  const networkData = networksArray.find((networkItem) => networkItem.code === network);

  return networkData?.currencies ? Array.from(new Set(networkData.currencies)) : [];
};

export const isCryptoCurrency = (currency, allCryptoCurrencies) => {
  if (!currency || !allCryptoCurrencies) {
    return false;
  }

  return allCryptoCurrencies.includes(currency);
};

export const getNetworksByCryptoCurrency = (currency, constants) => {
  if (!currency || !constants?.providerProperties?.GRAPHEREX) return [];

  return (
    Object.entries(constants.providerProperties.GRAPHEREX)
      // eslint-disable-next-line no-unused-vars
      .filter(([_, network]) => network?.currencies?.includes(currency))
      .map(([networkName]) => networkName)
  );
};

const getLimitKeyPartValue = (value) => (!value ? 'ANY' : value.toUpperCase());

const limitObjectKeys = ['limitType', 'transactionType', 'period', 'transferProvider', 'transferType', 'network'];

const getLimitKeyParts = (limit) => limitObjectKeys.map((key) => getLimitKeyPartValue(limit[key]));

export const getLimitMessageKey = (limit) => getLimitKeyParts(limit).join('_');

export const getLimitInfo = (limit) =>
  limitObjectKeys.reduce((info, key) => {
    const value = limit[key];
    if ((value !== undefined && value !== null) || (value === null && key === 'transferProvider')) {
      info[key] = getLimitKeyPartValue(value);
    }
    return info;
  }, {});

export const isPositiveNumber = (value) => value && !isNaN(value) && parseFloat(value) >= 0;

export const momentFromDateAndTime = (day, hour, minute, timezone) => {
  const date = new Date();

  day = day === 'L' ? moment().endOf('month').date() : Number(day);

  return moment.tz(`${date.getFullYear()}-${date.getMonth() + 1}-${day} ${hour}:${minute}`, 'YYYY-MM-D h:m', timezone);
};
