import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Table from 'react-bootstrap/Table';
import queryString from 'query-string';
import debounce from 'lodash-es/debounce';
import { AccountsHeadRow } from './components/AccountsHeadRow';
import { AccountsTableRow } from './components/AccountsTableRow';
import i18nContext from 'components/i18n-context';
import { DIRECTION, MENU_DIRECTION } from 'components/constants';
import EmptyTableContentPlug from 'components/EmptyTableContentPlug';
import CustomSearchInput from 'components/CustomSearchInput';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import './AccountsTable.scss';

const AccountsTable = ({ accountsStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(
    accountsStore.filters.searchText ? accountsStore.filters.searchText : ''
  );

  const updateFiltersInUrl = (filters, pagination) => {
    const queryParams = {
      page: pagination.page,
      size: pagination.size,
      sort_direction: pagination.direction
    };

    if (filters.searchText) {
      queryParams.searchText = filters.searchText;
    }

    if (pagination.sortBy) {
      queryParams.sort_column = pagination.sortBy;
    }

    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    accountsStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!accountsStore.isInitialized) {
      accountsStore.getAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsStore.isInitialized]);

  useEffect(() => {
    if (accountsStore.isInitialized) {
      accountsStore.resetAccountsStore();
      loadFiltersFromUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(accountsStore.filters, accountsStore.pagination);
    if (accountsStore.isInitialized) {
      accountsStore.getAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountsStore.filters.searchText,
    accountsStore.pagination.sortBy,
    accountsStore.pagination.direction,
    accountsStore.pagination.size,
    accountsStore.pagination.page
  ]);

  const handleSetCurrentPage = (pageNumber) => {
    accountsStore.setAccountsPage(pageNumber);
  };

  const handleSetPageSize = (size) => {
    accountsStore.setAccountsPageSize(size);
  };

  const handleOnSortTable = (newSortBy) => {
    accountsStore.setSortData({
      sortBy: newSortBy,
      direction:
        accountsStore.pagination.sortBy !== newSortBy
          ? DIRECTION.ASC
          : accountsStore.pagination.direction === DIRECTION.ASC
            ? DIRECTION.DESC
            : DIRECTION.ASC
    });
  };

  const debouncedSearch = useRef(
    debounce((name, value) => {
      accountsStore.setFilter(name, value);
    }, 400)
  ).current;

  const handleSearchTextInputChange = ({ target: { name, value } }) => {
    setSearchValue(value);

    debouncedSearch(name, value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  if (!accountsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container className={'accounts-container'} header={i18n.getMessage('container.accounts')}>
      <div className={'accounts-actions-wrapper'}>
        <CustomSearchInput
          wrapperClassName={'search-wrapper'}
          inputClassName={'accounts-filters-wrapper'}
          label={i18n.getMessage('accounts.filter.search.label')}
          placeholder={i18n.getMessage('accounts.filter.search.placeholder')}
          name={'searchText'}
          value={searchValue}
          onChange={handleSearchTextInputChange}
          totalElements={accountsStore.pagination.totalElements}
        />

        <Button
          className={'accounts-button'}
          size={'medium'}
          type={'primary'}
          onClick={accountsStore.handleExportAccounts}
          isDisabled={!accountsStore.isInitialized || accountsStore.isLoadingAccountsCsv}
        >
          {accountsStore.isLoadingAccountsCsv ? <Loader /> : i18n.getMessage('accounts.button.accountsCsv')}
        </Button>
      </div>

      {!accountsStore?.accounts || accountsStore?.accounts?.length === 0 ? (
        <EmptyTableContentPlug
          headerText={i18n.getMessage('accounts.table.emptyPlug.header')}
          descriptionText={i18n.getMessage('accounts.table.emptyPlug.description')}
        />
      ) : (
        <>
          <Pagination
            isShowPaginationSize={true}
            paginationSize={accountsStore.pagination.size}
            handleChangePaginationSize={handleSetPageSize}
            isLoading={accountsStore.isLoading}
            currentPage={accountsStore.pagination.page}
            numberOfPages={accountsStore.pagination.totalPages}
            setCurrentPage={handleSetCurrentPage}
          />

          <Table responsive>
            <thead>
              <AccountsHeadRow
                handleOnSortTable={handleOnSortTable}
                sortBy={accountsStore.pagination.sortBy}
                direction={accountsStore.pagination.direction}
              />
            </thead>
            <tbody>
              {accountsStore.accounts?.map((account, index) => (
                <AccountsTableRow key={index} type={'list'} isLoading={accountsStore.isLoading} accountData={account} />
              ))}
            </tbody>
          </Table>

          <Pagination
            isShowPaginationSize={true}
            paginationSize={accountsStore.pagination.size}
            handleChangePaginationSize={handleSetPageSize}
            isLoading={accountsStore.isLoading}
            currentPage={accountsStore.pagination.page}
            numberOfPages={accountsStore.pagination.totalPages}
            setCurrentPage={handleSetCurrentPage}
            menuDirection={MENU_DIRECTION.UP}
          />
        </>
      )}
    </Container>
  );
};

AccountsTable.propTypes = {
  accountsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  accountsStore: stores.accountsStore
}))(observer(AccountsTable));
