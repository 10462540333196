import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import './TariffGroupDetailsTableHeadRow.scss';

const TARIFF_GROUP_DETAILS_TABLE_HEADER_UPDATE = [
  {
    label: 'tariffInfo'
  },
  {
    label: 'tariffAmount'
  },
  {
    label: 'createdAtAndUpdatedAt'
  },
  {
    label: 'status'
  },
  {
    label: 'actions'
  }
];

const TARIFF_GROUP_DETAILS_TABLE_HEADER_CREATE = [
  {
    label: 'tariffInfo'
  },
  {
    label: 'tariffAmount'
  },
  {
    label: 'status'
  },
  {
    label: 'actions'
  }
];

export const TariffGroupDetailsTableHeadRow = ({ isUpdateTariffGroup }) => {
  const i18n = useContext(i18nContext);

  const getTariffGroupDetailsTableHeaderArray = () => {
    return isUpdateTariffGroup ? TARIFF_GROUP_DETAILS_TABLE_HEADER_UPDATE : TARIFF_GROUP_DETAILS_TABLE_HEADER_CREATE;
  };

  return (
    <tr>
      {getTariffGroupDetailsTableHeaderArray().map((head) => (
        <th key={head.label} className={'tariff-group-details-table-head'}>
          <div className={'tariff-group-details-table-head-block'}>
            <span>{i18n.getMessage(`tariffGroupDetails.table.header.${head.label}`)}</span>
          </div>
        </th>
      ))}
    </tr>
  );
};

TariffGroupDetailsTableHeadRow.propTypes = {
  isUpdateTariffGroup: PropTypes.bool
};
