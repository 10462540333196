import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import moment from 'moment/moment';
import Table from 'react-bootstrap/Table';
import queryString from 'query-string';
import CustomerTariffDetailsFiltersBar from './CustomerTariffDetailsFiltersBar';
import { CustomerTariffDetailsHeadRow } from './CustomerTariffDetailsHeadRow';
import { CustomerTariffDetailsTableRow } from './CustomerTariffDetailsTableRow';
import { POP_UP_TYPE } from 'components/constants';
import EmptyTableContentPlug from 'components/EmptyTableContentPlug';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { PopConfirmationScheme } from 'components/PopUpScheme/PopConfirmationScheme';
import PopUpAddNewTariffToTariffGroupScheme from 'components/PopUpScheme/PopUpAddNewTariffToTariffGroupScheme';
import { hasRole, TARIFF_GROUPS_UPDATE } from 'services/roles';
import { ROUTE_PATHS } from 'routes/constants';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import Button from 'uikit/Button/Button';
import { PopUp } from 'uikit/PopUp/PopUp';
import './TariffsDetailsTab.scss';

const popUpInitialData = {
  isShowPopUp: false,
  popUpType: '',
  title: '',
  description: '',
  confirmButtonLabel: '',
  cancelButtonLabel: '',
  onCancel: () => {},
  onConfirm: () => {}
};

const TariffsDetailsTab = ({ tariffsStore, customerNumber, customerTariffGroupId, assignTariffGroupToCustomer }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const [tariffGroupId, setTariffGroupId] = useState(customerTariffGroupId);
  const [tariffListForUpdate, setTariffListForUpdate] = useState(null);
  const [isShowAddNewTariffPopUp, setIsShowAddNewTariffPopUp] = useState(false);
  const [popUpData, setPopUpData] = useState(popUpInitialData);

  const updateTariffListFiltersInUrl = () => {
    const queryParams = tariffsStore.prepareTariffListFiltersParams();
    queryParams.tab = 'tariffs';

    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadTariffFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    tariffsStore.setTariffListFiltersFromUrl(params);
  };

  useEffect(() => {
    loadTariffFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchTariffGroupList = async () => {
      if (!tariffsStore.isInitialized && customerNumber) {
        await tariffsStore.getTariffGroupList(1000);
        await tariffsStore.getTariffFormData();
      }
    };

    fetchTariffGroupList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffsStore.isInitialized, customerNumber]);

  useEffect(() => {
    if (tariffsStore.isInitialized) {
      tariffsStore.resetTariffsStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const fetchCustomerTariffList = async () => {
      updateTariffListFiltersInUrl();

      if (!customerTariffGroupId || !customerNumber) return;

      await tariffsStore.getCustomerTariffList(customerNumber);
    };

    fetchCustomerTariffList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerTariffGroupId,
    customerNumber,
    tariffsStore.tariffListFilters.fee_type,
    tariffsStore.tariffListFilters.scheduled,
    tariffsStore.tariffListFilters.transactional,
    tariffsStore.tariffListFilters.transaction_type,
    tariffsStore.tariffListFilters.transfer_provider,
    tariffsStore.tariffListFilters.transfer_type,
    tariffsStore.tariffListFilters.payment_method,
    tariffsStore.tariffListFilters.currency,
    tariffsStore.tariffListFilters.network,
    tariffsStore.tariffListFilters.enabled
  ]);

  useEffect(() => {
    const fetchCustomerTariffList = async () => {
      updateTariffListFiltersInUrl();

      if (!customerTariffGroupId || !customerNumber) return;

      if (tariffsStore.isCustomerTariffListUpdated) {
        await tariffsStore.getCustomerTariffList(customerNumber);

        if (tariffsStore.isCustomerTariffListUpdated) {
          tariffsStore.setIsCustomerTariffListUpdated(false);
        }
      }
    };

    fetchCustomerTariffList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffsStore.isCustomerTariffListUpdated]);

  useEffect(() => {
    if (tariffsStore.customerTariffList) {
      setTariffListForUpdate(tariffsStore.customerTariffList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffsStore.customerTariffList]);

  const handleChangeCustomerTariffGroup = async (name, value) => {
    setTariffGroupId(value);
    await assignTariffGroupToCustomer(value);
  };

  const handleChangeTariffValue = (tariffIndex, field, value) => {
    setTariffListForUpdate((prevTariffs) =>
      prevTariffs.map((tariff, index) => {
        if (index !== tariffIndex) return tariff;

        if ((field === 'min' || field === 'max') && (value === '' || value === null)) {
          return {
            ...tariff,
            [field]: null,
            isUpdatedTariff: true
          };
        }

        if (field === 'cronExpression') {
          const { day, time } = value;

          const parseOutDate = (date) => {
            return date > 28 ? 'L' : date;
          };

          const utcTime = moment(time).utc();

          return {
            ...tariff,
            cronExpression: `0 ${utcTime.minute()} ${utcTime.hour()} ${parseOutDate(day)} * ? *`,
            isUpdatedTariff: true
          };
        }

        return {
          ...tariff,
          [field]: value,
          isUpdatedTariff: true
        };
      })
    );
  };

  const handleChangeTariffStatus = (tariffIndex, tariffStatus) => {
    setTariffListForUpdate((prevTariffs) =>
      prevTariffs.map((tariff, index) =>
        index === tariffIndex ? { ...tariff, enabled: tariffStatus, isUpdatedTariff: true } : tariff
      )
    );
  };

  const handleAddNewTariff = () => {
    setIsShowAddNewTariffPopUp(true);
  };

  const handleCloseAddNewTariffPopUp = () => {
    setIsShowAddNewTariffPopUp(false);
  };

  const handleAddNewTariffToTariffGroup = async (values) => {
    await tariffsStore.createNewIndividualTariff(customerNumber, values);
  };

  const resetPopUpData = () => {
    setPopUpData(popUpInitialData);
  };

  const handleConfirmTariffDeletion = async (tariffIndexOrTariffId) => {
    setPopUpData({
      isShowPopUp: true,
      popUpType: POP_UP_TYPE.CONFIRMATION,
      title: i18n.getMessage('tariffs.details.confirmModal.deleteTariff.title'),
      description: i18n.getMessage('tariffs.details.confirmModal.deleteTariff.message'),
      confirmButtonLabel: i18n.getMessage('tariffs.details.confirmModal.button.confirm'),
      cancelButtonLabel: i18n.getMessage('tariffs.details.confirmModal.button.cancel'),
      onConfirm: async () => {
        await tariffsStore.deleteIndividualTariff(customerNumber, tariffIndexOrTariffId);
        resetPopUpData();
      },
      onCancel: resetPopUpData
    });
  };

  const handleConfirmTariffApplying = async (tariffId, isIndividualTariff) => {
    setPopUpData({
      isShowPopUp: true,
      popUpType: POP_UP_TYPE.CONFIRMATION,
      title: i18n.getMessage('tariffs.details.confirmModal.applyTariff.title'),
      description: i18n.getMessage('tariffs.details.confirmModal.applyTariff.message'),
      confirmButtonLabel: i18n.getMessage('tariffs.details.confirmModal.button.confirm'),
      cancelButtonLabel: i18n.getMessage('tariffs.details.confirmModal.button.cancel'),
      onConfirm: async () => {
        if (isIndividualTariff) {
          await tariffsStore.applyCustomerIndividualTariff(customerNumber, tariffId);
        } else {
          await tariffsStore.applyCustomerTariff(customerNumber, tariffId);
        }
        resetPopUpData();
      },
      onCancel: resetPopUpData
    });
  };

  const handleUpdateCustomerTariffList = async () => {
    await tariffsStore.updateCustomerTariffList(customerNumber, tariffListForUpdate);
  };

  useEffect(() => {
    if (tariffsStore.isNewIndividualTariffSuccessfullyCreated) {
      handleCloseAddNewTariffPopUp();
      tariffsStore.setIsNewIndividualTariffSuccessfullyCreated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffsStore.isNewIndividualTariffSuccessfullyCreated]);

  const handleEditTariffGroup = () => {
    navigate(`${ROUTE_PATHS.TARIFFS}/${customerTariffGroupId}`);
  };

  if (!tariffsStore.isInitialized || !tariffListForUpdate || !tariffsStore.tariffFormData) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <div className={'customer-tariffs-details-container'}>
      <table className={'customer-tariff-group-details-table-wrapper'}>
        <tbody className={'customer-tariff-group-details-table-body'}>
          <tr className={'customer-tariff-group-details-table-row'}>
            <td className={'customer-tariff-group-details-table-cell customer-tariff-group-details-table-subsection'}>
              {i18n.getMessage('tariffs.details.customerId')}
            </td>
            <td className={'customer-tariff-group-details-table-cell customer-tariff-group-details-table-subsection'}>
              {customerNumber}
            </td>
          </tr>
          <tr className={'customer-tariff-group-details-table-row'}>
            <td className={'customer-tariff-group-details-table-cell'}>
              {i18n.getMessage('tariffs.details.tariffGroup')}
            </td>
            <td className={'customer-tariff-group-details-table-cell'}>
              <div className={'customer-tariff-group-details-table-input-wrapper'}>
                <InputDropDown
                  className={'customer-tariff-group-details-table-cell-input'}
                  id={'tariffGroup'}
                  name={'tariffGroup'}
                  options={tariffsStore?.tariffGroups?.map((group) => ({
                    key: group.tariffGroupId,
                    value: group.tariffGroupId
                  }))}
                  value={tariffGroupId}
                  onChange={handleChangeCustomerTariffGroup}
                  isDisabled={!hasRole(TARIFF_GROUPS_UPDATE)}
                />
                <Button
                  className={'customer-tariff-group-details-edit-button'}
                  size={'small'}
                  type={'primary'}
                  onClick={handleEditTariffGroup}
                >
                  {i18n.getMessage('tariffs.details.button.edit')}
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <Button
        type={'primary'}
        size={'small'}
        className={'tariffs-actions-button'}
        onClick={handleAddNewTariff}
        isDisabled={!tariffsStore.isInitialized || tariffsStore.isLoading || !hasRole(TARIFF_GROUPS_UPDATE)}
      >
        {i18n.getMessage('tariffs.details.addNewIndividualTariff')}
      </Button>

      <CustomerTariffDetailsFiltersBar
        isLoading={tariffsStore.isLoading}
        tariffListFilters={tariffsStore.tariffListFilters}
        tariffFormData={tariffsStore.tariffFormData}
        handleSetTariffListFilters={tariffsStore.setTariffListFilter}
      />

      {!tariffListForUpdate || tariffListForUpdate?.length === 0 ? (
        <EmptyTableContentPlug
          headerText={i18n.getMessage('tariffs.details.table.emptyPlug.header')}
          descriptionText={i18n.getMessage('tariffs.details.table.emptyPlug.description')}
        />
      ) : (
        <>
          <Table responsive>
            <thead>
              <CustomerTariffDetailsHeadRow />
            </thead>
            <tbody>
              {tariffListForUpdate?.map((tariffData, index) => (
                <CustomerTariffDetailsTableRow
                  key={index}
                  type={'list'}
                  isLoading={tariffsStore.isLoading}
                  isUpdateTariffGroup={true}
                  tariffDetailsData={tariffData}
                  tariffIndex={index}
                  tariffGroupName={tariffListForUpdate?.name}
                  handleChangeTariffValue={handleChangeTariffValue}
                  handleChangeTariffGroupStatus={handleChangeTariffStatus}
                  handleDeleteTariff={handleConfirmTariffDeletion}
                  handleApplyCustomerTariff={handleConfirmTariffApplying}
                />
              ))}
            </tbody>
          </Table>

          <div className={'tariffs-details-button'}>
            <Button
              className={'tariffs-actions-button'}
              type={'primary'}
              size={'small'}
              onClick={handleUpdateCustomerTariffList}
              isDisabled={tariffsStore.isLoading || !hasRole(TARIFF_GROUPS_UPDATE)}
            >
              {i18n.getMessage('tariffs.details.button.saveChanges')}
            </Button>
          </div>
        </>
      )}

      <PopUp alignOnCenter show={isShowAddNewTariffPopUp || popUpData.isShowPopUp}>
        {isShowAddNewTariffPopUp && (
          <PopUpAddNewTariffToTariffGroupScheme
            isLoading={tariffsStore.isLoading}
            handleClose={handleCloseAddNewTariffPopUp}
            handleAddNewTariffToTariffGroup={handleAddNewTariffToTariffGroup}
            tariffFormData={tariffsStore.tariffFormData}
            handleGetTariffFormData={tariffsStore.getTariffFormData}
          />
        )}
        {popUpData.isShowPopUp && (
          <PopConfirmationScheme
            title={popUpData.title}
            description={popUpData.description}
            onConfirmButtonTitle={popUpData.confirmButtonLabel}
            onCloseButtonTitle={popUpData.cancelButtonLabel}
            onConfirm={popUpData.onConfirm}
            onClose={popUpData.onCancel}
          />
        )}
      </PopUp>
    </div>
  );
};

TariffsDetailsTab.propTypes = {
  tariffsStore: MobXPropTypes.observableObject,
  customerNumber: PropTypes.string,
  customerTariffGroupId: PropTypes.string,
  assignTariffGroupToCustomer: PropTypes.func
};

export default inject((stores) => ({
  tariffsStore: stores.tariffsStore
}))(observer(TariffsDetailsTab));
