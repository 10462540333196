import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './CustomerMarkupsTabRowContent.scss';

export const CustomerMarkupsTabRowContent = ({ isLoading, markupData }) => {
  const getMarkupInfoBlock = (markupBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'customer-markup-details-table-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else {
      return <p>{markupBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'customer-markup-details-table-cell'}>{getMarkupInfoBlock(markupData.from)}</td>
      <td className={'customer-markup-details-table-cell'}>{getMarkupInfoBlock(markupData.to)}</td>
      <td className={'customer-markup-details-table-cell'}>{getMarkupInfoBlock(markupData.markup)}</td>
    </tr>
  );
};

CustomerMarkupsTabRowContent.propTypes = {
  isLoading: PropTypes.bool,
  markupData: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      markup: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    PropTypes.oneOf([null])
  ])
};
