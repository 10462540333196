import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Table from 'react-bootstrap/Table';
import queryString from 'query-string';
import CustomerLimitDetailsFiltersBar from './CustomerLimitDetailsFiltersBar';
import { CustomerLimitDetailsHeadRow } from './CustomerLimitDetailsHeadRow';
import { CustomerLimitDetailsTableRow } from './CustomerLimitDetailsTableRow';
import { POP_UP_TYPE } from 'components/constants';
import EmptyTableContentPlug from 'components/EmptyTableContentPlug';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { PopConfirmationScheme } from 'components/PopUpScheme/PopConfirmationScheme';
import PopUpAddNewLimitToLimitGroupScheme from 'components/PopUpScheme/PopUpAddNewLimitToLimitGroupScheme';
import { hasRole, LIMIT_GROUPS_UPDATE } from 'services/roles';
import { ROUTE_PATHS } from 'routes/constants';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import Button from 'uikit/Button/Button';
import { PopUp } from 'uikit/PopUp/PopUp';
import './LimitsDetails.scss';

const popUpInitialData = {
  isShowPopUp: false,
  popUpType: '',
  title: '',
  description: '',
  confirmButtonLabel: '',
  cancelButtonLabel: '',
  onCancel: () => {},
  onConfirm: () => {}
};

const LimitsDetails = ({ limitsStore, customerNumber, customerLimitGroupId, assignLimitGroupToCustomer }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const [limitGroupId, setGroupId] = useState(customerLimitGroupId);
  const [limitListForUpdate, setLimitListForUpdate] = useState(null);
  const [isShowAddNewLimitPopUp, setIsShowAddNewLimitPopUp] = useState(false);
  const [popUpData, setPopUpData] = useState(popUpInitialData);

  const updateLimitListFiltersInUrl = () => {
    const queryParams = limitsStore.prepareLimitsListFiltersParams();
    queryParams.tab = 'limits';

    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadLimitFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    limitsStore.setLimitsListFiltersFromUrl(params);
  };

  useEffect(() => {
    loadLimitFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchLimitGroupList = async () => {
      if (!limitsStore.isInitialized && customerNumber) {
        await limitsStore.getLimitGroupsList(1000);
        await limitsStore.getLimitFormData();
      }
    };

    fetchLimitGroupList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitsStore.isInitialized, customerNumber]);

  useEffect(() => {
    if (limitsStore.isInitialized) {
      limitsStore.resetLimitsStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const fetchCustomerLimitList = async () => {
      updateLimitListFiltersInUrl();

      if (!customerLimitGroupId || !customerNumber) return;

      await limitsStore.getCustomerLimitList(customerNumber);
    };

    fetchCustomerLimitList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerLimitGroupId,
    customerNumber,
    limitsStore.limitsListFilters.limit_type,
    limitsStore.limitsListFilters.period,
    limitsStore.limitsListFilters.transaction_type,
    limitsStore.limitsListFilters.transfer_provider,
    limitsStore.limitsListFilters.transfer_type,
    limitsStore.limitsListFilters.currency,
    limitsStore.limitsListFilters.network,
    limitsStore.limitsListFilters.enabled
  ]);

  useEffect(() => {
    const fetchCustomerLimitList = async () => {
      updateLimitListFiltersInUrl();

      if (!customerLimitGroupId || !customerNumber) return;

      if (limitsStore.isCustomerLimitListUpdated) {
        await limitsStore.getCustomerLimitList(customerNumber);

        if (limitsStore.isCustomerLimitListUpdated) {
          limitsStore.setIsCustomerLimitListUpdated(false);
        }
      }
    };

    fetchCustomerLimitList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitsStore.isCustomerLimitListUpdated]);

  useEffect(() => {
    if (limitsStore.customerLimitList) {
      setLimitListForUpdate(limitsStore.customerLimitList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitsStore.customerLimitList]);

  const handleChangeCustomerLimitGroup = async (name, value) => {
    setGroupId(value);
    await assignLimitGroupToCustomer(value);
  };

  const handleChangeLimitValue = (limitType, limitIndex, limitValue) => {
    setLimitListForUpdate((prevLimits) =>
      prevLimits.map((limit, index) => {
        if (index === limitIndex && limitType) {
          return {
            ...limit,
            [limitType]: limitValue,
            isUpdatedLimit: true
          };
        } else if (index === limitIndex && !limitType) {
          return {
            ...limit,
            hold: limitValue,
            isUpdatedLimit: true
          };
        }
        return limit;
      })
    );
  };

  const handleChangeLimitStatus = (limitIndex, limitStatus) => {
    setLimitListForUpdate((prevLimits) =>
      prevLimits.map((limit, index) =>
        index === limitIndex ? { ...limit, enabled: limitStatus, isUpdatedLimit: true } : limit
      )
    );
  };

  const handleAddNewLimit = () => {
    setIsShowAddNewLimitPopUp(true);
  };

  const handleCloseAddNewLimitPopUp = () => {
    setIsShowAddNewLimitPopUp(false);
  };

  const handleAddNewLimitToLimitGroup = async (values) => {
    await limitsStore.createNewIndividualLimit(customerNumber, values);
  };

  const resetPopUpData = () => {
    setPopUpData(popUpInitialData);
  };

  const handleConfirmLimitDeletion = async (limitIndexOrLimitId) => {
    setPopUpData({
      isShowPopUp: true,
      popUpType: POP_UP_TYPE.CONFIRMATION,
      title: i18n.getMessage('limits.details.confirmModal.deleteLimit.title'),
      description: i18n.getMessage('limits.details.confirmModal.deleteLimit.message'),
      confirmButtonLabel: i18n.getMessage('limits.details.confirmModal.button.confirm'),
      cancelButtonLabel: i18n.getMessage('limits.details.confirmModal.button.cancel'),
      onConfirm: async () => {
        await limitsStore.deleteIndividualLimit(customerNumber, limitIndexOrLimitId);
        resetPopUpData();
      },
      onCancel: resetPopUpData
    });
  };

  const handleUpdateCustomerLimitList = async () => {
    await limitsStore.updateCustomerLimitList(customerNumber, limitListForUpdate);
  };

  useEffect(() => {
    if (limitsStore.isNewIndividualLimitSuccessfullyCreated) {
      handleCloseAddNewLimitPopUp();
      limitsStore.setIsNewIndividualLimitSuccessfullyCreated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitsStore.isNewIndividualLimitSuccessfullyCreated]);

  const handleEditLimitGroup = () => {
    navigate(`${ROUTE_PATHS.LIMITS}/${customerLimitGroupId}`);
  };

  if (!limitsStore.isInitialized || !limitListForUpdate || !limitsStore.limitFormData) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <div className={'customer-limits-details-container'}>
      <table className={'customer-limit-group-details-table-wrapper'}>
        <tbody className={'customer-limit-group-details-table-body'}>
          <tr className={'customer-limit-group-details-table-row'}>
            <td className={'customer-limit-group-details-table-cell customer-limit-group-details-table-subsection'}>
              {i18n.getMessage('limits.details.customerId')}
            </td>
            <td className={'customer-limit-group-details-table-cell customer-limit-group-details-table-subsection'}>
              {customerNumber}
            </td>
          </tr>
          <tr className={'customer-limit-group-details-table-row'}>
            <td className={'customer-limit-group-details-table-cell'}>
              {i18n.getMessage('limits.details.limitGroup')}
            </td>
            <td className={'customer-limit-group-details-table-cell'}>
              <div className={'customer-limit-group-details-table-input-wrapper'}>
                <InputDropDown
                  className={'customer-limit-group-details-table-cell-input'}
                  id={'limitGroup'}
                  name={'limitGroup'}
                  options={limitsStore?.limitGroups?.map((group) => ({
                    key: group.limitGroupId,
                    value: group.limitGroupId
                  }))}
                  value={limitGroupId}
                  onChange={handleChangeCustomerLimitGroup}
                  isDisabled={!hasRole(LIMIT_GROUPS_UPDATE)}
                />
                <Button
                  className={'customer-limit-group-details-edit-button'}
                  size={'small'}
                  type={'primary'}
                  onClick={handleEditLimitGroup}
                >
                  {i18n.getMessage('limits.details.button.edit')}
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <Button
        type={'primary'}
        size={'small'}
        className={'limits-actions-button'}
        onClick={handleAddNewLimit}
        isDisabled={!limitsStore.isInitialized || limitsStore.isLoading || !hasRole(LIMIT_GROUPS_UPDATE)}
      >
        {i18n.getMessage('limits.details.addNewIndividualLimit')}
      </Button>

      <CustomerLimitDetailsFiltersBar
        isLoading={limitsStore.isLoading}
        limitListFilters={limitsStore.limitsListFilters}
        limitFormData={limitsStore.limitFormData}
        handleSetLimitListFilters={limitsStore.setLimitsListFilter}
      />

      {!limitListForUpdate || limitListForUpdate?.length === 0 ? (
        <EmptyTableContentPlug
          headerText={i18n.getMessage('limits.details.table.emptyPlug.header')}
          descriptionText={i18n.getMessage('limits.details.table.emptyPlug.description')}
        />
      ) : (
        <>
          <Table responsive>
            <thead>
              <CustomerLimitDetailsHeadRow />
            </thead>
            <tbody>
              {limitListForUpdate?.map((limitData, index) => (
                <CustomerLimitDetailsTableRow
                  key={index}
                  type={'list'}
                  isLoading={limitsStore.isLoading}
                  isUpdateLimitGroup={true}
                  limitDetailsData={limitData}
                  limitIndex={index}
                  limitGroupName={limitListForUpdate?.name}
                  handleChangeLimitValue={handleChangeLimitValue}
                  handleChangeLimitGroupStatus={handleChangeLimitStatus}
                  handleDeleteLimit={handleConfirmLimitDeletion}
                />
              ))}
            </tbody>
          </Table>

          <div className={'limits-details-button'}>
            <Button
              className={'limits-actions-button'}
              type={'primary'}
              size={'small'}
              onClick={handleUpdateCustomerLimitList}
              isDisabled={limitsStore.isLoading || !hasRole(LIMIT_GROUPS_UPDATE)}
            >
              {i18n.getMessage('limits.details.button.saveChanges')}
            </Button>
          </div>
        </>
      )}

      <PopUp alignOnCenter show={isShowAddNewLimitPopUp || popUpData.isShowPopUp}>
        {isShowAddNewLimitPopUp && (
          <PopUpAddNewLimitToLimitGroupScheme
            isLoading={limitsStore.isLoading}
            handleClose={handleCloseAddNewLimitPopUp}
            handleAddNewLimitToLimitGroup={handleAddNewLimitToLimitGroup}
            limitFormData={limitsStore.limitFormData}
            handleGetLimitFormData={limitsStore.getLimitFormData}
          />
        )}
        {popUpData.isShowPopUp && (
          <PopConfirmationScheme
            title={popUpData.title}
            description={popUpData.description}
            onConfirmButtonTitle={popUpData.confirmButtonLabel}
            onCloseButtonTitle={popUpData.cancelButtonLabel}
            onConfirm={popUpData.onConfirm}
            onClose={popUpData.onCancel}
          />
        )}
      </PopUp>
    </div>
  );
};

LimitsDetails.propTypes = {
  limitsStore: MobXPropTypes.observableObject,
  customerNumber: PropTypes.string,
  customerLimitGroupId: PropTypes.string,
  assignLimitGroupToCustomer: PropTypes.func
};

export default inject((stores) => ({
  limitsStore: stores.limitsStore
}))(observer(LimitsDetails));
