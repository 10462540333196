import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from 'react-bootstrap';
import i18nContext from 'components/i18n-context';
import { formatDateTime } from 'services/utils';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import './ExchangeRatesTableRowContent.scss';

export const ExchangeRatesTableRowContent = ({ isLoading, handleChangeExchangeRateStatus, exchangeRateData }) => {
  const i18n = useContext(i18nContext);

  const getExchangeRateInfoBlock = (exchangeRateBlockInfo, isExchangeRateStatus = false) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'exchange-rates-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (isExchangeRateStatus) {
      return (
        <div className={'exchange-rates-status-wrapper'}>
          <CheckBox
            className={'exchange-rates-status-checkbox'}
            name={'active'}
            isActive={exchangeRateBlockInfo}
            isReverseView={true}
            showCheckBox={true}
            onChange={() => handleChangeExchangeRateStatus(exchangeRateData, !exchangeRateBlockInfo)}
            value={i18n.getMessage('exchangeRates.table.rowContent.status.active')}
            disabled={isLoading}
          />
        </div>
      );
    } else {
      return <p>{exchangeRateBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'exchange-rates-table-cell'}>{getExchangeRateInfoBlock(exchangeRateData.from)}</td>
      <td className={'exchange-rates-table-cell'}>{getExchangeRateInfoBlock(exchangeRateData.to)}</td>
      <td className={'exchange-rates-table-cell'}>
        {getExchangeRateInfoBlock(formatDateTime(exchangeRateData.created_at))}
      </td>
      <td className={'exchange-rates-table-cell'}>{getExchangeRateInfoBlock(exchangeRateData.active, true)}</td>
    </tr>
  );
};

ExchangeRatesTableRowContent.propTypes = {
  isLoading: PropTypes.bool,
  handleChangeExchangeRateStatus: PropTypes.func,
  exchangeRateData: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    created_at: PropTypes.string,
    active: PropTypes.bool
  })
};
