import { useContext } from 'react';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { TariffInputControl } from 'components/TariffInputs/TariffInputControl';
import { hasRole, TARIFF_GROUPS_UPDATE } from 'services/roles';
import { formatDateTime, isCryptoProvider } from 'services/utils';
import Button from 'uikit/Button/Button';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import './CustomerTariffDetailsTableRow.scss';

export const CustomerTariffDetailsTableRow = ({
  isLoading,
  tariffIndex,
  tariffDetailsData,
  handleChangeTariffGroupStatus,
  handleChangeTariffValue,
  handleDeleteTariff,
  handleApplyCustomerTariff
}) => {
  const i18n = useContext(i18nContext);

  const getTariffLabel = (tariff) => {
    const transactionalFields = {
      transactional: i18n.getMessage(tariff.transactional ? 'no' : 'yes'),
      transactionType: i18n.getMessage(`transactionType.${tariff.transactionType}`),
      transferProvider: i18n.getMessage(`paymentProvider.${tariff.transferProvider}`),
      transferType: i18n.getMessage(`transferType.${tariff.transferType}`),
      paymentMethod: i18n.getMessage(`paymentMethod.${tariff.paymentMethod}`),
      currency: tariff.currency,
      network: tariff.network
    };

    const scheduledFields = {
      systemTariffType: tariff?.systemTariffKey?.replaceAll('_', ' '),
      transactional: i18n.getMessage(tariff.transactional ? 'no' : 'yes'),
      scheduled: i18n.getMessage(tariff.scheduled ? 'yes' : 'no'),
      currency: tariff.currency
    };

    const info = !tariff.systemTariffKey && tariff.transactional ? transactionalFields : scheduledFields;

    return Object.entries(info)
      .filter(([, value]) => value)
      .map(([key, value], index) => (
        <div key={'tariff-label-part-' + index}>
          {i18n.getMessage(`tariffGroupDetails.labelType.${key}`)}: <strong>{value}</strong>
        </div>
      ));
  };

  const getTariffInfoBlock = (
    tariffBlockInfo,
    isTariffInfo = false,
    isTariffValue = false,
    isTariffCreatedAtAndUpdatedAt = false,
    isStatus = false,
    isDeleteTariff = false
  ) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'customer-tariff-details-table-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (isTariffInfo) {
      return getTariffLabel(tariffDetailsData);
    } else if (isTariffValue) {
      return (
        <TariffInputControl
          tariff={tariffDetailsData}
          tariffIndex={tariffIndex}
          handleChangeValue={handleChangeTariffValue}
          editable={true}
          isCryptoProvider={isCryptoProvider(tariffDetailsData.transferProvider)}
        />
      );
    } else if (isTariffCreatedAtAndUpdatedAt) {
      return (
        <div className={'customer-tariff-details-table-date-and-time'}>
          Created:
          <br /> {formatDateTime(tariffDetailsData.createdAt)} <br />
          <br />
          Updated:
          <br /> {formatDateTime(tariffDetailsData.updatedAt)}
        </div>
      );
    } else if (isStatus) {
      return (
        <div className={'customer-tariff-details-table-status-wrapper'}>
          <CheckBox
            className={'customer-tariff-details-table-status-checkbox'}
            name={'hidden'}
            isActive={tariffBlockInfo}
            isReverseView={true}
            showCheckBox={true}
            onChange={() => handleChangeTariffGroupStatus(tariffIndex, !tariffBlockInfo)}
            value={i18n.getMessage('tariffs.details.table.row.enabled')}
            disabled={isLoading || !hasRole(TARIFF_GROUPS_UPDATE)}
          />
        </div>
      );
    } else if (isDeleteTariff) {
      if (tariffDetailsData.individualTariff || tariffDetailsData.systemTariffKey) {
        return (
          <div className={'customer-tariff-details-table-cell-actions'}>
            {tariffDetailsData.systemTariffKey && (
              <Button
                onClick={() => handleApplyCustomerTariff(tariffDetailsData.id, tariffDetailsData.individualTariff)}
                type={'primary'}
                size={'small'}
                isDisabled={!tariffDetailsData.systemTariffKey || isLoading || !hasRole(TARIFF_GROUPS_UPDATE)}
              >
                {i18n.getMessage('tariffs.details.table.row.apply')}
              </Button>
            )}

            {tariffDetailsData.individualTariff && (
              <Button
                onClick={() => handleDeleteTariff(tariffDetailsData.id)}
                type={'primary'}
                size={'small'}
                isDisabled={!tariffDetailsData.individualTariff || isLoading || !hasRole(TARIFF_GROUPS_UPDATE)}
              >
                {i18n.getMessage('tariffs.details.table.row.delete')}
              </Button>
            )}
          </div>
        );
      }
    } else {
      return <p>{tariffBlockInfo}</p>;
    }
  };

  return (
    <tr
      className={
        tariffDetailsData?.individualTariff
          ? 'customer-tariff-details-table-row-individual'
          : tariffDetailsData?.isUpdatedTariff
            ? 'customer-tariff-details-table-row-updated'
            : ''
      }
    >
      <td className={'customer-tariff-details-table-cell'}>{getTariffInfoBlock(tariffDetailsData, true)}</td>
      <td className={'customer-tariff-details-table-cell'}>{getTariffInfoBlock(tariffDetailsData, false, true)}</td>
      <td className={'customer-tariff-details-table-cell'}>
        {getTariffInfoBlock(
          tariffDetailsData?.individualTariff ? (
            <i>{i18n.getMessage('tariffs.details.table.row.isIndividual')}</i>
          ) : tariffDetailsData?.isUpdatedTariff ? (
            <i>{i18n.getMessage('tariffs.details.table.row.isUpdated')}</i>
          ) : (
            tariffDetailsData.tariffGroupId
          )
        )}
      </td>
      <td className={'customer-tariff-details-table-cell'}>
        {getTariffInfoBlock(tariffDetailsData, false, false, true)}
      </td>
      <td className={'customer-tariff-details-table-cell'}>
        {getTariffInfoBlock(tariffDetailsData.enabled, false, false, false, true)}
      </td>
      <td className={'customer-tariff-details-table-cell'}>
        {getTariffInfoBlock(tariffDetailsData, false, false, false, false, true)}
      </td>
    </tr>
  );
};

CustomerTariffDetailsTableRow.propTypes = {
  isLoading: PropTypes.bool,
  tariffIndex: PropTypes.number,
  handleChangeTariffGroupStatus: PropTypes.func,
  handleChangeTariffValue: PropTypes.func,
  handleDeleteTariff: PropTypes.func,
  handleApplyCustomerTariff: PropTypes.func,
  tariffData: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      tariffGroupId: PropTypes.string,
      systemTariffKey: PropTypes.string,
      feeType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      scheduled: PropTypes.bool,
      transactional: PropTypes.bool,
      transactionType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      transferProvider: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      transferType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      paymentMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      currency: PropTypes.string,
      network: PropTypes.string,
      fixedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      percentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      cronExpression: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      enabled: PropTypes.bool,
      individualTariff: PropTypes.bool
    }),
    PropTypes.oneOf([null])
  ])
};
