import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import AddNewAccountScheme from './AddNewAccountScheme';
import PopUpAccountDetailsScheme from './PopUpAccountDetailsScheme';
import { AccountsTableHeadRow } from './components/AccountsTableHeadRow';
import { AccountsTableContentRow } from './components/AccountsTableContentRow';
import { ACCOUNT_STATUS, CURRENCY } from 'components/constants';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { useApplicationContext } from 'contexts/ApplicationContext';
import { isObjectEmpty } from 'services/utils';
import Button from 'uikit/Button/Button';
import { PopUp } from 'uikit/PopUp/PopUp';
import './AccountsTab.scss';

const AccountsTab = ({
  isLoading,
  accounts,
  updateCustomerDetails,
  disableAccount,
  enableAccount,
  addNewAccount,
  setAccountPrimary,
  handleAdjustAccountAmount
}) => {
  const i18n = useContext(i18nContext);
  const { constants } = useApplicationContext();
  const [singleAccounts, setSingleAccounts] = useState([]);
  const [multiAccounts, setMultiAccounts] = useState({});
  const [accountDetails, setAccountDetails] = useState(null);
  const [isAddNewAccountOpen, setIsAddNewAccountOpen] = useState(false);
  const [isAccountDetailsOpen, setIsAccountDetailsOpen] = useState(false);
  const [accountToAddCurrency, setAccountToAddCurrency] = useState(null);

  useEffect(() => {
    if (accounts) {
      const singleAccountsTemp = [];
      const multiAccountsTemp = {};

      accounts.forEach((account) => {
        const mwn = account.multi_wallet_number;
        if (mwn) {
          if (!(mwn in multiAccountsTemp)) {
            multiAccountsTemp[mwn] = [];
          }
          multiAccountsTemp[mwn].push(account);
        } else {
          singleAccountsTemp.push(account);
        }
      });

      setSingleAccounts(singleAccountsTemp);
      setMultiAccounts(multiAccountsTemp);
    }
  }, [accounts]);

  const handleChangeAccountStatus = async (accountDetails) => {
    if (accountDetails.status === ACCOUNT_STATUS.ACTIVE) {
      await disableAccount(accountDetails.wallet_number);
    } else {
      await enableAccount(accountDetails.wallet_number);
    }
    await updateCustomerDetails();
  };

  const closePopUp = () => {
    setIsAddNewAccountOpen(false);
    setIsAccountDetailsOpen(false);
    setAccountToAddCurrency(null);
    setAccountDetails(null);
  };

  const handleAddNewAccount = async (transferProvider, data) => {
    await addNewAccount(transferProvider, data);
    await updateCustomerDetails();
    closePopUp();
  };

  const handleOpenAddNewCurrencyPopUp = (accountNumber, existingCurrencies) => {
    setAccountToAddCurrency({
      multi_wallet_number: accountNumber,
      existingCurrencies
    });
    setIsAddNewAccountOpen(true);
  };

  const handleOpenAccountDetailsPopUp = (accountDetails) => {
    setAccountDetails(accountDetails);
    setIsAccountDetailsOpen(true);
  };

  const handleSetAccountPrimary = async (accountNumber) => {
    await setAccountPrimary(accountNumber);
    await updateCustomerDetails();
    closePopUp();
  };

  if (!accounts) {
    return <Loader className={'application-loader accounts-tab-loader'} />;
  }

  return (
    <div className={'account-details-container accounts-table'}>
      <Table responsive>
        <thead>
          <AccountsTableHeadRow isMultiCurrency={false} />
        </thead>
        <tbody>
          {singleAccounts?.length > 0 &&
            singleAccounts.map((singleAccount, index) => (
              <AccountsTableContentRow
                key={index}
                isLoading={false}
                isMultiAccount={false}
                accountDetails={singleAccount}
                handleChangeAccountStatus={handleChangeAccountStatus}
                handleOpenAccountDetailsPopUp={handleOpenAccountDetailsPopUp}
              />
            ))}
        </tbody>
      </Table>

      {!isObjectEmpty(multiAccounts) &&
        Object.keys(multiAccounts).map((multiAccountNumber, index) => (
          <Table key={index} responsive>
            <thead>
              <AccountsTableHeadRow
                accountNumber={multiAccountNumber}
                iban={multiAccounts[multiAccountNumber][0]?.multi_iban?.toString()}
                transferProvider={multiAccounts[multiAccountNumber][0].transfer_provider}
                isMultiCurrency={true}
                openAddNewCurrencyPopUp={handleOpenAddNewCurrencyPopUp}
                existingCurrencies={multiAccounts[multiAccountNumber].map((account) => account.currency)}
              />
            </thead>
            <tbody>
              {multiAccounts[multiAccountNumber].length > 0 &&
                multiAccounts[multiAccountNumber].map((multiAccount, index) => (
                  <AccountsTableContentRow
                    key={index}
                    isLoading={false}
                    isMultiAccount={true}
                    accountDetails={multiAccount}
                    handleChangeAccountStatus={handleChangeAccountStatus}
                    handleOpenAccountDetailsPopUp={handleOpenAccountDetailsPopUp}
                  />
                ))}
            </tbody>
          </Table>
        ))}

      <div className={'add-account-button-wrapper'}>
        <Button
          type={'primary'}
          size={'small'}
          onClick={() => setIsAddNewAccountOpen(true)}
          className={'add-account-button'}
        >
          {i18n.getMessage('accountsTab.table.action.addAccount')}
        </Button>
      </div>

      <PopUp alignOnCenter show={isAddNewAccountOpen || isAccountDetailsOpen}>
        {isAddNewAccountOpen ? (
          <AddNewAccountScheme
            isLoading={isLoading}
            isAddCurrency={!!accountToAddCurrency}
            accountForUpdate={accountToAddCurrency}
            currenciesForCreateAccount={Object.keys(CURRENCY)}
            // Place for payout currencies (currenciesForAdd)
            currenciesForAdd={[]}
            paymentProviders={constants?.transferProviders}
            addNewAccount={handleAddNewAccount}
            onClose={closePopUp}
          />
        ) : (
          <PopUpAccountDetailsScheme
            isLoading={isLoading}
            accountDetails={accountDetails}
            handleClose={closePopUp}
            handleSetPrimary={handleSetAccountPrimary}
            handleAdjustAccountAmount={handleAdjustAccountAmount}
            updateCustomerDetails={updateCustomerDetails}
          />
        )}
      </PopUp>
    </div>
  );
};

AccountsTab.propTypes = {
  isLoading: PropTypes.bool,
  accounts: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])]),
  updateCustomerDetails: PropTypes.func.isRequired,
  disableAccount: PropTypes.func,
  enableAccount: PropTypes.func,
  addNewAccount: PropTypes.func,
  setAccountPrimary: PropTypes.func,
  handleAdjustAccountAmount: PropTypes.func
};

export default AccountsTab;
