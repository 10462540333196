import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import { DIRECTION } from 'components/constants';
import {
  addNewLimitToLimitGroupRequest,
  createLimitsGroupRequest,
  createNewIndividualLimitRequest,
  deleteIndividualLimitRequest,
  deleteLimitFromLimitGroupRequest,
  deleteLimitGroupRequest,
  getCustomerLimitListRequest,
  getLimitFormDataRequest,
  getLimitGroupLimitsListByIdRequest,
  getLimitListRequest,
  renameLimitGroupRequest,
  toggleLimitsGroupAvailabilityRequest,
  updateCustomerLimitListRequest,
  updateLimitsGroupRequest
} from 'services/requestAgent';

class LimitsStore {
  isInitialized = false;
  isLoading = false;
  isNewLimitGroupSuccessfullyCreated = false;
  isLimitGroupSuccessfullyDeleted = false;
  isNewLimitToLimitGroupSuccessfullyCreated = false;
  isNewIndividualLimitSuccessfullyCreated = false;
  isCustomerLimitListUpdated = false;
  error = null;
  limitGroups = null;
  limitFormData = null;
  currentLimitGroupDetails = null;
  customerLimitList = null;
  pagination = {
    sortBy: null,
    page: 0,
    size: 20,
    totalPages: 0,
    totalElements: 0,
    direction: DIRECTION.ASC
  };
  filters = {
    searchText: ''
  };
  limitsListFilters = {
    limit_type: '',
    period: '',
    transaction_type: '',
    transfer_provider: '',
    transfer_type: '',
    currency: '',
    network: '',
    enabled: false
  };

  constructor() {
    makeAutoObservable(this);
  }

  resetLimitsStore = () => {
    this.isInitialized = false;
    this.isLoading = false;
    this.isNewLimitGroupSuccessfullyCreated = false;
    this.isLimitGroupSuccessfullyDeleted = false;
    this.isNewLimitToLimitGroupSuccessfullyCreated = false;
    this.isNewIndividualLimitSuccessfullyCreated = false;
    this.isCustomerLimitListUpdated = false;
    this.error = null;
    this.limitGroups = null;
    this.limitFormData = null;
    this.currentLimitGroupDetails = null;
    this.customerLimitList = null;
    this.pagination = {
      sortBy: null,
      page: 0,
      size: 20,
      totalPages: 0,
      totalElements: 0,
      direction: DIRECTION.ASC
    };
    this.filters = {
      searchText: ''
    };
    this.limitsListFilters = {
      limit_type: '',
      period: '',
      transaction_type: '',
      transfer_provider: '',
      transfer_type: '',
      currency: '',
      network: '',
      enabled: false
    };
  };

  setIsLoading = (status) => {
    this.isLoading = status;
    this.error = null;
  };

  setIsNewLimitGroupSuccessfullyCreated = (status) => {
    this.isNewLimitGroupSuccessfullyCreated = status;
  };

  setIsLimitGroupSuccessfullyDeleted = (status) => {
    this.isLimitGroupSuccessfullyDeleted = status;
  };

  setIsNewLimitToLimitGroupSuccessfullyCreated = (status) => {
    this.isNewLimitToLimitGroupSuccessfullyCreated = status;
  };

  setIsNewIndividualLimitSuccessfullyCreated = (status) => {
    this.isNewIndividualLimitSuccessfullyCreated = status;
  };

  setIsCustomerLimitListUpdated = (status) => {
    this.isCustomerLimitListUpdated = status;
  };

  setLimitGroupsPage = (page) => {
    this.pagination.page = page;
  };

  setLimitGroupsPageSize = (size) => {
    this.pagination.size = size;
  };

  setFilter = (fieldName, value) => {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  };

  setLimitsListFilter = (fieldName, value) => {
    this.limitsListFilters[fieldName] = value;
  };

  setSortData = (sortData) => {
    this.pagination.sortBy = sortData.sortBy;
    this.pagination.direction = sortData.direction;
  };

  setFiltersFromUrl = (params) => {
    // Copying parameters from the URL to the filters object properties
    this.filters.searchText = params.searchText || this.filters.searchText;

    // Converting pagination parameters from the URL
    this.pagination.page = parseInt(params.page) || this.pagination.page;
    this.pagination.size = parseInt(params.size) || this.pagination.size;

    // Converting sorting parameters from the URL
    this.pagination.sortBy = params.sort_column || this.pagination.sortBy;
    this.pagination.direction = params.sort_direction || this.pagination.direction;
  };

  setLimitsListFiltersFromUrl = (params) => {
    const filters = { ...this.limitsListFilters };

    // Mapping URL filter parameters to corresponding properties in the 'limitsListFilters' object
    const filterParamsMapping = {
      limit_type: 'limit_type',
      period: 'period',
      transaction_type: 'transaction_type',
      transfer_provider: 'transfer_provider',
      transfer_type: 'transfer_type',
      currency: 'currency',
      network: 'network',
      enabled: 'enabled'
    };

    // Iterating over each URL parameter and assigning its values to 'limitsListFilters'
    for (const param in params) {
      if (param in filterParamsMapping) {
        let value = params[param];

        // Handle only array values as arrays, keep others as their original types
        if (Array.isArray(value)) {
          filters[filterParamsMapping[param]] = value;
        } else {
          filters[filterParamsMapping[param]] = value;
        }
      }
    }

    this.limitsListFilters = filters;
  };

  prepareLimitsListFiltersParams = () => {
    const params = {};

    Object.entries(this.limitsListFilters).forEach(([key, value]) => {
      if (value) {
        params[key] = value;
      }
    });

    return params;
  };

  getLimitGroupsList = async (pageSize = undefined) => {
    this.setIsLoading(true);
    try {
      const {
        content: limitGroupsList,
        number: page,
        size,
        total_elements: totalElements,
        total_pages: totalPages
      } = await getLimitListRequest(
        this.pagination.sortBy,
        this.pagination.page,
        pageSize ? pageSize : this.pagination.size,
        this.pagination.direction,
        this.filters.searchText
      );

      runInAction(() => {
        this.isInitialized = true;
        this.limitGroups = limitGroupsList;
        this.pagination = {
          ...this.pagination,
          page,
          size: !pageSize ? size : this.pagination.size,
          totalElements,
          totalPages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getCustomerLimitList = async (customerNumber) => {
    this.setIsLoading(true);
    try {
      const queryParams = this.prepareLimitsListFiltersParams();
      const limitList = await getCustomerLimitListRequest(customerNumber, queryParams);

      runInAction(() => {
        this.isInitialized = true;
        this.customerLimitList = limitList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getLimitFormData = async () => {
    this.setIsLoading(true);
    try {
      const limitFormData = await getLimitFormDataRequest();

      runInAction(() => {
        this.limitFormData = limitFormData;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  toggleLimitsGroupAvailability = async (limitGroupId, status) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(toggleLimitsGroupAvailabilityRequest(limitGroupId, status), {
      pending: i18n.getMessage('limitsStore.toggleLimitsGroupAvailability.toastPromise.pending'),
      success: i18n.getMessage('limitsStore.toggleLimitsGroupAvailability.toastPromise.success')
    });

    try {
      const limitGroupDetails = await toastPromise;

      const updatedLimitGroupsList = this.limitGroups.map((limitGroup) =>
        limitGroup.limitGroupId === limitGroupId ? limitGroupDetails : limitGroup
      );

      runInAction(() => {
        this.limitGroups = updatedLimitGroupsList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getLimitGroupLimitsListById = async (limitGroupId) => {
    this.setIsLoading(true);
    try {
      const queryParams = this.prepareLimitsListFiltersParams();
      const limitGroupLimitsList = await getLimitGroupLimitsListByIdRequest(limitGroupId, queryParams);

      const currentLimitGroupDetails = this.limitGroups.find((limitGroup) => limitGroup.limitGroupId === limitGroupId);

      currentLimitGroupDetails.limits = limitGroupLimitsList;

      runInAction(() => {
        this.isInitialized = true;
        this.currentLimitGroupDetails = currentLimitGroupDetails;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  createLimitsGroup = async (data) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(createLimitsGroupRequest(data), {
      pending: i18n.getMessage('limitsStore.createLimitsGroup.toastPromise.pending'),
      success: i18n.getMessage('limitsStore.createLimitsGroup.toastPromise.success')
    });

    const newLimitGroup = await toastPromise;

    try {
      runInAction(() => {
        this.currentLimitGroupDetails = newLimitGroup;
        this.isNewLimitGroupSuccessfullyCreated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  renameLimitGroup = async (limitGroupId, data) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(renameLimitGroupRequest(limitGroupId, data), {
      pending: i18n.getMessage('limitsStore.renameLimitGroup.toastPromise.pending'),
      success: i18n.getMessage('limitsStore.renameLimitGroup.toastPromise.success')
    });

    try {
      const updatedLimitGroupDetails = await toastPromise;

      const updatedLimitGroupList = this.limitGroups.map((limitGroup) =>
        limitGroup.limitGroupId === limitGroupId ? updatedLimitGroupDetails : limitGroup
      );

      runInAction(() => {
        this.limitGroups = updatedLimitGroupList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  deleteLimitGroup = async (limitGroupId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(deleteLimitGroupRequest(limitGroupId), {
      pending: i18n.getMessage('limitsStore.deleteLimitGroup.toastPromise.pending'),
      success: i18n.getMessage('limitsStore.deleteLimitGroup.toastPromise.success')
    });

    try {
      await toastPromise;

      const updatedLimitGroupList = this.limitGroups.filter((limitGroup) => limitGroup.limitGroupId !== limitGroupId);

      runInAction(() => {
        this.limitGroups = updatedLimitGroupList;
        this.isLimitGroupSuccessfullyDeleted = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  updateLimitsGroup = async (limitGroupId, limits) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateLimitsGroupRequest(limitGroupId, limits), {
      pending: i18n.getMessage('limitsStore.updateLimitsGroup.toastPromise.pending'),
      success: i18n.getMessage('limitsStore.updateLimitsGroup.toastPromise.success')
    });

    try {
      const updatedLimitGroupLimitsList = await toastPromise;

      runInAction(() => {
        this.currentLimitGroupDetails.limits = updatedLimitGroupLimitsList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  updateCustomerLimitList = async (customerNumber, limits) => {
    this.setIsLoading(true);

    const data = {
      createLimits: [],
      updateLimits: []
    };

    if (limits && limits?.length > 0) {
      limits.forEach((limit) => {
        if (limit?.isUpdatedLimit && !limit?.individualLimit) {
          data.createLimits.push(limit);
        } else if (limit?.isUpdatedLimit && limit?.individualLimit) {
          data.updateLimits.push(limit);
        }
      });
    }

    const toastPromise = toast.promise(updateCustomerLimitListRequest(customerNumber, data), {
      pending: i18n.getMessage('limitsStore.updateCustomerLimitList.toastPromise.pending'),
      success: i18n.getMessage('limitsStore.updateCustomerLimitList.toastPromise.success')
    });

    try {
      await toastPromise;

      runInAction(() => {
        this.isCustomerLimitListUpdated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  deleteLimitFromLimitGroup = async (limitGroupId, limitId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(deleteLimitFromLimitGroupRequest(limitGroupId, limitId), {
      pending: i18n.getMessage('limitsStore.deleteLimitFromLimitGroup.toastPromise.pending'),
      success: i18n.getMessage('limitsStore.deleteLimitFromLimitGroup.toastPromise.success')
    });

    try {
      await toastPromise;

      const updatedLimitList = this.currentLimitGroupDetails.limits.filter((limit) => limit.id !== limitId);

      runInAction(() => {
        this.currentLimitGroupDetails.limits = updatedLimitList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  deleteIndividualLimit = async (customerNumber, limitId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(deleteIndividualLimitRequest(customerNumber, limitId), {
      pending: i18n.getMessage('limitsStore.deleteIndividualLimit.toastPromise.pending'),
      success: i18n.getMessage('limitsStore.deleteIndividualLimit.toastPromise.success')
    });

    try {
      await toastPromise;

      const updatedLimitList = this.customerLimitList.filter((limit) => limit.id !== limitId);

      runInAction(() => {
        this.customerLimitList = updatedLimitList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  addNewLimitToLimitGroup = async (limitGroupId, data) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(addNewLimitToLimitGroupRequest(limitGroupId, data), {
      pending: i18n.getMessage('limitsStore.addNewLimitToLimitGroup.toastPromise.pending'),
      success: i18n.getMessage('limitsStore.addNewLimitToLimitGroup.toastPromise.success')
    });

    try {
      const newLimit = await toastPromise;

      runInAction(() => {
        this.currentLimitGroupDetails.limits = [...this.currentLimitGroupDetails.limits, newLimit];
        this.isNewLimitToLimitGroupSuccessfullyCreated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  createNewIndividualLimit = async (customerNumber, data) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(createNewIndividualLimitRequest(customerNumber, data), {
      pending: i18n.getMessage('limitsStore.createNewIndividualLimit.toastPromise.pending'),
      success: i18n.getMessage('limitsStore.createNewIndividualLimit.toastPromise.success')
    });

    try {
      const newLimit = await toastPromise;

      runInAction(() => {
        this.customerLimitList = [newLimit, ...this.customerLimitList];
        this.isNewIndividualLimitSuccessfullyCreated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}

export default new LimitsStore();
