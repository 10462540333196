import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import { MAX_NEW_MARKUP_GROUP_NAME_LENGTH } from 'components/constants';
import i18nContext from 'components/i18n-context';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import './PopUpRenameMarkupGroupScheme.scss';

const PopUpRenameMarkupGroupScheme = ({ isLoading, markupGroupId, handleClose, handleRenameMarkupGroup }) => {
  const i18n = useContext(i18nContext);

  const form = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      newMarkupGroupName: ''
    },
    validationSchema: Yup.object().shape({
      newMarkupGroupName: Yup.string()
        .required(i18n.getMessage('renameMarkupGroup.modal.validation.isRequired'))
        .max(
          MAX_NEW_MARKUP_GROUP_NAME_LENGTH,
          i18n.getMessage('renameMarkupGroup.modal.validation.maxSize"', { size: MAX_NEW_MARKUP_GROUP_NAME_LENGTH })
        )
    }),
    onSubmit: async (values) => {
      await handleRenameMarkupGroup(markupGroupId, { markupGroupId: values.newMarkupGroupName });
    }
  });

  const { values, handleSubmit, errors, handleChange } = form;

  return (
    <div className={'scheme-wrapper scheme-rename-markup-group-container'}>
      <div className={'scheme-rename-markup-group-container-header'}>
        <h3>{i18n.getMessage('renameMarkupGroup.modal.title')}</h3>
        <CloseIcon className={'scheme-rename-markup-group-container-header-close-icon'} onClick={handleClose} />
      </div>
      <form className={'scheme-rename-markup-group-form'} onSubmit={handleSubmit}>
        <div className={'scheme-rename-markup-group-form-inputs-wrapper'}>
          <Input
            className={'scheme-rename-markup-group-input'}
            id={'newMarkupGroupName'}
            name={'newMarkupGroupName'}
            label={i18n.getMessage('renameMarkupGroup.modal.input.newMarkupGroupName')}
            value={values.newMarkupGroupName}
            onChange={handleChange}
            max={MAX_NEW_MARKUP_GROUP_NAME_LENGTH}
            error={errors?.newMarkupGroupName}
          />
        </div>

        <div className={'scheme-rename-markup-group-actions-wrapper'}>
          <Button
            className={'scheme-rename-markup-group-button'}
            type={'outline'}
            onClick={handleClose}
            isDisabled={isLoading}
          >
            {i18n.getMessage('renameMarkupGroup.modal.button.cancel')}
          </Button>
          <Button
            className={'scheme-rename-markup-group-button'}
            roleType={'submit'}
            type={'primary'}
            isDisabled={isLoading}
          >
            {i18n.getMessage('renameMarkupGroup.modal.button.rename')}
          </Button>
        </div>
      </form>
    </div>
  );
};

PopUpRenameMarkupGroupScheme.propTypes = {
  isLoading: PropTypes.bool,
  markupGroupId: PropTypes.string,
  handleClose: PropTypes.func,
  handleRenameMarkupGroup: PropTypes.func
};

export default PopUpRenameMarkupGroupScheme;
