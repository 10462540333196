import { useContext } from 'react';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { AMOUNT_INPUT_VALUE_TYPE } from 'components/constants';
import i18nContext from 'components/i18n-context';
import AmountInput from 'uikit/AmountInput';
import './MarkupGroupDetailsTableRowContent.scss';

export const MarkupGroupDetailsTableRowContent = ({ isLoading, markupIndex, handleChangeMarkupValue, markupData }) => {
  const i18n = useContext(i18nContext);

  const getMarkupInfoBlock = (markupBlockInfo, isMarkupValue = false) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'markup-group-details-table-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (isMarkupValue) {
      return (
        <div className={'markup-value-wrapper'}>
          <AmountInput
            className={'table-cell-input'}
            id={'markup'}
            name={'markup'}
            returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
            value={markupBlockInfo}
            isDisabled={isLoading}
            onChange={(e) => handleChangeMarkupValue(markupIndex, e.target.value)}
          />
          <div className={'markup-value-symbol'}>
            {i18n.getMessage('markupGroupDetails.table.rowContent.inputLabel.markup')}
          </div>
        </div>
      );
    } else {
      return <p>{markupBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'markup-group-details-table-cell'}>{getMarkupInfoBlock(markupData.from)}</td>
      <td className={'markup-group-details-table-cell'}>{getMarkupInfoBlock(markupData.to)}</td>
      <td className={'markup-group-details-table-cell'}>{getMarkupInfoBlock(markupData.markup, true)}</td>
    </tr>
  );
};

MarkupGroupDetailsTableRowContent.propTypes = {
  isLoading: PropTypes.bool,
  markupIndex: PropTypes.number,
  handleChangeMarkupValue: PropTypes.func,
  markupData: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      markup: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    PropTypes.oneOf([null])
  ])
};
