import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import WithdrawalTransactionsFiltersBar from './components/WithdrawalTransactionsFiltersBar';
import WithdrawalTransactionsTableHead from './components/WithdrawalTransactionsTableHead';
import WithdrawalTransactionsTableRow from './components/WithdrawalTransactionsTableRow';
import { DIRECTION, MENU_DIRECTION } from 'components/constants';
import EmptyTableContentPlug from 'components/EmptyTableContentPlug';
import Loader from 'components/Loader';
import i18nContext from 'components/i18n-context';
import Pagination from 'components/Pagination/Pagination';
import './WithdrawalTransactions.scss';

const WithdrawalTransactions = ({ transitTransactionsStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();

  const updateFiltersInUrl = (filters, pagination, sortColumn) => {
    const filtersParams = transitTransactionsStore.prepareFiltersParams();
    const queryParams = {
      ...filtersParams,
      page: pagination.page,
      size: pagination.size,
      sort_column: sortColumn.sort_column,
      sort_direction: sortColumn.sort_direction
    };
    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    transitTransactionsStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!transitTransactionsStore.isInitialized) {
      transitTransactionsStore.getCommissionSystemTransactionsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transitTransactionsStore.isInitialized]);

  useEffect(() => {
    if (transitTransactionsStore.isInitialized) {
      transitTransactionsStore.resetTransitTransactionStore();
      loadFiltersFromUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(
      transitTransactionsStore.filters,
      transitTransactionsStore.pagination,
      transitTransactionsStore.sortColumn
    );
    if (transitTransactionsStore.isInitialized) {
      transitTransactionsStore.getCommissionSystemTransactionsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    transitTransactionsStore.filters.from,
    transitTransactionsStore.filters.to,
    transitTransactionsStore.filters.transfer_providers,
    transitTransactionsStore.filters.currencies,
    transitTransactionsStore.pagination.size,
    transitTransactionsStore.pagination.page,
    transitTransactionsStore.sortColumn.sort_column,
    transitTransactionsStore.sortColumn.sort_direction
  ]);

  const handleOnSortTable = (newSortBy) => {
    transitTransactionsStore.setSortData({
      sortBy: newSortBy,
      direction:
        transitTransactionsStore.sortColumn.sort_column !== newSortBy
          ? DIRECTION.ASC
          : transitTransactionsStore.sortColumn.sort_direction === DIRECTION.ASC
            ? DIRECTION.DESC
            : DIRECTION.ASC
    });
  };

  if (!transitTransactionsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <div className={'withdrawal-transactions-wrapper'}>
      <WithdrawalTransactionsFiltersBar
        isLoading={transitTransactionsStore.isLoading}
        isInitialized={transitTransactionsStore.isInitialized}
        isDownloadCommissionSystemTransactionsReport={
          transitTransactionsStore.isDownloadCommissionSystemTransactionsReport
        }
        filters={transitTransactionsStore.filters}
        handleSetFilters={transitTransactionsStore.setFilter}
        handleDownloadWithdrawalTransactionsReport={transitTransactionsStore.loadWithdrawalTransactionsCsvReport}
      />

      {transitTransactionsStore.commissionSystemTransactionsList?.length === 0 ? (
        <EmptyTableContentPlug
          headerText={i18n.getMessage('commissions.withdrawalTransactions.table.emptyPlug.header')}
          descriptionText={i18n.getMessage('commissions.withdrawalTransactions.table.emptyPlug.description')}
        />
      ) : (
        <>
          <Pagination
            isShowPaginationSize={true}
            paginationSize={transitTransactionsStore.pagination.size}
            handleChangePaginationSize={transitTransactionsStore.setPageSize}
            isLoading={transitTransactionsStore.isLoading}
            currentPage={transitTransactionsStore.pagination.page}
            numberOfPages={transitTransactionsStore.pagination.totalPages}
            setCurrentPage={transitTransactionsStore.setPageNumber}
          />

          <Table responsive>
            <thead>
              <WithdrawalTransactionsTableHead
                handleOnSortTable={handleOnSortTable}
                sortBy={transitTransactionsStore.sortColumn.sort_column}
                direction={transitTransactionsStore.sortColumn.sort_direction}
              />
            </thead>
            <tbody>
              {transitTransactionsStore.commissionSystemTransactionsList?.map((withdrawalTransaction, index) => (
                <WithdrawalTransactionsTableRow
                  key={index}
                  type={'list'}
                  isLoading={transitTransactionsStore.isLoading}
                  withdrawalTransactionData={withdrawalTransaction}
                />
              ))}
            </tbody>
          </Table>

          <Pagination
            isShowPaginationSize={true}
            paginationSize={transitTransactionsStore.pagination.size}
            handleChangePaginationSize={transitTransactionsStore.setPageSize}
            isLoading={transitTransactionsStore.isLoading}
            currentPage={transitTransactionsStore.pagination.page}
            numberOfPages={transitTransactionsStore.pagination.totalPages}
            setCurrentPage={transitTransactionsStore.setPageNumber}
            menuDirection={MENU_DIRECTION.UP}
          />
        </>
      )}
    </div>
  );
};

WithdrawalTransactions.propTypes = {
  transitTransactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  transitTransactionsStore: stores.transitTransactionsStore
}))(observer(WithdrawalTransactions));
