import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';
import './LimitGroupDetailsFiltersBar.scss';

const LimitGroupDetailsFiltersBar = ({ isLoading, limitListFilters, handleSetLimitListFilters, limitFormData }) => {
  const i18n = useContext(i18nContext);

  const limitTypeOptions = limitFormData?.limitTypes?.map((type) => {
    return {
      key: type,
      value: i18n.getMessage('limitType.' + type)
    };
  });

  const periodOptions = limitFormData?.periods?.map((period) => {
    return {
      key: period,
      value: i18n.getMessage('limitPeriod.' + period)
    };
  });

  const transactionsTypeOptions = limitFormData?.transactionTypes?.map((type) => {
    return {
      key: type,
      value: i18n.getMessage('transactionType.' + type)
    };
  });

  const transferProviderOptions = limitFormData?.transferProviders?.map((provider) => {
    return {
      key: provider,
      value: i18n.getMessage('paymentProvider.' + provider)
    };
  });

  const transferTypeOptions = limitFormData?.transferTypes?.map((type) => {
    return {
      key: type,
      value: i18n.getMessage('transferType.' + type)
    };
  });

  const currencyOptions = limitFormData?.currencies?.map((currency) => {
    return {
      key: currency,
      value: currency
    };
  });

  const networkOptions = limitFormData?.networks?.map((network) => {
    return {
      key: network,
      value: network
    };
  });

  return (
    <div className={'limit-list-filters-container'}>
      <InputDropDown
        className={'limit-list-filter-wrapper'}
        name={'limit_type'}
        label={i18n.getMessage('limits.details.filter.limitType.label')}
        value={limitListFilters.limit_type}
        options={limitTypeOptions}
        onChange={handleSetLimitListFilters}
        isMulti
        isDisabled={isLoading}
      />
      <InputDropDown
        className={'limit-list-filter-wrapper'}
        label={i18n.getMessage('limits.details.filter.period.label')}
        name={'period'}
        value={limitListFilters.period}
        options={periodOptions}
        onChange={handleSetLimitListFilters}
        isMulti
        isDisabled={isLoading}
      />
      <InputDropDown
        className={'limit-list-filter-wrapper'}
        label={i18n.getMessage('limits.details.filter.transactionType.label')}
        name={'transaction_type'}
        value={limitListFilters.transaction_type}
        options={transactionsTypeOptions}
        onChange={handleSetLimitListFilters}
        isMulti
        isDisabled={isLoading}
      />
      <InputDropDown
        className={'limit-list-filter-wrapper'}
        label={i18n.getMessage('limits.details.filter.transferProvider.label')}
        name={'transfer_provider'}
        value={limitListFilters.transfer_provider}
        options={transferProviderOptions}
        onChange={handleSetLimitListFilters}
        isMulti
        isDisabled={isLoading}
      />
      <InputDropDown
        className={'limit-list-filter-wrapper'}
        label={i18n.getMessage('limits.details.filter.transferType.label')}
        name={'transfer_type'}
        value={limitListFilters.transfer_type}
        options={transferTypeOptions}
        onChange={handleSetLimitListFilters}
        isMulti
        isDisabled={isLoading}
      />
      <InputDropDownSearch
        className={'limit-list-filter-wrapper-input-dropdown-search'}
        label={i18n.getMessage('limits.details.filter.currency.label')}
        name={'currency'}
        value={limitListFilters.currency}
        options={currencyOptions}
        onChange={handleSetLimitListFilters}
        isMulti
        isDisabled={isLoading}
      />
      <InputDropDown
        className={'limit-list-filter-wrapper'}
        label={i18n.getMessage('limits.details.filter.network.label')}
        name={'network'}
        value={limitListFilters.network}
        options={networkOptions}
        onChange={handleSetLimitListFilters}
        isMulti
        isDisabled={isLoading}
      />
      <CheckBox
        className={'limit-list-filter-checkbox'}
        name={'enabled'}
        isActive={limitListFilters.enabled}
        isReverseView={false}
        showCheckBox={true}
        onChange={() => handleSetLimitListFilters('enabled', !limitListFilters.enabled)}
        value={i18n.getMessage('limits.details.filter.enabled.label')}
        disabled={isLoading}
      />
    </div>
  );
};

LimitGroupDetailsFiltersBar.propTypes = {
  isLoading: PropTypes.bool,
  isInitialized: PropTypes.bool,
  limitListFilters: PropTypes.object,
  limitFormData: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  handleSetLimitListFilters: PropTypes.func
};

export default LimitGroupDetailsFiltersBar;
