import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InternalTransactionPartyControl from './components/InternalTransactionPartyControl';
import ExternalTransactionPartyControl from './components/ExternalTransactionPartyControl';
import { fromErrorDto, toDto } from './components/PaymentTransactionModel';
import TransitTransactionAsyncSelect from './components/TransitTransactionAsyncSelect';
import {
  AMOUNT_INPUT_VALUE_TYPE,
  MAX_TRANSACTION_COMMENT_LENGTH,
  MAX_UPLOADED_FILES_SIZE,
  PAYMENT_METHOD,
  PAYMENT_PROVIDER,
  TRANSACTION_PARTY_TYPE,
  TRANSACTION_TYPES,
  TRANSACTION_REQUISITES_TAB,
  ERROR_CODES
} from 'components/constants';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { convertBytesToMegabytes } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import { AttachDoc } from 'uikit/AttachDoc/AttachDoc';
import AmountInput from 'uikit/AmountInput';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';
import './PaymentTransaction.scss';

const PaymentTransaction = ({ transactionsStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const [currencyOptions, setCurrencyOptions] = useState([]);

  const form = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      transactionType: TRANSACTION_TYPES.INCOMING,
      amount: '',
      commission: '',
      currency: '',
      paymentMethod: '',
      comment: '',
      commentToAdmin: '',
      senderAccount: null,
      recipientAccount: null,
      paymentProvider: '',
      partyType: TRANSACTION_PARTY_TYPE.INDIVIDUAL,
      partyFirstName: '',
      partyLastName: '',
      partyCompanyName: '',
      activePartyTab: TRANSACTION_REQUISITES_TAB.IBAN,
      iban: '',
      accountNumber: '',
      sortCode: '',
      bankCountry: null,
      bankName: '',
      bic: '',
      registrationNumber: null,
      partyCity: null,
      partyCountry: null,
      partyPostCode: null,
      partyAddress: null,
      filename: '',
      transitTransaction: null,
      documents: []
    },
    validationSchema: Yup.object({
      paymentProvider: Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
      paymentMethod: Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
      senderAccount: Yup.object().when(['transactionType', 'paymentMethod'], {
        is: (transactionType, paymentMethod) =>
          transactionType === TRANSACTION_TYPES.OUTGOING ||
          (transactionType === TRANSACTION_TYPES.INCOMING && paymentMethod === PAYMENT_METHOD.INTERNAL),
        then: () => Yup.object().required(i18n.getMessage('createTransaction.validation.isRequired')),
        otherwise: () => Yup.object().nullable().notRequired()
      }),
      recipientAccount: Yup.object().when(['transactionType', 'paymentMethod'], {
        is: (transactionType, paymentMethod) =>
          transactionType === TRANSACTION_TYPES.INCOMING ||
          (transactionType === TRANSACTION_TYPES.OUTGOING && paymentMethod === PAYMENT_METHOD.INTERNAL),
        then: () => Yup.object().required(i18n.getMessage('createTransaction.validation.isRequired')),
        otherwise: () => Yup.object().nullable().notRequired()
      }),
      partyFirstName: Yup.string().when(['partyType', 'paymentMethod'], {
        is: (partyType, paymentMethod) =>
          partyType === TRANSACTION_PARTY_TYPE.INDIVIDUAL && paymentMethod !== PAYMENT_METHOD.INTERNAL,
        then: () => Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
        otherwise: () => Yup.string().nullable().notRequired()
      }),
      partyLastName: Yup.string().when(['partyType', 'paymentMethod'], {
        is: (partyType, paymentMethod) =>
          partyType === TRANSACTION_PARTY_TYPE.INDIVIDUAL && paymentMethod !== PAYMENT_METHOD.INTERNAL,
        then: () => Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
        otherwise: () => Yup.string().nullable().notRequired()
      }),
      partyCompanyName: Yup.string().when(['partyType', 'paymentMethod'], {
        is: (partyType, paymentMethod) =>
          partyType === TRANSACTION_PARTY_TYPE.COMPANY && paymentMethod !== PAYMENT_METHOD.INTERNAL,
        then: () => Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
        otherwise: () => Yup.string().nullable().notRequired()
      }),
      partyCountry: Yup.string().when('paymentMethod', {
        is: (paymentMethod) => paymentMethod === PAYMENT_METHOD.SWIFT || paymentMethod === PAYMENT_METHOD.TARGET2,
        then: () => Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
        otherwise: () => Yup.string().nullable().notRequired()
      }),
      iban: Yup.string().when(['activePartyTab', 'paymentMethod'], {
        is: (activePartyTab, paymentMethod) =>
          activePartyTab === TRANSACTION_REQUISITES_TAB.IBAN && paymentMethod !== PAYMENT_METHOD.INTERNAL,
        then: () => Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
        otherwise: () => Yup.string().nullable().notRequired()
      }),
      accountNumber: Yup.string().when(['activePartyTab', 'paymentMethod'], {
        is: (activePartyTab, paymentMethod) =>
          (activePartyTab === TRANSACTION_REQUISITES_TAB.LOCAL_BANK_ACCOUNT ||
            activePartyTab === TRANSACTION_REQUISITES_TAB.NON_IBAN_BANK_COUNTRIES) &&
          paymentMethod !== PAYMENT_METHOD.INTERNAL,
        then: () => Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
        otherwise: () => Yup.string().nullable().notRequired()
      }),
      sortCode: Yup.string().when(['activePartyTab', 'paymentMethod'], {
        is: (activePartyTab, paymentMethod) =>
          activePartyTab === TRANSACTION_REQUISITES_TAB.LOCAL_BANK_ACCOUNT && paymentMethod !== PAYMENT_METHOD.INTERNAL,
        then: () => Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
        otherwise: () => Yup.string().nullable().notRequired()
      }),
      bankCountry: Yup.string().when(['activePartyTab', 'paymentMethod'], {
        is: (activePartyTab, paymentMethod) =>
          activePartyTab === TRANSACTION_REQUISITES_TAB.NON_IBAN_BANK_COUNTRIES &&
          paymentMethod !== PAYMENT_METHOD.INTERNAL,
        then: () => Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
        otherwise: () => Yup.string().nullable().notRequired()
      }),
      bankName: Yup.string().when(['activePartyTab', 'paymentMethod'], {
        is: (activePartyTab, paymentMethod) =>
          activePartyTab === TRANSACTION_REQUISITES_TAB.NON_IBAN_BANK_COUNTRIES &&
          paymentMethod !== PAYMENT_METHOD.INTERNAL,
        then: () => Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
        otherwise: () => Yup.string().nullable().notRequired()
      }),
      bic: Yup.string().when(['activePartyTab', 'paymentMethod'], {
        is: (activePartyTab, paymentMethod) =>
          activePartyTab === TRANSACTION_REQUISITES_TAB.NON_IBAN_BANK_COUNTRIES &&
          paymentMethod !== PAYMENT_METHOD.INTERNAL,
        then: () => Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
        otherwise: () => Yup.string().nullable().notRequired()
      }),
      amount: Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
      commission: Yup.string().notRequired(),
      currency: Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
      comment: Yup.string()
        .required(i18n.getMessage('createTransaction.validation.isRequired'))
        .max(
          MAX_TRANSACTION_COMMENT_LENGTH,
          i18n.getMessage('createTransaction.validation.maxSize', { size: MAX_TRANSACTION_COMMENT_LENGTH })
        ),
      commentToAdmin: Yup.string()
        .nullable()
        .max(
          MAX_TRANSACTION_COMMENT_LENGTH,
          i18n.getMessage('createTransaction.validation.maxSize', { size: MAX_TRANSACTION_COMMENT_LENGTH })
        )
    }),
    onSubmit: async (values) => {
      values.transactionType === TRANSACTION_TYPES.OUTGOING
        ? await transactionsStore.createOutgoingTransaction(toDto(values))
        : await transactionsStore.createIncomingTransaction(toDto(values));
    }
  });

  const { values, errors, handleSubmit, handleChange, setErrors, setFieldValue, resetForm, validateField } = form;

  useEffect(() => {
    if (transactionsStore.isNewTransactionCreated) {
      resetForm();
      transactionsStore.setIsNewTransactionCreated(false);
      navigate(`${ROUTE_PATHS.TRANSACTIONS}/${transactionsStore.newTransactionId}`);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsStore.isNewTransactionCreated]);

  const parseServerErrors = (code, fields) => {
    if (code === ERROR_CODES.ARGUMENT_NOT_VALID) {
      const converted = fromErrorDto(fields);
      Object.keys(converted).forEach((key) => {
        converted[key] = converted[key] ? i18n.getMessage(`error.field.${converted[key]}`) : undefined;
      });

      setErrors(converted);
    }
  };

  useEffect(() => {
    if (transactionsStore.error) {
      parseServerErrors(transactionsStore.error.code, transactionsStore.error.fields);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsStore.error]);

  const transactionsTypesOptions = Object.keys(TRANSACTION_TYPES).map((type) => {
    return {
      key: TRANSACTION_TYPES[type],
      value: i18n.getMessage('transactionType.' + TRANSACTION_TYPES[type])
    };
  });

  useEffect(() => {
    if (values.paymentMethod && values.paymentProvider) {
      validateField('paymentProvider');
      validateField('paymentMethod');
      setCurrencyOptions(
        PAYMENT_PROVIDER[values.paymentProvider].paymentMethods[values.paymentMethod].map((currency) => {
          return {
            key: currency,
            value: currency
          };
        })
      );
    } else {
      setCurrencyOptions([]);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.paymentProvider, values.paymentMethod]);

  useEffect(() => {
    if (values.recipientAccount) {
      validateField('recipientAccount');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.recipientAccount]);

  useEffect(() => {
    if (values.senderAccount) {
      validateField('senderAccount');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.senderAccount]);

  const handleChangeValue = (name, value) => {
    return setFieldValue(name, value);
  };

  const handleChangeTransitTransactionValue = (name, value) => {
    setFieldValue(name, value);
    if (value?.amount) {
      setFieldValue('amount', value?.amount);
    }
  };

  const onBlurValidate = (fieldName) => {
    validateField(fieldName);
  };

  const handleChangeTransactionType = (name, transactionType) => {
    if (transactionType !== values.transactionType) {
      resetForm();
      setFieldValue(name, transactionType);
    }
  };

  useEffect(() => {
    setFieldValue('documents', transactionsStore.uploadedTransactionFiles);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsStore.uploadedTransactionFiles]);

  return (
    <div className={'payment-transaction-wrapper'}>
      <div className={'payment-transaction-type-wrapper'}>
        <InputDropDown
          className={'payment-transaction-select-transaction-type'}
          label={i18n.getMessage('createTransaction.payment.transactionType')}
          id={'transactionType'}
          name={'transactionType'}
          value={values.transactionType}
          options={transactionsTypesOptions}
          onChange={handleChangeTransactionType}
        />
      </div>
      <form className={'payment-transaction-form'} onSubmit={handleSubmit}>
        {values.transactionType === TRANSACTION_TYPES.INCOMING && (
          <>
            <InternalTransactionPartyControl
              account={values.recipientAccount}
              paymentProvider={values.paymentProvider}
              paymentMethod={values.paymentMethod}
              setCurrencyOptions={setCurrencyOptions}
              handleChangeValue={handleChangeValue}
              property={'recipientAccount'}
              accountLabel={i18n.getMessage('createTransaction.payment.toAccount')}
              isShowPaymentMethod={true}
              isShowPaymentProvider={true}
              errors={errors}
              validateField={onBlurValidate}
            />

            {values.paymentMethod !== PAYMENT_METHOD.INTERNAL && (
              <TransitTransactionAsyncSelect
                property={'transitTransaction'}
                handleChangeValue={handleChangeTransitTransactionValue}
                error={errors.transitTransaction}
              />
            )}

            {values.paymentMethod === PAYMENT_METHOD.INTERNAL && (
              <InternalTransactionPartyControl
                account={values.senderAccount}
                paymentProvider={values.paymentProvider}
                paymentMethod={values.paymentMethod}
                handleChangeValue={handleChangeValue}
                property={'senderAccount'}
                accountLabel={i18n.getMessage('createTransaction.payment.fromAccount')}
                isShowPaymentMethod={false}
                errors={errors}
                validateField={onBlurValidate}
              />
            )}

            {values.paymentMethod !== PAYMENT_METHOD.INTERNAL && (
              <ExternalTransactionPartyControl
                data={values}
                handleChangeValue={handleChange}
                handleChangeCountryValue={handleChangeValue}
                errors={errors}
                partyLabel={i18n.getMessage('createTransaction.payment.partyLabel.sender')}
                setFieldValue={setFieldValue}
                validateField={onBlurValidate}
              />
            )}
          </>
        )}

        {values.transactionType === TRANSACTION_TYPES.OUTGOING && (
          <>
            <InternalTransactionPartyControl
              account={values.senderAccount}
              paymentProvider={values.paymentProvider}
              paymentMethod={values.paymentMethod}
              handleChangeValue={handleChangeValue}
              property={'senderAccount'}
              accountLabel={i18n.getMessage('createTransaction.payment.fromAccount')}
              isShowPaymentProvider={false}
              isShowPaymentMethod={true}
              validateField={onBlurValidate}
            />

            {values.paymentMethod === PAYMENT_METHOD.INTERNAL && (
              <InternalTransactionPartyControl
                account={values.recipientAccount}
                paymentProvider={values.paymentProvider}
                paymentMethod={values.paymentMethod}
                handleChangeValue={handleChangeValue}
                property={'recipientAccount'}
                accountLabel={i18n.getMessage('createTransaction.payment.toAccount')}
                isShowPaymentMethod={false}
                validateField={onBlurValidate}
              />
            )}

            {values.paymentMethod !== PAYMENT_METHOD.INTERNAL && (
              <ExternalTransactionPartyControl
                data={values}
                handleChangeValue={handleChange}
                handleChangeCountryValue={handleChangeValue}
                errors={errors}
                partyLabel={i18n.getMessage('createTransaction.payment.partyLabel.recipient')}
                setFieldValue={setFieldValue}
              />
            )}
          </>
        )}

        <div className={'payment-transaction-block-wrapper'}>
          <div className={'payment-transaction-row-block'}>
            <AmountInput
              isRequired
              className={'payment-transaction-input'}
              label={i18n.getMessage('createTransaction.payment.amount.label')}
              placeholder={i18n.getMessage('createTransaction.payment.amount.placeholder')}
              name={'amount'}
              value={values?.amount}
              returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
              onChange={handleChange}
              onBlur={() => onBlurValidate('amount')}
              isDisabled={!!values.transitTransaction}
              error={errors?.amount}
            />
            <AmountInput
              isRequired
              className={'payment-transaction-input'}
              label={i18n.getMessage('createTransaction.payment.commission.label')}
              placeholder={i18n.getMessage('createTransaction.payment.commission.placeholder')}
              name={'commission'}
              value={values?.commission}
              onBlur={() => onBlurValidate('commission')}
              returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
              onChange={handleChange}
              error={errors?.commission}
            />
            <InputDropDownSearch
              isRequired
              className={'payment-transaction-select-currency'}
              label={i18n.getMessage('createTransaction.payment.currency')}
              id={'currency'}
              name={'currency'}
              value={values?.currency}
              options={currencyOptions}
              onChange={handleChangeValue}
              onBlur={() => onBlurValidate('currency')}
              isDisabled={
                (!!values.recipientAccount && values.transactionType === TRANSACTION_TYPES.INCOMING) ||
                values.transactionType === TRANSACTION_TYPES.OUTGOING
              }
              error={errors?.currency}
            />
          </div>

          <Input
            isRequired
            type={'textarea'}
            rows={2}
            className={'payment-transaction-textarea'}
            label={i18n.getMessage('createTransaction.payment.commentToTransfer')}
            id={'comment'}
            name={'comment'}
            value={values.comment}
            onChange={handleChange}
            onBlur={() => onBlurValidate('comment')}
            error={errors?.comment}
            max={MAX_TRANSACTION_COMMENT_LENGTH}
            subText={i18n.getMessage('createTransaction.payment.symbolsLeft', {
              symbolsLeft: MAX_TRANSACTION_COMMENT_LENGTH - values.comment?.length
            })}
          />

          <Input
            type={'textarea'}
            rows={2}
            className={'payment-transaction-textarea'}
            label={i18n.getMessage('createTransaction.payment.commentToAdmin')}
            id={'commentToAdmin'}
            name={'commentToAdmin'}
            value={values.commentToAdmin}
            onChange={handleChange}
            onBlur={() => onBlurValidate('commentToAdmin')}
            error={errors?.commentToAdmin}
            max={MAX_TRANSACTION_COMMENT_LENGTH}
            subText={i18n.getMessage('createTransaction.payment.symbolsLeft', {
              symbolsLeft: MAX_TRANSACTION_COMMENT_LENGTH - values.commentToAdmin?.length
            })}
          />

          <AttachDoc
            label={i18n.getMessage('createTransaction.payment.attachDocument')}
            className={'payment-transaction-attachment'}
            type={'file'}
            size={MAX_UPLOADED_FILES_SIZE}
            files={transactionsStore.uploadedTransactionFiles}
            onChange={transactionsStore.uploadTransactionAttachments}
            onRemoveFiles={transactionsStore.removeTransactionAttachment}
            isDisabled={transactionsStore.isLoading || transactionsStore.isTransactionFileUploading}
            hintText={i18n.getMessage('attachDoc.hint.text', {
              maxFilesSize: convertBytesToMegabytes(MAX_UPLOADED_FILES_SIZE)
            })}
          />
        </div>

        <div className={'payment-transaction-submit-button-wrapper'}>
          <Button
            className={'payment-transaction-submit-button'}
            type={'primary'}
            roleType={'submit'}
            size={'large'}
            onClick={() => {}}
            isDisabled={transactionsStore.isLoading}
          >
            {transactionsStore.isLoading ? (
              <Loader />
            ) : (
              i18n.getMessage('createTransaction.payment.button.createTransaction')
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

PaymentTransaction.propTypes = {
  transactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  transactionsStore: stores.transactionsStore
}))(observer(PaymentTransaction));
