import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import debounce from 'lodash-es/debounce';
import Table from 'react-bootstrap/Table';
import queryString from 'query-string';
import { LimitGroupsTableHeadRow } from './components/LimitGroupsTableHeadRow';
import { LimitGroupsTableRowContent } from './components/LimitGroupsTableRowContent';
import PopUpRenameLimitGroupScheme from './components/PopUpRenameLimitGroupScheme';
import { DIRECTION, POP_UP_TYPE } from 'components/constants';
import CustomSearchInput from 'components/CustomSearchInput';
import EmptyTableContentPlug from 'components/EmptyTableContentPlug';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import { PopConfirmationScheme } from 'components/PopUpScheme/PopConfirmationScheme';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import { PopUp } from 'uikit/PopUp/PopUp';
import './LimitGroupsTable.scss';

const popUpInitialData = {
  isShowPopUp: false,
  limitGroupId: null,
  popUpType: '',
  title: '',
  description: '',
  confirmButtonLabel: '',
  cancelButtonLabel: '',
  onCancel: () => {},
  onConfirm: () => {}
};

const LimitGroupsTable = ({ limitsStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(limitsStore.filters.searchText ? limitsStore.filters.searchText : '');
  const [popUpData, setPopUpData] = useState(popUpInitialData);

  const updateFiltersInUrl = (filters, pagination) => {
    const queryParams = {
      page: pagination.page,
      size: pagination.size,
      sort_direction: pagination.direction
    };

    if (filters.searchText) {
      queryParams.searchText = filters.searchText;
    }

    if (pagination.sortBy) {
      queryParams.sort_column = pagination.sortBy;
    }

    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    limitsStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!limitsStore.isInitialized) {
      limitsStore.getLimitGroupsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitsStore.isInitialized]);

  useEffect(() => {
    if (limitsStore.isInitialized) {
      limitsStore.resetLimitsStore();
      loadFiltersFromUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(limitsStore.filters, limitsStore.pagination);
    if (limitsStore.isInitialized) {
      limitsStore.getLimitGroupsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    limitsStore.filters.searchText,
    limitsStore.pagination.sortBy,
    limitsStore.pagination.direction,
    limitsStore.pagination.size,
    limitsStore.pagination.page
  ]);

  useEffect(() => {
    if (limitsStore.isLimitGroupSuccessfullyDeleted) {
      limitsStore.getLimitGroupsList();
      limitsStore.setIsLimitGroupSuccessfullyDeleted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitsStore.isLimitGroupSuccessfullyDeleted]);

  const handleClickCreateLimitGroupButton = () => {
    navigate(ROUTE_PATHS.LIMITS_CREATE_GROUP);
  };

  const handleSetCurrentPage = (pageNumber) => {
    limitsStore.setLimitGroupsPage(pageNumber);
  };

  const handleSetPageSize = (size) => {
    limitsStore.setLimitGroupsPageSize(size);
  };

  const handleOnSortTable = (newSortBy) => {
    limitsStore.setSortData({
      sortBy: newSortBy,
      direction:
        limitsStore.pagination.sortBy !== newSortBy
          ? DIRECTION.ASC
          : limitsStore.pagination.direction === DIRECTION.ASC
            ? DIRECTION.DESC
            : DIRECTION.ASC
    });
  };

  const debouncedSearch = useRef(
    debounce((name, value) => {
      limitsStore.setFilter(name, value);
    }, 400)
  ).current;

  const handleSearchTextInputChange = ({ target: { name, value } }) => {
    setSearchValue(value);

    debouncedSearch(name, value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const resetPopUpData = () => {
    setPopUpData(popUpInitialData);
  };

  const handleConfirmLimitDeletion = async (limitGroupId) => {
    setPopUpData({
      isShowPopUp: true,
      popUpType: POP_UP_TYPE.CONFIRMATION,
      title: i18n.getMessage('limitGroups.confirmModal.deleteLimit.title'),
      description: i18n.getMessage('limitGroups.confirmModal.deleteLimit.message'),
      confirmButtonLabel: i18n.getMessage('limitGroups.confirmModal.button.confirm'),
      cancelButtonLabel: i18n.getMessage('limitGroups.confirmModal.button.cancel'),
      onConfirm: async () => {
        await limitsStore.deleteLimitGroup(limitGroupId);
        resetPopUpData();
      },
      onCancel: resetPopUpData
    });
  };

  const handleRenameLimitGroup = async (limitGroupId) => {
    setPopUpData({
      isShowPopUp: true,
      popUpType: 'RENAME_LIMIT_GROUP',
      limitGroupId: limitGroupId,
      onConfirm: async (limitGroupId, data) => {
        await limitsStore.renameLimitGroup(limitGroupId, data);
        resetPopUpData();
      },
      onCancel: resetPopUpData
    });
  };

  if (!limitsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container className={'limit-groups-container'} header={i18n.getMessage('container.limits')}>
      <div className={'limit-groups-actions-wrapper'}>
        <CustomSearchInput
          wrapperClassName={'search-wrapper'}
          inputClassName={'limit-groups-filters-wrapper'}
          label={i18n.getMessage('limitGroups.filter.search.label')}
          placeholder={i18n.getMessage('limitGroups.filter.search.placeholder')}
          name={'searchText'}
          value={searchValue}
          onChange={handleSearchTextInputChange}
          totalElements={limitsStore.pagination.totalElements}
        />

        <Button
          className={'limit-groups-button'}
          size={'medium'}
          type={'primary'}
          onClick={handleClickCreateLimitGroupButton}
          isDisabled={!limitsStore.isInitialized}
        >
          {i18n.getMessage('limitGroups.button.createNewLimitGroup')}
        </Button>
      </div>

      {!limitsStore.limitGroups || limitsStore.limitGroups?.length === 0 ? (
        <EmptyTableContentPlug
          headerText={i18n.getMessage('limitGroups.table.emptyPlug.header')}
          descriptionText={i18n.getMessage('limitGroups.table.emptyPlug.description')}
        />
      ) : (
        <>
          <Pagination
            isShowPaginationSize={true}
            paginationSize={limitsStore.pagination.size}
            handleChangePaginationSize={handleSetPageSize}
            isLoading={limitsStore.isLoading}
            currentPage={limitsStore.pagination.page}
            numberOfPages={limitsStore.pagination.totalPages}
            setCurrentPage={handleSetCurrentPage}
          />

          <Table responsive>
            <thead>
              <LimitGroupsTableHeadRow
                handleOnSortTable={handleOnSortTable}
                sortBy={limitsStore.pagination.sortBy}
                direction={limitsStore.pagination.direction}
              />
            </thead>
            <tbody>
              {limitsStore.limitGroups?.map((limitGroup, index) => (
                <LimitGroupsTableRowContent
                  key={index}
                  type={'list'}
                  isLoading={limitsStore.isLoading}
                  limitGroupData={limitGroup}
                  handleChangeLimitGroupStatus={limitsStore.toggleLimitsGroupAvailability}
                  handleDeleteLimitGroup={handleConfirmLimitDeletion}
                  handleRenameLimitGroup={handleRenameLimitGroup}
                />
              ))}
            </tbody>
          </Table>

          <Pagination
            isShowPaginationSize={true}
            paginationSize={limitsStore.pagination.size}
            handleChangePaginationSize={handleSetPageSize}
            isLoading={limitsStore.isLoading}
            currentPage={limitsStore.pagination.page}
            numberOfPages={limitsStore.pagination.totalPages}
            setCurrentPage={handleSetCurrentPage}
          />
        </>
      )}

      <PopUp alignOnCenter show={popUpData.isShowPopUp}>
        {popUpData.popUpType === POP_UP_TYPE.CONFIRMATION ? (
          <PopConfirmationScheme
            isLoading={limitsStore.isLoading}
            title={popUpData.title}
            description={popUpData.description}
            onConfirmButtonTitle={popUpData.confirmButtonLabel}
            onCloseButtonTitle={popUpData.cancelButtonLabel}
            onConfirm={popUpData.onConfirm}
            onClose={popUpData.onCancel}
          />
        ) : (
          <PopUpRenameLimitGroupScheme
            isLoading={limitsStore.isLoading}
            limitGroupId={popUpData.limitGroupId}
            handleClose={popUpData.onCancel}
            handleRenameLimitGroup={popUpData.onConfirm}
          />
        )}
      </PopUp>
    </Container>
  );
};

LimitGroupsTable.propTypes = {
  limitsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  limitsStore: stores.limitsStore
}))(observer(LimitGroupsTable));
