import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import {
  AMOUNT_INPUT_VALUE_TYPE,
  COMMON_CURRENCY_PRECISION,
  CRYPTO_CURRENCY_PRECISION,
  CURRENCY,
  FEE_TYPE
} from 'components/constants';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { useApplicationContext } from 'contexts/ApplicationContext';
import newTariffModel from 'pages/Tariffs/components/models/newTariffModel';
import {
  getAllCryptoCurrencies,
  getNetworksByCryptoCurrency,
  isCryptoCurrency,
  isPositiveNumber
} from 'services/utils';
import AmountInput from 'uikit/AmountInput';
import Button from 'uikit/Button/Button';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';
import './PopUpAddNewTariffToTariffGroupScheme.scss';

const PopUpAddNewTariffToTariffGroupScheme = ({
  isLoading,
  isTariffGroupCreation,
  handleClose,
  handleAddNewTariffToTariffGroup,
  tariffFormData,
  handleGetTariffFormData
}) => {
  const i18n = useContext(i18nContext);
  const { constants } = useApplicationContext();
  const allCryptoCurrencies = getAllCryptoCurrencies(constants);
  const [networkOptions, setNetworkOptions] = useState(
    tariffFormData
      ? tariffFormData?.networks.map((value) => ({
          key: value,
          value: value
        }))
      : []
  );

  useEffect(() => {
    if (!tariffFormData) {
      handleGetTariffFormData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffFormData]);

  const form = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      transactionType: '',
      transferProvider: '',
      transferType: '',
      paymentMethod: '',
      feeType: FEE_TYPE.FIXED,
      currency: CURRENCY.EUR,
      network: null,
      enabled: true,
      fixedValue: '0',
      percentValue: '0',
      min: null,
      max: null
    },
    validationSchema: Yup.object().shape({
      transactionType: Yup.string().required(i18n.getMessage('addNewTariffForTariffGroup.modal.validation.isRequired')),
      transferType: Yup.string().required(i18n.getMessage('addNewTariffForTariffGroup.modal.validation.isRequired')),
      transferProvider: Yup.string().required(
        i18n.getMessage('addNewTariffForTariffGroup.modal.validation.isRequired')
      ),
      paymentMethod: Yup.string().required(i18n.getMessage('addNewTariffForTariffGroup.modal.validation.isRequired')),
      feeType: Yup.string().required(i18n.getMessage('addNewTariffForTariffGroup.modal.validation.isRequired')),
      currency: Yup.string()
        .nullable()
        .required(i18n.getMessage('addNewTariffForTariffGroup.modal.validation.isRequired')),
      network: Yup.string().when('currency', {
        is: (currency) => isCryptoCurrency(currency, allCryptoCurrencies),
        then: (schema) => schema.required(i18n.getMessage('addNewTariffForTariffGroup.modal.validation.isRequired')),
        otherwise: (schema) => schema.notRequired().nullable()
      }),
      fixedValue: Yup.string().when('feeType', {
        is: (feeType) => feeType === FEE_TYPE.FIXED,
        then: (schema) =>
          schema
            .required(i18n.getMessage('addNewTariffForTariffGroup.modal.validation.isRequired'))
            .test(
              'is-positive',
              i18n.getMessage('addNewTariffForTariffGroup.modal.validation.isPositiveNumber', { min: 0 }),
              (value) => isPositiveNumber(value)
            ),
        otherwise: (schema) => schema.notRequired().nullable()
      }),
      percentValue: Yup.string().when('feeType', {
        is: (feeType) => feeType === FEE_TYPE.PERCENT,
        then: (schema) =>
          schema
            .required(i18n.getMessage('addNewTariffForTariffGroup.modal.validation.isRequired'))
            .test(
              'is-positive',
              i18n.getMessage('addNewTariffForTariffGroup.modal.validation.isPositiveNumber', { min: 0 }),
              (value) => isPositiveNumber(value)
            ),
        otherwise: (schema) => schema.notRequired().nullable()
      }),
      min: Yup.string().when('feeType', {
        is: (feeType) => feeType === FEE_TYPE.PERCENT,
        then: (schema) =>
          schema
            .notRequired()
            .nullable()
            .test(
              'is-positive',
              i18n.getMessage('addNewTariffForTariffGroup.modal.validation.isPositiveNumber', { min: 0 }),
              (value) => value !== '' || (value !== null && isPositiveNumber(value))
            ),
        otherwise: (schema) => schema.notRequired().nullable()
      }),
      max: Yup.string().when('feeType', {
        is: (feeType) => feeType === FEE_TYPE.PERCENT,
        then: (schema) =>
          schema
            .notRequired()
            .nullable()
            .test(
              'is-positive',
              i18n.getMessage('addNewTariffForTariffGroup.modal.validation.isPositiveNumber', { min: 0 }),
              (value) => value !== '' || (value !== null && isPositiveNumber(value))
            ),
        otherwise: (schema) => schema.notRequired().nullable()
      })
    }),
    onSubmit: async (values) => {
      await handleAddNewTariffToTariffGroup(newTariffModel(values, !!isTariffGroupCreation));
    }
  });

  const { values, handleSubmit, errors, handleChange, setFieldValue } = form;

  const transactionTypeOptions =
    tariffFormData &&
    tariffFormData?.transactionTypes.map((value) => ({
      key: value,
      value: i18n.getMessage(`transactionType.${value}`)
    }));

  const transferTypeOptions =
    tariffFormData &&
    tariffFormData?.transferTypes.map((value) => ({
      key: value,
      value: i18n.getMessage(`transferType.${value}`)
    }));

  const feeTypeOptions =
    tariffFormData &&
    Object.values(FEE_TYPE).map((value) => ({
      key: value,
      value: i18n.getMessage(`tariffType.${value}`)
    }));

  const transferProviderOptions =
    tariffFormData &&
    tariffFormData?.transferProviders.map((value) => ({
      key: value,
      value: i18n.getMessage(`paymentProvider.${value}`)
    }));

  const paymentMethodOptions =
    tariffFormData &&
    tariffFormData?.paymentMethods.map((value) => ({
      key: value,
      value: i18n.getMessage(`paymentMethod.${value}`)
    }));

  const currencyOptions =
    tariffFormData &&
    tariffFormData?.currencies.map((value) => ({
      key: value,
      value: value
    }));

  useEffect(() => {
    if (values.currency && isCryptoCurrency(values.currency, allCryptoCurrencies)) {
      const allNetworks = getNetworksByCryptoCurrency(values.currency, constants);

      if (!allNetworks && allNetworks.length === 0) {
        setNetworkOptions([]);
      } else {
        setNetworkOptions(
          allNetworks.map((value) => ({
            key: value,
            value: value
          }))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.currency]);

  if (!tariffFormData) {
    return <Loader className={'application-loader scheme-add-new-tariff-container'} />;
  }

  return (
    <div className={'scheme-wrapper scheme-add-new-tariff-container'}>
      <div className={'scheme-add-new-tariff-container-header'}>
        <h3>{i18n.getMessage('addNewTariffForTariffGroup.modal.title')}</h3>
        <CloseIcon className={'scheme-add-new-tariff-container-header-close-icon'} onClick={handleClose} />
      </div>
      <form className={'scheme-add-new-tariff-form'} onSubmit={handleSubmit}>
        <div className={'scheme-add-new-tariff-form-inputs-wrapper'}>
          <InputDropDown
            className={'scheme-add-new-tariff-input'}
            id={'transactionType'}
            name={'transactionType'}
            label={i18n.getMessage('addNewTariffForTariffGroup.modal.input.transactionType')}
            options={transactionTypeOptions}
            value={values.transactionType || ''}
            onChange={(name, value) => setFieldValue(name, value)}
            isDisabled={isLoading}
            error={errors?.transactionType}
          />

          <InputDropDown
            className={'scheme-add-new-tariff-input'}
            id={'transferType'}
            name={'transferType'}
            label={i18n.getMessage('addNewTariffForTariffGroup.modal.input.transferType')}
            options={transferTypeOptions}
            value={values.transferType || ''}
            onChange={(name, value) => setFieldValue(name, value)}
            isDisabled={isLoading}
            error={errors?.transferType}
          />

          <InputDropDown
            className={'scheme-add-new-tariff-input'}
            id={'transferProvider'}
            name={'transferProvider'}
            label={i18n.getMessage('addNewTariffForTariffGroup.modal.input.transferProvider')}
            options={transferProviderOptions}
            value={values.transferProvider || ''}
            onChange={(name, value) => setFieldValue(name, value)}
            isDisabled={isLoading}
            error={errors?.transferProvider}
          />

          <InputDropDown
            className={'scheme-add-new-tariff-input'}
            id={'paymentMethod'}
            name={'paymentMethod'}
            label={i18n.getMessage('addNewTariffForTariffGroup.modal.input.paymentMethod')}
            options={paymentMethodOptions}
            value={values.paymentMethod || ''}
            onChange={(name, value) => setFieldValue(name, value)}
            isDisabled={isLoading}
            error={errors?.paymentMethod}
          />

          <InputDropDownSearch
            className={'scheme-add-new-tariff-input-dropdown-search'}
            id={'currency'}
            name={'currency'}
            label={i18n.getMessage('addNewTariffForTariffGroup.modal.input.currency')}
            options={currencyOptions}
            value={values.currency || ''}
            onChange={(name, value) => setFieldValue(name, value)}
            isDisabled={isLoading}
            error={errors?.currency}
          />

          {isCryptoCurrency(values.currency, allCryptoCurrencies) && (
            <InputDropDown
              className={'scheme-add-new-tariff-input'}
              id={'network'}
              name={'network'}
              label={i18n.getMessage('addNewTariffForTariffGroup.modal.input.network')}
              options={networkOptions}
              value={values.network || ''}
              onChange={(name, value) => setFieldValue(name, value)}
              isDisabled={isLoading}
              error={errors?.network}
            />
          )}

          <InputDropDown
            className={'scheme-add-new-tariff-input'}
            id={'feeType'}
            name={'feeType'}
            label={i18n.getMessage('addNewTariffForTariffGroup.modal.input.feeType')}
            options={feeTypeOptions}
            value={values.feeType || ''}
            onChange={(name, value) => setFieldValue(name, value)}
            isDisabled={isLoading}
            error={errors?.feeType}
          />

          <div className={'scheme-add-new-tariff-form-checkbox-wrapper'}>
            <span>{i18n.getMessage('addNewTariffForTariffGroup.modal.input.status')}</span>
            <CheckBox
              className={'scheme-add-new-tariff-form-status-checkbox'}
              id={'enabled'}
              name={'enabled'}
              isActive={values.enabled}
              isReverseView={true}
              showCheckBox={true}
              onChange={() => setFieldValue('enabled', !values.enabled)}
              value={i18n.getMessage('addNewTariffForTariffGroup.modal.input.status.enabled')}
              disabled={isLoading}
            />
          </div>

          <div
            // eslint-disable-next-line max-len
            className={`scheme-add-new-tariff-form-amount-inputs-wrapper ${errors?.fixedValue ? 'error-inputs-wrapper' : ''}`}
          >
            {values.feeType === FEE_TYPE.FIXED && (
              <div className={'scheme-add-new-tariff-form-amount-inputs-and-currency-wrapper'}>
                <AmountInput
                  className={'scheme-add-new-tariff-form-amount-input'}
                  id={'fixedValue'}
                  name={'fixedValue'}
                  placeholder={i18n.getMessage('addNewTariffForTariffGroup.modal.input.placeholder')}
                  returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
                  value={values.fixedValue}
                  onChange={handleChange}
                  error={errors?.fixedValue}
                  precision={
                    isCryptoCurrency(values.currency, allCryptoCurrencies)
                      ? CRYPTO_CURRENCY_PRECISION
                      : COMMON_CURRENCY_PRECISION
                  }
                />
                <div className={'scheme-add-new-tariff-form-currency'}>
                  {values.currency || i18n.getMessage('addNewTariffForTariffGroup.modal.input.currency.placeholder')}
                </div>
              </div>
            )}

            {values.feeType === FEE_TYPE.PERCENT && (
              <>
                <div className={'scheme-add-new-tariff-form-amount-inputs-and-currency-wrapper'}>
                  <AmountInput
                    className={'scheme-add-new-tariff-form-amount-input'}
                    id={'percentValue'}
                    name={'percentValue'}
                    placeholder={i18n.getMessage('addNewTariffForTariffGroup.modal.input.placeholder')}
                    returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
                    value={values.percentValue}
                    onChange={handleChange}
                    error={errors?.percentValue}
                  />
                  <div className={'scheme-add-new-tariff-form-currency'}>
                    {i18n.getMessage('addNewTariffForTariffGroup.modal.input.percentValue')}
                  </div>
                </div>

                <div className={'scheme-add-new-tariff-form-amount-inputs-and-currency-wrapper'}>
                  <AmountInput
                    className={'scheme-add-new-tariff-form-amount-input'}
                    id={'min'}
                    name={'min'}
                    placeholder={i18n.getMessage('addNewTariffForTariffGroup.modal.input.placeholder')}
                    returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
                    value={values.min}
                    onChange={handleChange}
                    error={errors?.min}
                    precision={
                      isCryptoCurrency(values.currency, allCryptoCurrencies)
                        ? CRYPTO_CURRENCY_PRECISION
                        : COMMON_CURRENCY_PRECISION
                    }
                  />
                  <div className={'scheme-add-new-tariff-form-currency'}>
                    {i18n.getMessage('addNewTariffForTariffGroup.modal.input.min')}
                  </div>
                </div>

                <div className={'scheme-add-new-tariff-form-amount-inputs-and-currency-wrapper'}>
                  <AmountInput
                    className={'scheme-add-new-tariff-form-amount-input'}
                    id={'max'}
                    name={'max'}
                    placeholder={i18n.getMessage('addNewTariffForTariffGroup.modal.input.placeholder')}
                    returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
                    value={values.max}
                    onChange={handleChange}
                    error={errors?.max}
                    precision={
                      isCryptoCurrency(values.currency, allCryptoCurrencies)
                        ? CRYPTO_CURRENCY_PRECISION
                        : COMMON_CURRENCY_PRECISION
                    }
                  />
                  <div className={'scheme-add-new-tariff-form-currency'}>
                    {i18n.getMessage('addNewTariffForTariffGroup.modal.input.max')}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className={'scheme-add-new-tariff-actions-wrapper'}>
          <Button
            className={'scheme-add-new-tariff-button'}
            type={'outline'}
            onClick={handleClose}
            isDisabled={isLoading}
          >
            {i18n.getMessage('addNewTariffForTariffGroup.modal.button.cancel')}
          </Button>
          <Button
            className={'scheme-add-new-tariff-button'}
            roleType={'submit'}
            type={'primary'}
            isDisabled={isLoading}
          >
            {i18n.getMessage('addNewTariffForTariffGroup.modal.button.add')}
          </Button>
        </div>
      </form>
    </div>
  );
};

PopUpAddNewTariffToTariffGroupScheme.propTypes = {
  isLoading: PropTypes.bool,
  isTariffGroupCreation: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAddNewTariffToTariffGroup: PropTypes.func,
  tariffFormData: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  handleGetTariffFormData: PropTypes.func.isRequired
};

export default PopUpAddNewTariffToTariffGroupScheme;
