import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import CardAuthorisationsFiltersBar from './components/CardAuthorisationsFiltersBar';
import CardAuthorisationsTableHead from './components/CardAuthorisationsTableHead';
import CardAuthorisationsTableRow from './components/CardAuthorisationsTableRow';
import i18nContext from 'components/i18n-context';
import { DIRECTION, MENU_DIRECTION } from 'components/constants';
import EmptyTableContentPlug from 'components/EmptyTableContentPlug';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import { Container } from 'uikit/Container/Container';
import './CardAuthorisationsTable.scss';

const CardAuthorisationsTable = ({ cardsStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();

  const updateFiltersInUrl = (filters, pagination, sortColumn) => {
    const filtersParams = cardsStore.prepareFiltersParams();
    const queryParams = {
      ...filtersParams,
      page: pagination.page,
      size: pagination.size,
      sort_column: sortColumn.sort_column,
      sort_direction: sortColumn.sort_direction
    };
    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    cardsStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!cardsStore.isInitialized) {
      cardsStore.getCardAuthorizationsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardsStore.isInitialized]);

  useEffect(() => {
    if (cardsStore.isInitialized) {
      cardsStore.resetCardsStore();
      loadFiltersFromUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(cardsStore.filters, cardsStore.pagination, cardsStore.sortColumn);
    if (cardsStore.isInitialized) {
      cardsStore.getCardAuthorizationsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cardsStore.filters.search_text,
    cardsStore.filters.from_date,
    cardsStore.filters.to_date,
    cardsStore.filters.statuses,
    cardsStore.pagination.size,
    cardsStore.pagination.page,
    cardsStore.sortColumn.sort_column,
    cardsStore.sortColumn.sort_direction
  ]);

  const handleOnSortTable = (newSortBy) => {
    cardsStore.setSortData({
      sortBy: newSortBy,
      direction:
        cardsStore.sortColumn.sort_column !== newSortBy
          ? DIRECTION.ASC
          : cardsStore.sortColumn.sort_direction === DIRECTION.ASC
            ? DIRECTION.DESC
            : DIRECTION.ASC
    });
  };

  if (!cardsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container className={'card-authorizations-container'} header={i18n.getMessage('container.cardAuthorizations')}>
      <CardAuthorisationsFiltersBar
        filters={cardsStore.filters}
        totalElements={cardsStore.totalElements}
        handleSetFilters={cardsStore.setFilter}
      />

      {cardsStore.cardAuthorizationsList?.length === 0 ? (
        <EmptyTableContentPlug
          headerText={i18n.getMessage('cardAuthorizations.table.emptyPlug.header')}
          descriptionText={i18n.getMessage('cardAuthorizations.table.emptyPlug.description')}
        />
      ) : (
        <>
          <Pagination
            isShowPaginationSize={true}
            paginationSize={cardsStore.pagination.size}
            handleChangePaginationSize={cardsStore.setPageSize}
            isLoading={cardsStore.isLoading}
            currentPage={cardsStore.pagination.page}
            numberOfPages={cardsStore.pagination.totalPages}
            setCurrentPage={cardsStore.setPageNumber}
          />

          <Table responsive>
            <thead>
              <CardAuthorisationsTableHead
                handleOnSortTable={handleOnSortTable}
                sortBy={cardsStore.sortColumn.sort_column}
                direction={cardsStore.sortColumn.sort_direction}
              />
            </thead>
            <tbody>
              {cardsStore.cardAuthorizationsList?.map((cardAuthorization, index) => (
                <CardAuthorisationsTableRow
                  key={index}
                  type={'list'}
                  isLoading={cardsStore.isLoading}
                  cardAuthorizationData={cardAuthorization}
                  releaseCardAuthorization={cardsStore.releaseCardAuthorization}
                />
              ))}
            </tbody>
          </Table>

          <Pagination
            isShowPaginationSize={true}
            paginationSize={cardsStore.pagination.size}
            handleChangePaginationSize={cardsStore.setPageSize}
            isLoading={cardsStore.isLoading}
            currentPage={cardsStore.pagination.page}
            numberOfPages={cardsStore.pagination.totalPages}
            setCurrentPage={cardsStore.setPageNumber}
            menuDirection={MENU_DIRECTION.UP}
          />
        </>
      )}
    </Container>
  );
};

CardAuthorisationsTable.propTypes = {
  cardsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  cardsStore: stores.cardsStore
}))(observer(CardAuthorisationsTable));
