import { toast } from 'react-toastify';
import i18n from 'i18n';

export const showErrorNotification = (statusCode, error) => {
  const errorMessage = `${statusCode} ${error}` || 'An unknown error occurred';

  toast.error(errorMessage);
};

export const processDetailsErrorResponse = (fields) => {
  if (!fields || Object.keys(fields)?.length === 0) return '';

  const firstField = Object.keys(fields)[0];

  const errorCode = fields[firstField];

  if (errorCode === 'NotBlank' || errorCode === 'NotEmpty') {
    return getEmptyFieldMessage({ field: firstField, code: errorCode });
  } else if (errorCode === 'WrongValue') {
    return getWrongValueFieldMessage({ field: firstField, code: errorCode });
  } else if (errorCode === 'Size') {
    return getWrongSizeFieldMessage({ field: firstField, code: errorCode });
  } else if (errorCode === 'NotNull') {
    return getNotNullMessage({ field: firstField, code: errorCode });
  } else if (errorCode === 'Phone') {
    return getPhoneMessage({ field: firstField, code: errorCode });
  } else if (errorCode === 'Email') {
    return getEmailMessage({ field: firstField, code: errorCode });
  } else if (errorCode === 'Unique') {
    return getUniqueMessage({ field: firstField, code: errorCode });
  } else if (errorCode === 'Pattern') {
    return getPatternMessage({ field: firstField, code: errorCode });
  } else if (errorCode === 'Text') {
    return getNameMessage();
  } else {
    return errorCode;
  }
};

const getNameMessage = (field) => {
  return i18n.getMessage('customer.tab.basic.text');
};

const getPatternMessage = (field) => {
  let fieldName = field.field;
  if (field.field === 'login') {
    fieldName = i18n.getMessage('customer.tab.basic.login.pattern');
  }

  return fieldName;
};

const getPhoneMessage = (field) => {
  let fieldName = field.field;
  if (field.field === 'phone' || field.field === 'contactPhone') {
    fieldName = i18n.getMessage('customer.tab.basic.phone.format');
  }

  return fieldName;
};

const getUniqueMessage = (field) => {
  let fieldName = field.field;
  if (field.field === 'phone') {
    fieldName = i18n.getMessage('customer.tab.basic.phone.assigned');
  }

  return fieldName;
};

const getEmailMessage = (field) => {
  let fieldName = field.field;
  if (field.field === 'email' || field.field === 'contactEmail') {
    fieldName = i18n.getMessage('customer.tab.basic.email.format');
  }

  return fieldName;
};

const getNotNullMessage = (field) => {
  let fieldName = field.field;
  if (field.field === 'entityType') {
    fieldName = i18n.getMessage('customer.tab.details.entityType');
  }

  return i18n.getMessage('customer.tab.details.error.empty', { fieldName });
};

const getEmptyFieldMessage = (field) => {
  let fieldName = field.field;
  if (field.field === 'companyName') {
    fieldName = i18n.getMessage('customer.tab.details.companyName');
  } else if (field.field === 'registrationNumberOfficial') {
    fieldName = i18n.getMessage('customer.tab.details.registrationNumber');
  } else if (field.field === 'entityType') {
    fieldName = i18n.getMessage('customer.tab.details.entityType');
  } else if (field.field === 'description') {
    fieldName = i18n.getMessage('customer.tab.details.description');
  } else if (field.field === 'legalAddress.address') {
    fieldName = i18n.getMessage('customer.tab.details.legal.address');
  } else if (field.field === 'legalAddress.zipCode') {
    fieldName = i18n.getMessage('customer.tab.details.legal.zipCode');
  } else if (field.field === 'legalAddress.city') {
    fieldName = i18n.getMessage('customer.tab.details.legal.city');
  } else if (field.field === 'businessAddress.address') {
    fieldName = i18n.getMessage('customer.tab.details.business.address');
  } else if (field.field === 'businessAddress.zipCode') {
    fieldName = i18n.getMessage('customer.tab.details.business.zipCode');
  } else if (field.field === 'businessAddress.city') {
    fieldName = i18n.getMessage('customer.tab.details.business.city');
  } else if (field.field === 'authorizedAgent.name') {
    fieldName = i18n.getMessage('customer.tab.details.person.name');
  } else if (field.field === 'authorizedAgent.surname') {
    fieldName = i18n.getMessage('customer.tab.details.person.surname');
  } else if (/.*address.address$/.test(field.field)) {
    fieldName = i18n.getMessage('customer.tab.details.person.address');
  } else if (/.*address.zipCode$/.test(field.field)) {
    fieldName = i18n.getMessage('customer.tab.details.person.zipCode');
  } else if (/.*address.city$/.test(field.field)) {
    fieldName = i18n.getMessage('customer.tab.details.person.addressCity');
  } else if (field.field === 'email') {
    fieldName = i18n.getMessage('customer.tab.basic.email');
  }

  return i18n.getMessage('customer.tab.details.error.empty', { fieldName });
};

const getWrongValueFieldMessage = (field) => {
  let fieldName = field.field;
  if (field.field === 'registration_date_official') {
    fieldName = i18n.getMessage('customer.tab.details.registrationDate');
  } else if (/.*birthday$/.test(field.field)) {
    fieldName = i18n.getMessage('customer.tab.details.person.birthday');
  } else if (/.*passport_issue_date$/.test(field.field)) {
    fieldName = i18n.getMessage('customer.tab.details.person.issuingDate');
  } else if (/.*passport_expiry_date$/.test(field.field)) {
    fieldName = i18n.getMessage('customer.tab.details.person.expiryDate');
  }

  // field is date
  if (fieldName !== field.field) {
    return i18n.getMessage('customer.tab.details.error.date', { fieldName });
  }

  if (field.field === 'entity_type') {
    fieldName = i18n.getMessage('customer.tab.details.entityType');
  }

  return i18n.getMessage('customer.tab.details.error.empty', { fieldName });
};

const getWrongSizeFieldMessage = (field) => {
  let fieldName = field.field;
  let maxLength = '';
  if (field.field === 'companyName') {
    fieldName = i18n.getMessage('customer.tab.details.companyName');
    maxLength = 50;
  } else if (field.field === 'registrationNumberOfficial') {
    fieldName = i18n.getMessage('customer.tab.details.registrationNumber');
    maxLength = 20;
  } else if (field.field === 'webSite') {
    fieldName = i18n.getMessage('customer.tab.details.webSite');
    maxLength = 50;
  } else if (field.field === 'commercialProfile') {
    fieldName = i18n.getMessage('customer.tab.details.commercialProfile');
    maxLength = 50;
  } else if (field.field === 'description') {
    fieldName = i18n.getMessage('customer.tab.details.description');
    maxLength = 1024;
  } else if (field.field === 'legalAddress.address') {
    fieldName = i18n.getMessage('customer.tab.details.legal.address');
    maxLength = 50;
  } else if (field.field === 'legalAddress.zipCode') {
    fieldName = i18n.getMessage('customer.tab.details.legal.zipCode');
    maxLength = 10;
  } else if (field.field === 'legalAddress.city') {
    fieldName = i18n.getMessage('customer.tab.details.legal.city');
    maxLength = 50;
  } else if (field.field === 'businessAddress.address') {
    fieldName = i18n.getMessage('customer.tab.details.business.address');
    maxLength = 50;
  } else if (field.field === 'businessAddress.zipCode') {
    fieldName = i18n.getMessage('customer.tab.details.business.zipCode');
    maxLength = 10;
  } else if (field.field === 'businessAddress.city') {
    fieldName = i18n.getMessage('customer.tab.details.business.city');
    maxLength = 50;
  } else if (/.*surname$/.test(field.field)) {
    fieldName = i18n.getMessage('customer.tab.details.person.surname');
    maxLength = 50;
  } else if (/.*name$/.test(field.field)) {
    fieldName = i18n.getMessage('customer.tab.details.person.name');
    maxLength = 50;
  } else if (/.*passportNumber$/.test(field.field)) {
    fieldName = i18n.getMessage('customer.tab.details.person.passportNumber');
    maxLength = 30;
  } else if (/.*address.address$/.test(field.field)) {
    fieldName = i18n.getMessage('customer.tab.details.person.address');
    maxLength = 50;
  } else if (/.*address.zipCode$/.test(field.field)) {
    fieldName = i18n.getMessage('customer.tab.details.person.zipCode');
    maxLength = 10;
  } else if (/.*address.city$/.test(field.field)) {
    fieldName = i18n.getMessage('customer.tab.details.person.addressCity');
    maxLength = 50;
  } else if ('legalAddress.country' === field.field) {
    return i18n.getMessage('customer.tab.details.business.legalAddress.country');
  } else if ('details.address.country' === field.field) {
    return i18n.getMessage('customer.tab.details.person.address.country');
  } else if ('businessAddress.country' === field.field) {
    return i18n.getMessage('customer.tab.details.business.businessAddress.country');
  } else if (field.field === 'declaredAccountTurnover') {
    fieldName = i18n.getMessage('customer.tab.details.declaredAccountTurnover');
    maxLength = 200;
  } else if (field.field === 'additionalComments') {
    fieldName = i18n.getMessage('customer.tab.details.additionalComments');
    maxLength = 200;
  } else if (field.field === 'partners') {
    fieldName = i18n.getMessage('customer.tab.details.partners');
    maxLength = 200;
  } else if (field.field === 'phone') {
    fieldName = i18n.getMessage('customer.tab.basic.phone');
    maxLength = 25;
  } else if (field.field === 'login') {
    fieldName = i18n.getMessage('customer.tab.basic.login');
    maxLength = 50;
  }

  return i18n.getMessage('error.tooLong', { fieldName, maxLength });
};
