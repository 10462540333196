import { useContext } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { MarkupGroupDetailsTableHeadRow } from './components/MarkupGroupDetailsTableHeadRow';
import { MarkupGroupDetailsTableRowContent } from './components/MarkupGroupDetailsTableRowContent';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import Button from 'uikit/Button/Button';
import './MarkupGroupDetailsTable.scss';

const MarkupGroupDetailsTable = ({
  isLoading,
  isUpdateMarkupGroup,
  markupGroupDetails,
  setMarkupGroupDetails,
  handleUpdateMarkupGroup,
  handleClickBackButton
}) => {
  const i18n = useContext(i18nContext);

  const handleChangeMarkupValue = (markupIndex, markupValue) => {
    setMarkupGroupDetails({
      ...markupGroupDetails,
      markups: markupGroupDetails.markups.map((markup, index) => {
        if (index === markupIndex) {
          return {
            ...markup,
            markup: markupValue
          };
        } else {
          return markup;
        }
      })
    });
  };

  const handleClickSaveChangesButton = async () => {
    await handleUpdateMarkupGroup(markupGroupDetails.markups);
  };

  if (!markupGroupDetails) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <div className={'markup-group-details-table-wrapper'}>
      <Table responsive>
        <thead>
          <MarkupGroupDetailsTableHeadRow />
        </thead>
        <tbody>
          {markupGroupDetails?.markups?.map((markupData, index) => (
            <MarkupGroupDetailsTableRowContent
              key={index}
              type={'list'}
              isLoading={isLoading}
              markupData={markupData}
              markupIndex={index}
              handleChangeMarkupValue={handleChangeMarkupValue}
            />
          ))}
        </tbody>
      </Table>

      {isUpdateMarkupGroup && (
        <div className={'markup-group-details-table-actions-wrapper'}>
          <Button
            className={'markup-group-details-table-button'}
            size={'medium'}
            type={'outline'}
            onClick={handleClickBackButton}
            isDisabled={isLoading}
          >
            {i18n.getMessage('markupGroupDetails.button.back')}
          </Button>
          <Button
            className={'markup-group-details-table-button'}
            roleType={'button'}
            size={'medium'}
            type={'primary'}
            onClick={handleClickSaveChangesButton}
            isDisabled={isLoading}
          >
            {i18n.getMessage('markupGroupDetails.button.saveChanges')}
          </Button>
        </div>
      )}
    </div>
  );
};

MarkupGroupDetailsTable.propTypes = {
  isLoading: PropTypes.bool,
  isUpdateMarkupGroup: PropTypes.bool,
  handleUpdateMarkupGroup: PropTypes.func,
  handleClickBackButton: PropTypes.func,
  setMarkupGroupDetails: PropTypes.func,
  markupGroupDetails: PropTypes.oneOfType([
    PropTypes.shape({
      markupGroupId: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      customersCount: PropTypes.number,
      markups: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          markup: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          createdAt: PropTypes.string,
          updatedAt: PropTypes.string
        })
      )
    }),
    PropTypes.shape({
      markups: PropTypes.arrayOf(
        PropTypes.shape({
          from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          markup: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        })
      )
    }),
    PropTypes.oneOf([null])
  ])
};

export default MarkupGroupDetailsTable;
