import './index.css';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'mobx-react';

import accountsStore from 'stores/accountsStore';
import authStore from 'stores/authStore';
import cardsStore from 'stores/cardsStore';
import commissionTransactionsStore from 'stores/commissionTransactionsStore';
import customersStore from 'stores/customersStore';
import emailStatisticsStore from 'stores/emailStatisticsStore';
import limitsStore from 'stores/limitsStore';
import markupsStore from 'stores/markupsStore';
import messagesStore from 'stores/messagesStore';
import paymentObligationsStore from 'stores/paymentObligationsStore';
import settingsStore from 'stores/settingsStore';
import tariffsStore from 'stores/tariffsStore';
import transactionsStore from 'stores/transactionsStore';
import transitTransactionsStore from 'stores/transitTransactionsStore';
import usersStore from 'stores/usersStore';
import ApplicationProvider from 'contexts/ApplicationContext';

import App from './App';
import 'assets/fonts/Manrope-Regular.ttf';
import 'assets/fonts/Manrope-SemiBold.ttf';
import 'bootstrap/dist/css/bootstrap.css';
import 'styles.scss';

const stores = {
  accountsStore,
  authStore,
  cardsStore,
  commissionTransactionsStore,
  customersStore,
  emailStatisticsStore,
  limitsStore,
  markupsStore,
  messagesStore,
  paymentObligationsStore,
  settingsStore,
  tariffsStore,
  transactionsStore,
  transitTransactionsStore,
  usersStore
};

const root = createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <Provider {...stores}>
      <ApplicationProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApplicationProvider>
    </Provider>
  </StrictMode>
);
