import i18n from 'i18n';

export const TIMEZONE = 'Europe/London';
export const DEFAULT_COUNTRY_CODE = 'GB';
export const DEFAULT_DIAL_CODE = '+44';

export const DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const MENU_DIRECTION = {
  UP: 'UP',
  DOWN: 'DOWN'
};

export const AMOUNT_INPUT_VALUE_TYPE = {
  STRING: 'STRING',
  NUMBER: 'NUMBER'
};

export const CRYPTO_CURRENCY_PRECISION = 8;
export const COMMON_CURRENCY_PRECISION = 2;

export const PAGINATION_SIZES = [20, 100];

export const MAX_TOPIC_SUBJECT_LENGTH = 100;
export const MAX_MESSAGE_LENGTH = 3500;
export const MAX_TRANSACTION_NOTE_LENGTH = 1000;
export const MAX_TRANSACTION_COMMENT_LENGTH = 140;
export const MAX_COMMISSION_TRANSACTION_COMMENT_LENGTH = 255;
export const MAX_NEW_LIMIT_GROUP_NAME_LENGTH = 255;
export const MAX_NEW_TARIFF_GROUP_NAME_LENGTH = 255;
export const MAX_NEW_MARKUP_GROUP_NAME_LENGTH = 255;

export const SEARCH_BOUND_MINIMUM_LENGTH_CLIENT = 4;
export const SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT = 3;
export const SEARCH_BOUND_MINIMUM_LENGTH_SYSTEM_ACCOUNT = 1;
export const MESSAGE_CLIENTS_AMOUNT_RESULT = 15;

export const MESSAGE_REG_EXP = /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~\s’‘‚”“„€£]*$/;
export const PASSWORD_SPECIAL_SYMBOLS = '!"#$%&\'()*+,-.:;<=>?@[]^_`{/|\\}~';

export const POP_UP_TYPE = {
  CONFIRMATION: 'CONFIRMATION',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

export const REPRESENTATIVE_POP_UP = {
  PERMISSION: 'change_permission',
  DELETE: 'delete_representative',
  SUCCESS: 'successfully_linked',
  ADD: 'add_new_representative',
  ERROR_NOT_VERIFIED: 'user_not_verified',
  ERROR_USER_NOT_APPLICATION: 'user_not_have_application_account',
  ERROR_ALREADY_ADDED: 'user_already_added',
  ERROR_ADDING_YOURSELF_AS_REPRESENTATIVE: 'user_adding_himself_as_representative',
  ERROR_INVALID_EMAIL: 'invalid_email',
  ERROR: 'api_error'
};

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
  ADMINISTRATE: 'ADMINISTRATE'
};

export const RISK_PROFILE = {
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH'
};

export const PASS_OR_FAIL = {
  PASS: 'PASS',
  FAIL: 'FAIL'
};

export const YES_OR_NO = {
  YES: true,
  NO: false
};

export const CUSTOMER_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  CORPORATE: 'CORPORATE'
};

export const CUSTOMER_STATUSES = {
  NEW: 'NEW',
  VERIFICATION: 'VERIFICATION',
  VERIFIED: 'VERIFIED',
  VIRTUAL: 'VIRTUAL',
  HIDDEN: 'HIDDEN',
  CLOSED: 'CLOSED',
  DECLINED: 'DECLINED'
};

export const ONBOARDING_STATUS = {
  CUSTOMER_WITHOUT_SUMSUB: 'CUSTOMER_WITHOUT_SUMSUB',
  SUMSUB_START: 'SUMSUB_START',
  SUMSUB_START_QUESTIONNAIRE: 'SUMSUB_START_QUESTIONNAIRE',
  SUMSUB_START_IDENTITY_VERIFICATION: 'SUMSUB_START_IDENTITY_VERIFICATION',
  INCORRECT_POA_DOC: 'INCORRECT_POA_DOC',
  SUMSUB_COMPLETE: 'SUMSUB_COMPLETE',
  DOCUMENT_COLLECTION: 'DOCUMENT_COLLECTION',
  ADDITIONAL_REQUEST: 'ADDITIONAL_REQUEST',
  INTERNAL_DD: 'INTERNAL_DD',
  IBAN_SETUP: 'IBAN_SETUP',
  ACCOUNT_OPENED: 'ACCOUNT_OPENED',
  FEE_COLLECTION: 'FEE_COLLECTION',
  FIRST_DEPOSIT: 'FIRST_DEPOSIT',
  FIRST_PAYMENT: 'FIRST_PAYMENT',
  CLOSURE_OF_ACCOUNT: 'CLOSURE_OF_ACCOUNT'
};

export const APPROVAL_STATUS = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
};

export const LEGAL_ENTITY_TYPES = {
  LLC: 'LLC',
  LLP: 'LLP',
  LP: 'LP',
  SELF_EMPLOYED: 'SELF_EMPLOYED',
  JSC: 'JSC',
  FOUNDATION: 'FOUNDATION',
  SOCIETY: 'SOCIETY',
  OTHER: 'OTHER'
};

export const PERSON_TITLE = {
  MISS: 'MISS',
  MR: 'MR',
  MRS: 'MRS',
  MS: 'MS'
};

export const CUSTOM_TABS_DIRECTION = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal'
};

export const REGISTRATION_CUSTOMER_TYPE = {
  PERSONAL: 'PERSONAL',
  BUSINESS: 'BUSINESS'
};

export const REGISTRATION_STATUSES = {
  REGISTRATION_STATUS_EMAIL_CONFIRMATION: 'EMAIL_CONFIRMATION',
  REGISTRATION_STATUS_SECOND_FACTOR_CONFIRMATION_PHONE: 'PHONE_SETUP',
  REGISTRATION_STATUS_PHONE_CONFIRMATION: 'PHONE_CONFIRMATION',
  REGISTRATION_STATUS_CONFIRMED: 'CONFIRMED'
};

export const PASSWORD_RECOVERY_STATUSES = {
  PHONE_CONFIRM: 'PHONE_CONFIRM',
  EMAIL_CHECK: 'EMAIL_CHECK',
  EMAIL_CONFIRM: 'EMAIL_CONFIRM',
  PASSWORD_SETUP: 'PASSWORD_SETUP'
};

export const AUTHORIZATION_CONFIRMATION_TYPES = {
  PHONE: 'CODE',
  GOOGLE2FA: 'GOOGLE_2FA'
};

export const CONFIRMATION_HANDLE_TYPES = {
  ON_CHANGE: 'ON_CHANGE',
  ON_COMPLETE: 'ON_COMPLETE'
};

export const ICON_BUTTONS_TYPE = {
  NAVIGATION: 'NAVIGATION',
  MENU: 'MENU',
  OTHER: 'OTHER'
};

export const USER_CUSTOMER_STATUSES = {
  NEW: 'NEW',
  CREATED: 'CREATED',
  VERIFICATION: 'VERIFICATION',
  VERIFIED: 'VERIFIED',
  DECLINED: 'DECLINED',
  VIRTUAL: 'VIRTUAL'
};

export const EXTERNAL_KYC_LINK = 'https://cockpit.sumsub.com/checkus#/applicant/';

export const KYC_STATUSES = {
  NEW: 'NEW',
  UNDER_REVIEW: 'UNDER_REVIEW',
  VERIFIED: 'VERIFIED',
  DECLINED: 'DECLINED'
};

export const TRANSACTIONS_TABLE_STATUS = {
  created: 'CREATED',
  onHold: 'ON_HOLD',
  pending: 'PENDING',
  processing: 'PROCESSING',
  completed: 'PAID',
  rejected: 'REJECTED',
  failed: 'FAILED',
  acceptRequest: 'ACCEPT_REQUESTED',
  rejectRequest: 'REJECT_REQUESTED'
};

export const TRANSIT_TRANSACTION_DIRECTION = {
  IN: 'IN',
  OUT: 'OUT'
};

export const TRANSIT_TRANSACTION_STATUS = {
  CREATED: 'CREATED',
  PROCESSING: 'PROCESSING',
  PROCESSED: 'PROCESSED',
  FAILED: 'FAILED',
  PAYMENT_REJECTED: 'PAYMENT_REJECTED',
  RETURNED: 'RETURNED'
};

export const COMMISSION_TRANSACTION_TYPE = {
  RETURN: 'RETURN',
  WITHDRAW: 'WITHDRAW',
  COMMISSION: 'COMMISSION'
};

export const SYSTEM_ACCOUNT_TYPE = {
  TRANSIT: 'TRANSIT',
  COMMISSION: 'COMMISSION'
};

export const WORK_TIME_TYPE = {
  PAYMENT: 'PAYMENT',
  EXCHANGE: 'EXCHANGE'
};

export const ACCOUNT_STATUS = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED'
};

export const DOCUMENT_STATUS = {
  OK: 'OK',
  REQUESTED: 'REQUESTED',
  PROVIDED: 'PROVIDED'
};

export const ACCOUNT_PROPERTIES = {
  IBAN: 'iban',
  MULTI_IBAN: 'multi_iban',
  ACCOUNT_NUMBER: 'wallet_number',
  MULTI_ACCOUNT_NUMBER: 'multi_wallet_number',
  AVAILABLE: 'available',
  CURRENCY: 'currency',
  FROZEN: 'frozen',
  MINIMAL: 'minimal',
  ON_HOLD: 'on_hold',
  STATUS: 'status',
  TOTAL: 'total',
  TRANSFER_PROVIDER: 'transfer_provider',
  TYPE: 'type'
};

export const TRANSFER_TYPE = {
  NORMAL: 'NORMAL',
  COMMISSION: 'COMMISSION',
  RETURN: 'RETURN',
  INTERNET_PURCHASE: 'INTERNET_PURCHASE',
  PURCHASE: 'PURCHASE',
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW',
  EXCHANGE: 'EXCHANGE',
  OTHER: 'OTHER'
};

export const TRANSACTION_TYPES = {
  INCOMING: 'INCOMING',
  OUTGOING: 'OUTGOING'
};

export const TYPES_OF_TRANSACTION = {
  EXCHANGE: 'EXCHANGE',
  COMMISSION: 'COMMISSION',
  NORMAL: 'NORMAL'
};

export const RECIPIENT_TYPES = {
  INDIVIDUAL: 'Individual',
  COMPANY: 'Company'
};

export const TRANSACTION_PARTY_TYPE = {
  COMPANY: 'COMPANY',
  INDIVIDUAL: 'INDIVIDUAL'
};

export const TRANSACTION_REQUISITES_TAB = {
  IBAN: 'iban',
  LOCAL_BANK_ACCOUNT: 'localBankAccount',
  NON_IBAN_BANK_COUNTRIES: 'bankAccount'
};

export const PAYMENT_OBLIGATION_STATUS = {
  OPEN: 'OPEN',
  PAID: 'PAID',
  RELEASED: 'RELEASED'
};

export const ACCOUNT_TOOLTIP_TEXT = [
  i18n.getMessage('tooltip.Transactions'),
  i18n.getMessage('tooltip.newPayment'),
  i18n.getMessage('tooltip.accountDetails')
];

export const WALLESTER_ACCOUNT_TOOLTIP_TEXT = [
  i18n.getMessage('tooltip.Transactions'),
  i18n.getMessage('tooltip.cards'),
  i18n.getMessage('tooltip.accountDetails')
];

export const SESSION_STORAGE_CONSTANTS = {
  SESSION_ENDED: 'session ended'
};

export const SESSION_STORAGE_OPTIONS = {
  PASSWORD_RECOVERY: 'passwordRecovery',
  SESSION_ENDED: 'sessionEnded'
};

export const SMS_ERROR_CODE_DISABLE = [
  'CONFIRMATION_CODE_TRIES_EXCEED',
  'CONFIRMATION_CODE_IS_OUT_OF_DATE',
  'CODE_SEND_TIMEOUT'
];

export const ERROR_CODES = {
  ARGUMENT_NOT_VALID: 'ARGUMENT_NOT_VALID',
  USER_ALREADY_REGISTERED: 'USER_ALREADY_REGISTERED',
  EMAIL_ALREADY_IN_USE: 'EMAIL_ALREADY_IN_USE',
  ARGUMENT_TYPE_MISMATCH: 'ARGUMENT_TYPE_MISMATCH'
};

export const CURRENCY_SYMBOLS = {
  EUR: '€',
  GBP: '£',
  USD: '$'
};

export const EXCHANGE_AMOUNT_DIRECTION = {
  AMOUNT_FROM: 'AMOUNT_FROM',
  AMOUNT_TO: 'AMOUNT_TO'
};

export const CUSTOMER_PERMISSIONS = {
  READ_ACCOUNT: 'READ_ACCOUNT',
  READ_PROFILE: 'READ_PROFILE',
  CREATE_TRANSACTION: 'CREATE_TRANSACTION',
  CREATE_INTERNAL_TRANSACTION: 'CREATE_INTERNAL_TRANSACTION',
  CREATE_CURRENCY_EXCHANGE: 'CREATE_CURRENCY_EXCHANGE',
  CREATE_REPORT: 'CREATE_REPORT',
  READ_FEES: 'READ_FEES',
  READ_CARDS: 'READ_CARDS',
  CREATE_CARDS: 'CREATE_CARDS',
  READ_MESSAGES: 'READ_MESSAGES',
  CREATE_MESSAGE: 'CREATE_MESSAGE'
};

export const PAYMENT_METHODS = [
  'SEPA',
  'SEPA_INSTANT',
  'TARGET2',
  'SWIFT',
  'FASTER_PAYMENTS',
  'INTERNAL',
  'INTERNAL_PROVIDER',
  'CHAPS',
  'BACS'
];

export const PAYMENT_METHOD = {
  INTERNAL: 'INTERNAL',
  INTERNAL_PROVIDER: 'INTERNAL_PROVIDER',
  SEPA: 'SEPA',
  SEPA_INSTANT: 'SEPA_INSTANT',
  FASTER_PAYMENTS: 'FASTER_PAYMENTS',
  TARGET2: 'TARGET2',
  SWIFT: 'SWIFT',
  CHAPS: 'CHAPS',
  BACS: 'BACS',
  CARD: 'CARD',
  CRYPTO: 'CRYPTO'
};

export const MANUAL_PROVIDER_PAYMENT_METHODS_SORTING_EXAMPLE = {
  EUR: [PAYMENT_METHOD.SEPA, PAYMENT_METHOD.SEPA_INSTANT, PAYMENT_METHOD.SWIFT],
  GBP: [PAYMENT_METHOD.FASTER_PAYMENTS, PAYMENT_METHOD.SWIFT],
  OTHER: [PAYMENT_METHOD.SWIFT]
};

export const BEFORE_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE = {
  EUR: [
    PAYMENT_METHOD.SEPA,
    PAYMENT_METHOD.SEPA_INSTANT,
    PAYMENT_METHOD.TARGET2,
    PAYMENT_METHOD.SWIFT,
    PAYMENT_METHOD.INTERNAL,
    PAYMENT_METHOD.INTERNAL_PROVIDER
  ],
  GBP: [PAYMENT_METHOD.FASTER_PAYMENTS, PAYMENT_METHOD.INTERNAL, PAYMENT_METHOD.INTERNAL_PROVIDER],
  OTHER: [PAYMENT_METHOD.SWIFT, PAYMENT_METHOD.INTERNAL]
};

export const AFTER_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE = {
  EUR: [
    PAYMENT_METHOD.INTERNAL,
    PAYMENT_METHOD.INTERNAL_PROVIDER,
    PAYMENT_METHOD.SEPA_INSTANT,
    PAYMENT_METHOD.SEPA,
    PAYMENT_METHOD.TARGET2,
    PAYMENT_METHOD.SWIFT
  ],
  GBP: [
    PAYMENT_METHOD.INTERNAL,
    PAYMENT_METHOD.INTERNAL_PROVIDER,
    PAYMENT_METHOD.FASTER_PAYMENTS,
    PAYMENT_METHOD.BACS,
    PAYMENT_METHOD.CHAPS,
    PAYMENT_METHOD.SWIFT
  ],
  OTHER: [PAYMENT_METHOD.INTERNAL, PAYMENT_METHOD.SWIFT]
};

export const PAYMENT_PROVIDERS = {
  MANUAL: 'MANUAL',
  CLEAR_JUNCTION: 'CLEAR_JUNCTION',
  RYVYL: 'RYVYL',
  LPB: 'LPB',
  OPEN_PAYD: 'OPEN_PAYD',
  WALLESTER: 'WALLESTER',
  GRAPHEREX: 'GRAPHEREX',
  GURU_PAY: 'GURU_PAY'
};

export const RULE_ENGINE_PROVIDER = {
  FINCHECKER: 'FINCHECKER'
};

export const RULE_ENGINE_PROVIDERS = ['FINCHECKER'];

export const TARIFF_TYPE = {
  FIXED: 'FIXED',
  PERCENT: 'PERCENT',
  SCHEDULED: 'SCHEDULED'
};

export const FEE_TYPE = {
  FIXED: 'FIXED',
  PERCENT: 'PERCENT'
};

export const PROVIDER_STATUS = {
  LINKING: 'LINKING',
  LINKED: 'LINKED',
  FAILED: 'FAILED'
};

export const CURRENCY_TYPES = {
  GBP: 'GBP',
  EUR: 'EUR',
  USD: 'USD'
};

export const ON_HOLD_STATUS = {
  DEFAULT: 'DEFAULT',
  DOCUMENTS_REQUESTED: 'DOCUMENTS_REQUESTED',
  DOCUMENTS_REVIEW: 'DOCUMENTS_REVIEW'
};

export const LIMIT_TYPES = {
  AMOUNT: 'AMOUNT',
  COUNT: 'COUNT'
};

export const TARIFF_TYPES = {
  FIXED: 'FIXED',
  PERCENT: 'PERCENT',
  SCHEDULED: 'SCHEDULED'
};

export const LIMIT_PERIODS = {
  PER_TRANSACTION: 'PER_TRANSACTION',
  DAY: 'DAY',
  MONTH: 'MONTH',
  YEAR: 'YEAR'
};

export const LIMIT_GROUP_TYPES = {
  SHARED: 'SHARED',
  PRIVATE: 'PRIVATE'
};

export const BLACKLIST_STATUS = {
  ACTIVATED: true,
  DEACTIVATED: false,
  ALL: 'all'
};

export const CURRENCY = {
  AED: 'AED',
  ARS: 'ARS',
  AUD: 'AUD',
  BBD: 'BBD',
  BDT: 'BDT',
  BGN: 'BGN',
  BHD: 'BHD',
  BRL: 'BRL',
  BWP: 'BWP',
  CAD: 'CAD',
  CHF: 'CHF',
  CLP: 'CLP',
  CNY: 'CNY',
  COP: 'COP',
  CZK: 'CZK',
  DKK: 'DKK',
  EUR: 'EUR',
  FJD: 'FJD',
  GBP: 'GBP',
  GHS: 'GHS',
  HKD: 'HKD',
  HRK: 'HRK',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  INR: 'INR',
  JMD: 'JMD',
  JOD: 'JOD',
  JPY: 'JPY',
  KES: 'KES',
  KRW: 'KRW',
  KWD: 'KWD',
  LKR: 'LKR',
  LSL: 'LSL',
  MAD: 'MAD',
  MUR: 'MUR',
  MWK: 'MWK',
  MXN: 'MXN',
  MYR: 'MYR',
  MZN: 'MZN',
  NAD: 'NAD',
  NOK: 'NOK',
  NPR: 'NPR',
  NZD: 'NZD',
  OMR: 'OMR',
  PEN: 'PEN',
  PGK: 'PGK',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  QAR: 'QAR',
  RON: 'RON',
  RUB: 'RUB',
  SAR: 'SAR',
  SEK: 'SEK',
  SGD: 'SGD',
  THB: 'THB',
  TND: 'TND',
  TRY: 'TRY',
  TTD: 'TTD',
  TWD: 'TWD',
  TZS: 'TZS',
  UGX: 'UGX',
  USD: 'USD',
  UYU: 'UYU',
  VND: 'VND',
  XAF: 'XAF',
  XCD: 'XCD',
  XOF: 'XOF',
  ZAR: 'ZAR',
  ZMW: 'ZMW'
};

export const GRAPHEREX_CURRENCY_MAPPINGS = {
  'ETH-USDT': { currency: 'USDT', network: 'Ethereum' },
  'TRX-USDT': { currency: 'USDT', network: 'Tron' },
  'TRX-TRX': { currency: 'TRX', network: 'Tron' },
  'SOL-USDT': { currency: 'USDT', network: 'Solana' },
  'XLM-USDT': { currency: 'USDT', network: 'Stellar' },
  'TRX-TRX_TEST': { currency: 'TRX_TEST', network: 'Tron' }
};

export const TRANSACTION_STATUS = {
  CREATED: 'CREATED',
  ON_HOLD: 'ON_HOLD',
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  ACCEPT_REQUESTED: 'ACCEPT_REQUESTED',
  REJECT_REQUESTED: 'REJECT_REQUESTED',
  PAID: 'PAID',
  FAILED: 'FAILED',
  REJECTED: 'REJECTED'
};

export const PAYMENT_PROVIDER = {
  MANUAL: {
    name: 'MANUAL',
    currencies: Object.values(CURRENCY),
    paymentMethods: {
      [PAYMENT_METHOD.INTERNAL]: Object.keys(CURRENCY),
      [PAYMENT_METHOD.SEPA]: [CURRENCY.EUR],
      [PAYMENT_METHOD.SEPA_INSTANT]: [CURRENCY.EUR],
      [PAYMENT_METHOD.FASTER_PAYMENTS]: [CURRENCY.GBP],
      [PAYMENT_METHOD.TARGET2]: [CURRENCY.EUR],
      [PAYMENT_METHOD.SWIFT]: Object.keys(CURRENCY),
      [PAYMENT_METHOD.BACS]: [CURRENCY.GBP],
      [PAYMENT_METHOD.CHAPS]: [CURRENCY.GBP]
    }
  },
  CLEAR_JUNCTION: {
    name: 'CLEAR_JUNCTION',
    currencies: [CURRENCY.EUR, CURRENCY.GBP],
    paymentMethods: {
      [PAYMENT_METHOD.INTERNAL]: [CURRENCY.EUR, CURRENCY.GBP],
      [PAYMENT_METHOD.INTERNAL_PROVIDER]: [CURRENCY.EUR, CURRENCY.GBP],
      [PAYMENT_METHOD.SEPA]: [CURRENCY.EUR],
      [PAYMENT_METHOD.SEPA_INSTANT]: [CURRENCY.EUR],
      [PAYMENT_METHOD.FASTER_PAYMENTS]: [CURRENCY.GBP],
      [PAYMENT_METHOD.BACS]: [CURRENCY.GBP],
      [PAYMENT_METHOD.CHAPS]: [CURRENCY.GBP]
    }
  },
  OPEN_PAYD: {
    name: 'OPEN_PAYD',
    currencies: [
      CURRENCY.EUR,
      CURRENCY.GBP,
      CURRENCY.USD,
      CURRENCY.CAD,
      CURRENCY.CHF,
      CURRENCY.DKK,
      CURRENCY.HUF,
      CURRENCY.NOK,
      CURRENCY.PLN,
      CURRENCY.SEK
    ],
    paymentMethods: {
      [PAYMENT_METHOD.INTERNAL]: [
        CURRENCY.EUR,
        CURRENCY.GBP,
        CURRENCY.USD,
        CURRENCY.CAD,
        CURRENCY.CHF,
        CURRENCY.DKK,
        CURRENCY.HUF,
        CURRENCY.NOK,
        CURRENCY.PLN,
        CURRENCY.SEK
      ],
      [PAYMENT_METHOD.SEPA]: [CURRENCY.EUR],
      [PAYMENT_METHOD.SEPA_INSTANT]: [CURRENCY.EUR],
      [PAYMENT_METHOD.FASTER_PAYMENTS]: [CURRENCY.GBP],
      [PAYMENT_METHOD.TARGET2]: [CURRENCY.EUR],
      [PAYMENT_METHOD.SWIFT]: [
        CURRENCY.GBP,
        CURRENCY.USD,
        CURRENCY.CAD,
        CURRENCY.CHF,
        CURRENCY.DKK,
        CURRENCY.HUF,
        CURRENCY.NOK,
        CURRENCY.PLN,
        CURRENCY.SEK
      ],
      [PAYMENT_METHOD.BACS]: [CURRENCY.GBP],
      [PAYMENT_METHOD.CHAPS]: [CURRENCY.GBP]
    }
  },
  LPB: {
    name: 'LPB',
    currencies: [CURRENCY.EUR],
    paymentMethods: {
      [PAYMENT_METHOD.INTERNAL]: [CURRENCY.EUR],
      [PAYMENT_METHOD.SEPA]: [CURRENCY.EUR],
      [PAYMENT_METHOD.SEPA_INSTANT]: [CURRENCY.EUR],
      [PAYMENT_METHOD.TARGET2]: [CURRENCY.EUR]
    }
  },
  WALLESTER: {
    name: 'WALLESTER',
    currencies: [CURRENCY.EUR],
    paymentMethods: {
      [PAYMENT_METHOD.INTERNAL]: [CURRENCY.EUR],
      [PAYMENT_METHOD.CARD]: [CURRENCY.EUR]
    }
  },
  RYVYL: {
    name: 'RYVYL',
    currencies: [CURRENCY.EUR],
    paymentMethods: {
      [PAYMENT_METHOD.INTERNAL]: [CURRENCY.EUR],
      [PAYMENT_METHOD.SEPA]: [CURRENCY.EUR]
    }
  },
  GRAPHEREX: {
    name: 'GRAPHEREX',
    currencies: ['USDT', 'TRX', 'TRX_TEST'],
    paymentMethods: {
      [PAYMENT_METHOD.CRYPTO]: []
    }
  },
  GURU_PAY: {
    name: 'GURU_PAY',
    currencies: [CURRENCY.EUR],
    paymentMethods: {
      [PAYMENT_METHOD.INTERNAL]: [CURRENCY.EUR],
      [PAYMENT_METHOD.SEPA]: [CURRENCY.EUR]
    }
  }
};

export const MANUAL_BANK_DETAILS = {
  SEPA_IBAN: 'GB22BARC20000046858244',
  SWIFT_IBAN: 'GB05IFXS23229006273629'
};

export const FORM_TYPES = {
  SIMPLE: 'simple',
  ADVANCED: 'advanced'
};

export const KYC_PROVIDERS = {
  SUMSUB: 'SUMSUB',
  SHUFTI: 'SHUFTI'
};

export const VERIFICATION_TYPES = {
  SIMPLE: 'kyb-single-ownership',
  STANDARD: 'KYB',
  BASIC: 'basic-kyc-level'
};

export const MESSAGE_SENDER_TYPES = {
  USER: 'USER',
  OPERATOR: 'OPERATOR'
};

export const MESSAGE_TOPIC_PERMISSIONS = {
  ANNOUNCEMENT: 'READ',
  DEFAULT: 'READ_WRITE'
};

export const MESSAGE_TOPIC_STATUS = {
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  CLOSED: 'CLOSED'
};

export const CARD_CREATION_FORM_TYPE = {
  VIRTUAL: 'virtual',
  PHYSICAL: 'physical',
  ONE_TIME_USAGE: 'one_time_usage'
};

export const CARD_TYPE = {
  VIRTUAL: 'VIRTUAL',
  PHYSICAL: 'PHYSICAL'
};

export const CARD_STATUS = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  SOFT_BLOCK: 'SOFT_BLOCK',
  HARD_BLOCK: 'HARD_BLOCK'
};

export const CARD_BLOCK_TYPE = {
  BLOCKED_BY_CARD_USER: 'BLOCKED_BY_CARD_USER',
  BLOCKED_BY_CARDHOLDER: 'BLOCKED_BY_CARDHOLDER',
  BLOCKED_BY_CARDHOLDER_VIA_PHONE: 'BLOCKED_BY_CARDHOLDER_VIA_PHONE',
  BLOCKED_BY_CLIENT: 'BLOCKED_BY_CLIENT', // blocked by finmatic
  BLOCKED_BY_ISSUER: 'BLOCKED_BY_ISSUER', // blocked by wallester
  COUNTERFEIT: 'COUNTERFEIT',
  FRAUDULENT: 'FRAUDULENT',
  LOST: 'LOST',
  STOLEN: 'STOLEN'
};

export const CARD_ACTIONS = {
  BLOCK: 'BLOCK',
  UNBLOCK: 'UNBLOCK'
};

export const SETTINGS_TABS = {
  PASSWORD_RECOVERY: 'passwordReset',
  MANAGE2FA: 'manage2fa',
  ACTIVITY_LOG: 'activityLog',
  USER_DETAILS: 'userDetails'
};

export const CARD_AUTHORIZATION_STATUS = {
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  COMPLETED: 'COMPLETED'
};

export const TRANSACTION_DOCUMENT_CATEGORY = {
  AGREEMENT: 'AGREEMENT',
  INVOICE: 'INVOICE',
  OTHER: 'OTHER'
};

export const MAX_UPLOADED_FILES_SIZE = 15728640;
export const ALLOWED_FILE_EXTENSIONS = ['.pdf', '.jpg', '.jpeg', '.png'];
export const SUPPORTED_IMAGE_FORMATS = ['.png', '.jpeg', '.jpg'];

export const LIMITED_PAYMENT_DESCRIPTION_SIZE = 18;
export const MAX_PAYMENT_DESCRIPTION_SIZE = 140;
export const MAX_BLACKLIST_VALUE_SIZE = 255;

// eslint-disable-next-line no-useless-escape
export const LATIN_LETTERS_AND_DIGITS_AND_SYMBOLS_REG_EXP = /^[A-Za-z0-9\s!@#$%^&*()_+=\-`"~\\\]\[{}|';:/.,?><]*$/g;

export const INDUSTRY_TYPES = {
  ACTIVITIES_OF_EXTRATERRITORIAL_ORGANIZATIONS_AND_BODIES: 'ACTIVITIES_OF_EXTRATERRITORIAL_ORGANIZATIONS_AND_BODIES',
  ADULT_ENTERTAINMENT: 'ADULT_ENTERTAINMENT',
  ADVERTISING_MARKETING: 'ADVERTISING_MARKETING',
  AGRICULTURE_FORESTRY_FISHERIES: 'AGRICULTURE_FORESTRY_FISHERIES',
  ALCOHOLIC_BEVERAGES_AND_TOBACCO: 'ALCOHOLIC_BEVERAGES_AND_TOBACCO',
  ALIMENTARY_PRODUCTS: 'ALIMENTARY_PRODUCTS',
  ARCHITECTURE_ENGINEERING_AND_TECHNICAL_CONSULTING_IN_THESE_AREAS:
    'ARCHITECTURE_ENGINEERING_AND_TECHNICAL_CONSULTING_IN_THESE_AREAS',
  ARTWORKS_COLLECTION_ITEMS_ANTIQUES: 'ARTWORKS_COLLECTION_ITEMS_ANTIQUES',
  BEVERAGE_SERVICE: 'BEVERAGE_SERVICE',
  BUILDING_AND_TERRITORY_MAINTENANCE: 'BUILDING_AND_TERRITORY_MAINTENANCE',
  CHARITY: 'CHARITY',
  CINEMA_VIDEO_FILMS_AND_TV_PROGRAMS_SOUND_RECORDING_AND_RECORDED_MUSIC:
    'CINEMA_VIDEO_FILMS_AND_TV_PROGRAMS_SOUND_RECORDING_AND_RECORDED_MUSIC',
  CLOTHING_AND_FOOTWEAR: 'CLOTHING_AND_FOOTWEAR',
  COMPUTER_PROGRAMMING_CONSULTING_AND_OTHER_RELATED_SERVICES:
    'COMPUTER_PROGRAMMING_CONSULTING_AND_OTHER_RELATED_SERVICES',
  CONSTRUCTION_ACTIVITIES: 'CONSTRUCTION_ACTIVITIES',
  CREATIVE_WORK_AND_ART: 'CREATIVE_WORK_AND_ART',
  CREDITS_OR_MONETARY_INTERMEDIATION: 'CREDITS_OR_MONETARY_INTERMEDIATION',
  CROWDFUNDING: 'CROWDFUNDING',
  CRYPTOCURRENCIES: 'CRYPTOCURRENCIES',
  DATA_PROCESSING_WEB_PORTALS: 'DATA_PROCESSING_WEB_PORTALS',
  DESIGN: 'DESIGN',
  DOMESTIC_APPLIANCES_AND_ELECTRONICS: 'DOMESTIC_APPLIANCES_AND_ELECTRONICS',
  EDUCATION: 'EDUCATION',
  EMBASSIES_CONSULATES: 'EMBASSIES_CONSULATES',
  ENERGY: 'ENERGY',
  ENGINEERING_TESTS: 'ENGINEERING_TESTS',
  EVENT_MANAGEMENT: 'EVENT_MANAGEMENT',
  FOOD_DELIVERY: 'FOOD_DELIVERY',
  FUEL_AND_GAS: 'FUEL_AND_GAS',
  FUND_MANAGEMENT: 'FUND_MANAGEMENT',
  GAMBLING_GAMES_AND_BETTING: 'GAMBLING_GAMES_AND_BETTING',
  HEALTH_CARE_AND_SOCIAL_SERVICES: 'HEALTH_CARE_AND_SOCIAL_SERVICES',
  HIGH_VALUE_GOODS: 'HIGH_VALUE_GOODS',
  HOLDING_COMPANIES_AND_PROPERTY_MANAGEMENT: 'HOLDING_COMPANIES_AND_PROPERTY_MANAGEMENT',
  HOTELS_AND_RELATED_ACCOMMODATION: 'HOTELS_AND_RELATED_ACCOMMODATION',
  HR_AGENCIES: 'HR_AGENCIES',
  INFORMATION_AND_REFERENCE_SERVICES: 'INFORMATION_AND_REFERENCE_SERVICES',
  INSURANCE: 'INSURANCE',
  INVESTMENT_ACTIVITIES: 'INVESTMENT_ACTIVITIES',
  LANDSCAPE_DESIGN: 'LANDSCAPE_DESIGN',
  LEGAL_SERVICES_AND_ACCOUNTING: 'LEGAL_SERVICES_AND_ACCOUNTING',
  LIBRARIES_ARCHIVES_MUSEUMS: 'LIBRARIES_ARCHIVES_MUSEUMS',
  LODGING_AND_CATERING_SERVICES: 'LODGING_AND_CATERING_SERVICES',
  MANAGEMENT_CONSULTING: 'MANAGEMENT_CONSULTING',
  MANUFACTURING: 'MANUFACTURING',
  MEDICATION_AND_PHARMACEUTICALS: 'MEDICATION_AND_PHARMACEUTICALS',
  MINING_INDUSTRY: 'MINING_INDUSTRY',
  MOTOR_VEHICLES: 'MOTOR_VEHICLES',
  NON_PROFIT_ORGANIZATION_FOUNDATIONS: 'NON_PROFIT_ORGANIZATION_FOUNDATIONS',
  NON_PROFIT_ORGANIZATION_OTHER: 'NON_PROFIT_ORGANIZATION_OTHER',
  OFFSHORE_SERVICES: 'OFFSHORE_SERVICES',
  OTHER_ACCOMMODATION_TYPES: 'OTHER_ACCOMMODATION_TYPES',
  OTHER_ADMINISTRATIVE_ACTIVITIES: 'OTHER_ADMINISTRATIVE_ACTIVITIES',
  OTHER_FINANCIAL_SERVICES_EXCLUDING_INSURANCE_AND_PENSION_FUND_SERVICES:
    'OTHER_FINANCIAL_SERVICES_EXCLUDING_INSURANCE_AND_PENSION_FUND_SERVICES',
  OTHER_GOODS: 'OTHER_GOODS',
  OTHER_INFORMATION_SERVICES: 'OTHER_INFORMATION_SERVICES',
  OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES: 'OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES',
  OTHER_SERVICES: 'OTHER_SERVICES',
  PENSION_FUNDS: 'PENSION_FUNDS',
  PHOTOGRAPHY: 'PHOTOGRAPHY',
  POLITICAL_ORGANIZATION: 'POLITICAL_ORGANIZATION',
  PRIVATE_BANKING: 'PRIVATE_BANKING',
  PROFESSIONAL_MEMBERSHIP_ORGANIZATION: 'PROFESSIONAL_MEMBERSHIP_ORGANIZATION',
  PUBLIC_ADMINISTRATION_AND_DEFENSE: 'PUBLIC_ADMINISTRATION_AND_DEFENSE',
  PUBLISHING_ACTIVITIES: 'PUBLISHING_ACTIVITIES',
  RADIO_BROADCASTING: 'RADIO_BROADCASTING',
  REAL_ESTATE_ACTIVITIES: 'REAL_ESTATE_ACTIVITIES',
  REAL_ESTATE_LEASING_AND_MANAGEMENT: 'REAL_ESTATE_LEASING_AND_MANAGEMENT',
  REAL_ESTATE_PURCHASE_AND_SALE: 'REAL_ESTATE_PURCHASE_AND_SALE',
  RECREATION_AND_ENTERTAINMENT: 'RECREATION_AND_ENTERTAINMENT',
  RELIGIOUS_ORGANIZATION: 'RELIGIOUS_ORGANIZATION',
  RENTING_LEASING: 'RENTING_LEASING',
  REPAIRING_SERVICES: 'REPAIRING_SERVICES',
  RESTAURANTS: 'RESTAURANTS',
  SCIENTIFIC_RESEARCH_AND_DEVELOPMENT: 'SCIENTIFIC_RESEARCH_AND_DEVELOPMENT',
  SECURITY_AND_INVESTIGATION: 'SECURITY_AND_INVESTIGATION',
  SHORT_TERM_ACCOMMODATION_SERVICES: 'SHORT_TERM_ACCOMMODATION_SERVICES',
  SOFTWARE: 'SOFTWARE',
  SPORT: 'SPORT',
  SUPPORTING_ACTIVITIES_RELATED_TO_FINANCIAL_SERVICES: 'SUPPORTING_ACTIVITIES_RELATED_TO_FINANCIAL_SERVICES',
  TELECOMMUNICATIONS: 'TELECOMMUNICATIONS',
  TOUR_OPERATOR_TRAVEL_AGENT: 'TOUR_OPERATOR_TRAVEL_AGENT',
  TOURIST_CAMPS_RECREATION_AND_ENTERTAINMENT_PARKS: 'TOURIST_CAMPS_RECREATION_AND_ENTERTAINMENT_PARKS',
  TRADE_UNION: 'TRADE_UNION',
  TRANSLATION_SERVICES: 'TRANSLATION_SERVICES',
  TRANSPORTATION_AND_STORAGE: 'TRANSPORTATION_AND_STORAGE',
  TRUSTS_FUNDS_AND_OTHER_RELATED_FINANCIAL_FACILITIES: 'TRUSTS_FUNDS_AND_OTHER_RELATED_FINANCIAL_FACILITIES',
  VALUABLES_JEWELRY: 'VALUABLES_JEWELRY',
  WATER_SUPPLY_SEWERAGE_WASTE_COLLECTION_AND_DISTRIBUTION: 'WATER_SUPPLY_SEWERAGE_WASTE_COLLECTION_AND_DISTRIBUTION'
};

export const EXCLUDED_COUNTRIES = ['by', 'ru'];

export const JURISDICTION_CODES = [
  'ae_az',
  'us_al',
  'us_ak',
  'al',
  'us_az',
  'us_ar',
  'aw',
  'au',
  'bs',
  'bh',
  'bd',
  'bb',
  'by',
  'be',
  'bz',
  'bm',
  'bo',
  'br',
  'bg',
  'us_ca',
  'kh',
  'ca',
  'us_co',
  'us_ct',
  'hr',
  'cw',
  'cy',
  'us_de',
  'dk',
  'us_dc',
  'do',
  'ae_du',
  'fi',
  'us_fl',
  'fr',
  'gf',
  'us_ga',
  'de',
  'gi',
  'gr',
  'gl',
  'gp',
  'gg',
  'us_hi',
  'hk',
  'is',
  'us_id',
  'in',
  'us_in',
  'us_ia',
  'ir',
  'ie',
  'im',
  'il',
  'jm',
  'jp',
  'je',
  'us_ks',
  'us_ky',
  'lv',
  'li',
  'us_la',
  'lu',
  'us_me',
  'my',
  'mt',
  'mq',
  'us_md',
  'us_ma',
  'mu',
  'yt',
  'mx',
  'us_mi',
  'us_mn',
  'us_ms',
  'us_mo',
  'md',
  'us_mt',
  'me',
  'mm',
  'us_ne',
  'nl',
  'us_nv',
  'ca_nb',
  'us_nh',
  'us_nj',
  'us_nm',
  'us_ny',
  'nz',
  'ca_nl',
  'us_nc',
  'us_nd',
  'no',
  'ca_ns',
  'us_oh',
  'us_ok',
  'us_or',
  'pk',
  'pa',
  'us_pa',
  'pl',
  'ca_pe',
  'pr',
  'ca_qc',
  'us_ri',
  'ro',
  'rw',
  're',
  'bl',
  'mf',
  'pm',
  'sg',
  'sk',
  'si',
  'za',
  'us_sc',
  'us_sd',
  'es',
  'se',
  'ch',
  'tj',
  'tz',
  'us_tn',
  'us_tx',
  'th',
  'to',
  'tn',
  'ug',
  'ua',
  'gb',
  'us_ut',
  'vu',
  'us_vt',
  'vn',
  'us_va',
  'us_wa',
  'us_wv',
  'us_wi',
  'us_wy'
];
