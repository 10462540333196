import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import { DIRECTION } from 'components/constants';
import {
  createExchangeRatePairRequest,
  createMarkupGroupRequest,
  deleteMarkupGroupRequest,
  getExchangeRatesListRequest,
  getMarkupGroupMarkupsListByIdRequest,
  getMarkupListRequest,
  renameMarkupGroupRequest,
  updateExchangeRatePairStatusRequest,
  updateMarkupGroupRequest
} from 'services/requestAgent';

class MarkupsStore {
  isInitialized = false;
  isLoading = false;
  isNewMarkupGroupSuccessfullyCreated = false;
  isNewExchangeRatePairSuccessfullyCreated = false;
  isMarkupGroupSuccessfullyDeleted = false;
  isNewMarkupToMarkupGroupSuccessfullyCreated = false;
  isNewIndividualMarkupSuccessfullyCreated = false;
  isCustomerMarkupListUpdated = false;
  error = null;
  markupGroups = null;
  currentMarkupGroupDetails = null;
  customerMarkupList = null;
  exchangeRateList = null;
  pagination = {
    sortBy: null,
    page: 0,
    size: 20,
    totalPages: 0,
    totalElements: 0,
    direction: DIRECTION.ASC
  };
  filters = {
    searchText: ''
  };
  markupsListFilters = {
    from: '',
    to: ''
  };

  constructor() {
    makeAutoObservable(this);
  }

  resetMarkupsStore = () => {
    this.isInitialized = false;
    this.isLoading = false;
    this.isNewMarkupGroupSuccessfullyCreated = false;
    this.isNewExchangeRatePairSuccessfullyCreated = false;
    this.isMarkupGroupSuccessfullyDeleted = false;
    this.isNewMarkupToMarkupGroupSuccessfullyCreated = false;
    this.isNewIndividualMarkupSuccessfullyCreated = false;
    this.isCustomerMarkupListUpdated = false;
    this.error = null;
    this.markupGroups = null;
    this.currentMarkupGroupDetails = null;
    this.customerMarkupList = null;
    this.exchangeRateList = null;
    this.pagination = {
      sortBy: null,
      page: 0,
      size: 20,
      totalPages: 0,
      totalElements: 0,
      direction: DIRECTION.ASC
    };
    this.filters = {
      searchText: ''
    };
    this.markupsListFilters = {
      from: '',
      to: ''
    };
  };

  setIsLoading = (status) => {
    this.isLoading = status;
    this.error = null;
  };

  setIsNewMarkupGroupSuccessfullyCreated = (status) => {
    this.isNewMarkupGroupSuccessfullyCreated = status;
  };

  setIsNewExchangeRatePairSuccessfullyCreated = (status) => {
    this.isNewExchangeRatePairSuccessfullyCreated = status;
  };

  setIsMarkupGroupSuccessfullyDeleted = (status) => {
    this.isMarkupGroupSuccessfullyDeleted = status;
  };

  setIsNewMarkupToMarkupGroupSuccessfullyCreated = (status) => {
    this.isNewMarkupToMarkupGroupSuccessfullyCreated = status;
  };

  setIsNewIndividualMarkupSuccessfullyCreated = (status) => {
    this.isNewIndividualMarkupSuccessfullyCreated = status;
  };

  setIsCustomerMarkupListUpdated = (status) => {
    this.isCustomerMarkupListUpdated = status;
  };

  setMarkupGroupsPage = (page) => {
    this.pagination.page = page;
  };

  setMarkupGroupsPageSize = (size) => {
    this.pagination.size = size;
  };

  setFilter = (fieldName, value) => {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  };

  setMarkupsListFilter = (fieldName, value) => {
    this.markupsListFilters[fieldName] = value;
  };

  setSortData = (sortData) => {
    this.pagination.sortBy = sortData.sortBy;
    this.pagination.direction = sortData.direction;
  };

  setFiltersFromUrl = (params) => {
    // Copying parameters from the URL to the filters object properties
    this.filters.searchText = params.searchText || this.filters.searchText;

    // Converting pagination parameters from the URL
    this.pagination.page = parseInt(params.page) || this.pagination.page;
    this.pagination.size = parseInt(params.size) || this.pagination.size;

    // Converting sorting parameters from the URL
    this.pagination.sortBy = params.sort_column || this.pagination.sortBy;
    this.pagination.direction = params.sort_direction || this.pagination.direction;
  };

  setMarkupsListFiltersFromUrl = (params) => {
    const filters = { ...this.markupsListFilters };

    // Mapping URL filter parameters to corresponding properties in the 'markupsListFilters' object
    const filterParamsMapping = {
      from: 'from',
      to: 'to'
    };

    // Iterating over each URL parameter and assigning its values to 'markupsListFilters'
    for (const param in params) {
      if (param in filterParamsMapping) {
        let value = params[param];

        // Handle only array values as arrays, keep others as their original types
        if (Array.isArray(value)) {
          filters[filterParamsMapping[param]] = value;
        } else {
          filters[filterParamsMapping[param]] = value;
        }
      }
    }

    this.markupsListFilters = filters;
  };

  prepareMarkupsListFiltersParams = () => {
    const params = {};

    Object.entries(this.markupsListFilters).forEach(([key, value]) => {
      if (value) {
        params[key] = value;
      }
    });

    return params;
  };

  getMarkupGroupsList = async (pageSize = undefined) => {
    this.setIsLoading(true);
    try {
      const {
        content: markupGroupsList,
        number: page,
        size,
        total_elements: totalElements,
        total_pages: totalPages
      } = await getMarkupListRequest(
        this.pagination.sortBy,
        this.pagination.page,
        pageSize ? pageSize : this.pagination.size,
        this.pagination.direction,
        this.filters.searchText
      );

      runInAction(() => {
        this.isInitialized = true;
        this.markupGroups = markupGroupsList;
        this.pagination = {
          ...this.pagination,
          page,
          size: !pageSize ? size : this.pagination.size,
          totalElements,
          totalPages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getMarkupGroupMarkupsListById = async (markupGroupId) => {
    this.setIsLoading(true);
    try {
      const queryParams = this.prepareMarkupsListFiltersParams();
      const markupGroupMarkupsList = await getMarkupGroupMarkupsListByIdRequest(markupGroupId, queryParams);

      const currentMarkupGroupDetails = this.markupGroups.find(
        (markupGroup) => markupGroup.markupGroupId === markupGroupId
      );

      currentMarkupGroupDetails.markups = markupGroupMarkupsList;

      runInAction(() => {
        this.isInitialized = true;
        this.currentMarkupGroupDetails = currentMarkupGroupDetails;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  createMarkupGroup = async (data) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(createMarkupGroupRequest(data), {
      pending: i18n.getMessage('markupsStore.createMarkupGroup.toastPromise.pending'),
      success: i18n.getMessage('markupsStore.createMarkupGroup.toastPromise.success')
    });

    const newMarkupGroup = await toastPromise;

    try {
      runInAction(() => {
        this.currentMarkupGroupDetails = newMarkupGroup;
        this.isNewMarkupGroupSuccessfullyCreated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  renameMarkupGroup = async (markupGroupId, data) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(renameMarkupGroupRequest(markupGroupId, data), {
      pending: i18n.getMessage('markupsStore.renameMarkupGroup.toastPromise.pending'),
      success: i18n.getMessage('markupsStore.renameMarkupGroup.toastPromise.success')
    });

    try {
      const updatedMarkupGroupDetails = await toastPromise;

      const updatedMarkupGroupList = this.markupGroups.map((markupGroup) =>
        markupGroup.markupGroupId === markupGroupId ? updatedMarkupGroupDetails : markupGroup
      );

      runInAction(() => {
        this.markupGroups = updatedMarkupGroupList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  deleteMarkupGroup = async (markupGroupId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(deleteMarkupGroupRequest(markupGroupId), {
      pending: i18n.getMessage('markupsStore.deleteMarkupGroup.toastPromise.pending'),
      success: i18n.getMessage('markupsStore.deleteMarkupGroup.toastPromise.success')
    });

    try {
      await toastPromise;

      const updatedMarkupGroupList = this.markupGroups.filter(
        (markupGroup) => markupGroup.markupGroupId !== markupGroupId
      );

      runInAction(() => {
        this.markupGroups = updatedMarkupGroupList;
        this.isMarkupGroupSuccessfullyDeleted = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  updateMarkupGroup = async (markupGroupId, markups) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateMarkupGroupRequest(markupGroupId, markups), {
      pending: i18n.getMessage('markupsStore.updateMarkupGroup.toastPromise.pending'),
      success: i18n.getMessage('markupsStore.updateMarkupGroup.toastPromise.success')
    });

    try {
      const updatedMarkupGroupMarkupsList = await toastPromise;

      runInAction(() => {
        this.currentMarkupGroupDetails.markups = updatedMarkupGroupMarkupsList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getExchangeRatesList = async () => {
    this.setIsLoading(true);
    try {
      const queryParams = this.prepareMarkupsListFiltersParams();
      const {
        content: exchangeRateList,
        number: page,
        size,
        total_elements: totalElements,
        total_pages: totalPages
      } = await getExchangeRatesListRequest(this.pagination, queryParams);

      runInAction(() => {
        this.isInitialized = true;
        this.exchangeRateList = exchangeRateList;
        this.pagination = {
          ...this.pagination,
          page,
          size,
          totalElements,
          totalPages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  createExchangeRatePair = async (data) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(createExchangeRatePairRequest(data), {
      pending: i18n.getMessage('markupsStore.createExchangeRatePair.toastPromise.pending'),
      success: i18n.getMessage('markupsStore.createExchangeRatePair.toastPromise.success')
    });

    await toastPromise;

    try {
      runInAction(() => {
        this.isNewExchangeRatePairSuccessfullyCreated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  updateExchangeRatePair = async (data) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateExchangeRatePairStatusRequest(data), {
      pending: i18n.getMessage('markupsStore.updateExchangeRatePair.toastPromise.pending'),
      success: i18n.getMessage('markupsStore.updateExchangeRatePair.toastPromise.success')
    });

    const updatedExchangeRatePair = await toastPromise;

    const updatedExchangeRateList = this.exchangeRateList.map((rate) =>
      rate.from === updatedExchangeRatePair.from && rate.to === updatedExchangeRatePair.to
        ? updatedExchangeRatePair
        : rate
    );

    try {
      runInAction(() => {
        this.exchangeRateList = updatedExchangeRateList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getExchangeRatesListForCreatingMarkupGroup = async () => {
    this.setIsLoading(true);

    const pagination = {
      ...this.pagination,
      size: 1000
    };

    try {
      const queryParams = this.prepareMarkupsListFiltersParams();
      const {
        content: exchangeRateList,
        number: page,
        total_elements: totalElements,
        total_pages: totalPages
      } = await getExchangeRatesListRequest(pagination, queryParams);

      //eslint-disable-next-line no-unused-vars
      const updatedExchangeRateList = exchangeRateList.map(({ active, created_at, ...exchangeRatePair }) => ({
        ...exchangeRatePair,
        markup: 0
      }));

      runInAction(() => {
        this.isInitialized = true;
        this.exchangeRateList = updatedExchangeRateList;
        this.pagination = {
          ...this.pagination,
          page,
          size: 1000,
          totalElements,
          totalPages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}

export default new MarkupsStore();
