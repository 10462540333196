import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MarkupGroupDetailsTable from '../MarkupGroupDetailsTable';
import { MAX_NEW_MARKUP_GROUP_NAME_LENGTH } from 'components/constants';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import Input from 'uikit/Input/Input';
import './CreateMarkupGroupPage.scss';

const CreateMarkupGroupPage = ({ markupsStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const [markupGroupDetailsForCreate, setMarkupGroupDetailsForCreate] = useState(null);

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      newMarkupGroupName: ''
    },
    validationSchema: Yup.object({
      newMarkupGroupName: Yup.string()
        .required(i18n.getMessage('createMarkupGroup.validation.isRequired'))
        .max(
          MAX_NEW_MARKUP_GROUP_NAME_LENGTH,
          i18n.getMessage('createMarkupGroup.validation.maxSize', {
            size: MAX_NEW_MARKUP_GROUP_NAME_LENGTH
          })
        )
        .test('unique-name', i18n.getMessage('createMarkupGroup.validation.duplicateName'), function (value) {
          return !markupsStore?.markupGroups.some((group) => group.markupGroupId === value);
        })
    }),
    onSubmit: async (values) => {
      let markups = [];

      if (
        markupGroupDetailsForCreate &&
        markupGroupDetailsForCreate?.markups &&
        markupGroupDetailsForCreate?.markups.length > 0
      ) {
        // eslint-disable-next-line no-unused-vars
        markups = markupGroupDetailsForCreate.markups.map(({ isNewMarkup, ...rest }) => rest);
      }

      await markupsStore.createMarkupGroup({
        markupGroupId: values.newMarkupGroupName,
        markups
      });
    }
  });

  const { values, errors, handleSubmit, handleChange, validateField } = form;

  useEffect(() => {
    if (!markupsStore.isInitialized) {
      markupsStore.getMarkupGroupsList(1000);
      markupsStore.getExchangeRatesListForCreatingMarkupGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markupsStore.isInitialized]);

  useEffect(() => {
    if (markupsStore.isInitialized) {
      markupsStore.resetMarkupsStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (markupsStore.exchangeRateList) {
      setMarkupGroupDetailsForCreate({
        markups: markupsStore.exchangeRateList
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markupsStore.exchangeRateList]);

  useEffect(() => {
    if (markupsStore.isNewMarkupGroupSuccessfullyCreated) {
      markupsStore.setIsNewMarkupGroupSuccessfullyCreated(false);
      navigate(`${ROUTE_PATHS.MARKUPS_GROUPS}/${values.newMarkupGroupName}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markupsStore.isNewMarkupGroupSuccessfullyCreated]);

  const handleClickBackButton = () => {
    navigate(ROUTE_PATHS.MARKUPS_GROUPS);
  };

  if (!markupsStore.isInitialized || !markupsStore.markupGroups || !markupsStore.exchangeRateList) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container className={'create-markup-group-container'} header={i18n.getMessage('container.createMarkupGroup')}>
      <form className={'create-markup-group-form-wrapper'} onSubmit={handleSubmit}>
        <table className={'create-markup-group-form-table-wrapper'}>
          <tbody className={'create-markup-group-form-table-body'}>
            <tr className={'create-markup-group-form-table-row'}>
              <td colSpan={2} className={'create-markup-group-form-table-cell create-markup-group-form-subsection'}>
                {i18n.getMessage('createMarkupGroup.form.title')}
              </td>
            </tr>
            <tr className={'create-markup-group-form-table-row'}>
              <td className={'create-markup-group-form-table-cell'}>
                {i18n.getMessage('createMarkupGroup.form.input.groupName')}
              </td>
              <td className={'create-markup-group-form-table-cell'}>
                <div className={'create-markup-group-form-table-input-wrapper'}>
                  <Input
                    className={'create-markup-group-form-table-cell-input'}
                    name={'newMarkupGroupName'}
                    value={values.newMarkupGroupName}
                    onChange={handleChange}
                    onBlur={() => validateField('newMarkupGroupName')}
                    max={MAX_NEW_MARKUP_GROUP_NAME_LENGTH}
                    error={errors?.newMarkupGroupName}
                    isDisabled={markupsStore.isLoading}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {markupGroupDetailsForCreate && markupGroupDetailsForCreate.markups?.length > 0 && (
          <MarkupGroupDetailsTable
            isLoading={markupsStore.isLoading}
            isUpdateMarkupGroup={false}
            markupGroupDetails={markupGroupDetailsForCreate}
            setMarkupGroupDetails={setMarkupGroupDetailsForCreate}
            handleClickBackButton={handleClickBackButton}
          />
        )}

        <div className={'create-markup-group-form-table-actions-wrapper'}>
          <Button
            className={'create-markup-group-form-table-button'}
            roleType={'submit'}
            size={'medium'}
            type={'primary'}
            isDisabled={markupsStore.isLoading}
          >
            {i18n.getMessage('createMarkupGroup.button.createGroup')}
          </Button>
        </div>
      </form>
    </Container>
  );
};

CreateMarkupGroupPage.propTypes = {
  markupsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  markupsStore: stores.markupsStore
}))(observer(CreateMarkupGroupPage));
