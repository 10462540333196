import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import './LimitGroupDetailsTableHeadRow.scss';

export const LIMIT_GROUP_DETAILS_TABLE_HEADER_UPDATE = [
  { label: 'limitInfo' },
  { label: 'limitValue' },
  { label: 'createdAtAndUpdatedAt' },
  { label: 'status' },
  { label: 'actions' }
];

export const LIMIT_GROUP_DETAILS_TABLE_HEADER_CREATE = [
  { label: 'limitInfo' },
  { label: 'limitValue' },
  { label: 'status' },
  { label: 'actions' }
];

export const LimitGroupDetailsTableHeadRow = ({ isUpdateLimitGroup }) => {
  const i18n = useContext(i18nContext);

  const getLimitGroupDetailsTableHeaderArray = () => {
    return isUpdateLimitGroup ? LIMIT_GROUP_DETAILS_TABLE_HEADER_UPDATE : LIMIT_GROUP_DETAILS_TABLE_HEADER_CREATE;
  };

  return (
    <tr>
      {getLimitGroupDetailsTableHeaderArray().map((head) => (
        <th key={head.label} className={'limit-group-details-table-head'}>
          <div className={'limit-group-details-table-head-block'}>
            <span>{i18n.getMessage(`limitGroupDetails.table.header.${head.label}`)}</span>
          </div>
        </th>
      ))}
    </tr>
  );
};

LimitGroupDetailsTableHeadRow.propTypes = {
  isUpdateLimitGroup: PropTypes.bool
};
