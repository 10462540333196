import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Table from 'react-bootstrap/Table';
import queryString from 'query-string';
import { ExchangeRatesTableHeadRow } from './components/ExchangeRatesTableHeadRow';
import { ExchangeRatesTableRowContent } from './components/ExchangeRatesTableRowContent';
import { DIRECTION } from 'components/constants';
import EmptyTableContentPlug from 'components/EmptyTableContentPlug';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import PopUpCreateNewExchangeRatePairScheme from 'components/PopUpScheme/PopUpCreateNewExchangeRatePairScheme';
import { useApplicationContext } from 'contexts/ApplicationContext';
import Button from 'uikit/Button/Button';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';
import { PopUp } from 'uikit/PopUp/PopUp';
import './ExchangeRatesTable.scss';

const ExchangeRatesTable = ({ markupsStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { constants } = useApplicationContext();
  const [isShowAddNewExchangeRatePopUp, setIsShowAddNewExchangeRatePopUp] = useState(false);

  const currencyOptions = constants?.currencies?.map((currency) => {
    return {
      key: currency,
      value: currency
    };
  });

  const updateFiltersInUrl = (filters, pagination) => {
    const queryParams = {
      page: pagination.page,
      size: pagination.size,
      sort_direction: pagination.direction
    };

    if (filters.from) {
      queryParams.from = filters.from;
    }
    if (filters.to) {
      queryParams.to = filters.to;
    }

    if (pagination.sortBy) {
      queryParams.sort_column = pagination.sortBy;
    }

    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    markupsStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!markupsStore.isInitialized) {
      markupsStore.getExchangeRatesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markupsStore.isInitialized]);

  useEffect(() => {
    if (markupsStore.isInitialized) {
      markupsStore.resetMarkupsStore();
      loadFiltersFromUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(markupsStore.markupsListFilters, markupsStore.pagination);
    if (markupsStore.isInitialized) {
      markupsStore.getExchangeRatesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    markupsStore.markupsListFilters.from,
    markupsStore.markupsListFilters.to,
    markupsStore.pagination.sortBy,
    markupsStore.pagination.direction,
    markupsStore.pagination.size,
    markupsStore.pagination.page
  ]);

  useEffect(() => {
    if (markupsStore.isNewExchangeRatePairSuccessfullyCreated) {
      markupsStore.getExchangeRatesList();
      markupsStore.setIsNewExchangeRatePairSuccessfullyCreated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markupsStore.isNewExchangeRatePairSuccessfullyCreated]);

  const handleSetCurrentPage = (pageNumber) => {
    markupsStore.setMarkupGroupsPage(pageNumber);
  };

  const handleSetPageSize = (size) => {
    markupsStore.setMarkupGroupsPageSize(size);
  };

  const handleOnSortTable = (newSortBy) => {
    markupsStore.setSortData({
      sortBy: newSortBy,
      direction:
        markupsStore.pagination.sortBy !== newSortBy
          ? DIRECTION.ASC
          : markupsStore.pagination.direction === DIRECTION.ASC
            ? DIRECTION.DESC
            : DIRECTION.ASC
    });
  };

  const handleChangeExchangeRateStatus = async (data, status) => {
    await markupsStore.updateExchangeRatePair({ ...data, active: status });
  };

  const handleStatusCreateNewExchangeRatePopUp = () => {
    setIsShowAddNewExchangeRatePopUp(!isShowAddNewExchangeRatePopUp);
  };

  const handleCreateNewExchangeRatePair = async (data) => {
    await markupsStore.createExchangeRatePair(data);
    handleStatusCreateNewExchangeRatePopUp();
  };

  if (!markupsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <div className={'exchange-rates-wrapper'}>
      <div className={'exchange-rates-actions-wrapper'}>
        <div className={'exchange-rates-filters-container'}>
          <InputDropDownSearch
            className={'exchange-rates-filter-wrapper'}
            name={'from'}
            label={i18n.getMessage('exchangeRates.filter.from.label')}
            value={markupsStore.markupsListFilters.from}
            options={currencyOptions}
            onChange={markupsStore.setMarkupsListFilter}
            isDisabled={markupsStore.isLoading}
          />
          <InputDropDownSearch
            className={'exchange-rates-filter-wrapper'}
            name={'to'}
            label={i18n.getMessage('exchangeRates.filter.to.label')}
            value={markupsStore.markupsListFilters.to}
            options={currencyOptions}
            onChange={markupsStore.setMarkupsListFilter}
            isDisabled={markupsStore.isLoading}
          />
        </div>

        <Button
          className={'exchange-rates-button'}
          size={'medium'}
          type={'primary'}
          onClick={handleStatusCreateNewExchangeRatePopUp}
          isDisabled={!markupsStore.isInitialized}
        >
          {i18n.getMessage('exchangeRates.button.createNewExchangeRate')}
        </Button>
      </div>

      {!markupsStore.exchangeRateList || markupsStore.exchangeRateList?.length === 0 ? (
        <EmptyTableContentPlug
          headerText={i18n.getMessage('exchangeRates.table.emptyPlug.header')}
          descriptionText={i18n.getMessage('exchangeRates.table.emptyPlug.description')}
        />
      ) : (
        <>
          <Pagination
            isShowPaginationSize={true}
            paginationSize={markupsStore.pagination.size}
            handleChangePaginationSize={handleSetPageSize}
            isLoading={markupsStore.isLoading}
            currentPage={markupsStore.pagination.page}
            numberOfPages={markupsStore.pagination.totalPages}
            setCurrentPage={handleSetCurrentPage}
          />

          <Table responsive>
            <thead>
              <ExchangeRatesTableHeadRow
                handleOnSortTable={handleOnSortTable}
                sortBy={markupsStore.pagination.sortBy}
                direction={markupsStore.pagination.direction}
              />
            </thead>
            <tbody>
              {markupsStore.exchangeRateList?.map((exchangeRateData, index) => (
                <ExchangeRatesTableRowContent
                  key={index}
                  type={'list'}
                  isLoading={markupsStore.isLoading}
                  handleChangeExchangeRateStatus={handleChangeExchangeRateStatus}
                  exchangeRateData={exchangeRateData}
                />
              ))}
            </tbody>
          </Table>

          <Pagination
            isShowPaginationSize={true}
            paginationSize={markupsStore.pagination.size}
            handleChangePaginationSize={handleSetPageSize}
            isLoading={markupsStore.isLoading}
            currentPage={markupsStore.pagination.page}
            numberOfPages={markupsStore.pagination.totalPages}
            setCurrentPage={handleSetCurrentPage}
          />
        </>
      )}

      <PopUp alignOnCenter show={isShowAddNewExchangeRatePopUp}>
        <PopUpCreateNewExchangeRatePairScheme
          isLoading={markupsStore.isLoading}
          handleClose={handleStatusCreateNewExchangeRatePopUp}
          handleCreateNewExchangeRatePair={handleCreateNewExchangeRatePair}
        />
      </PopUp>
    </div>
  );
};

ExchangeRatesTable.propTypes = {
  markupsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  markupsStore: stores.markupsStore
}))(observer(ExchangeRatesTable));
