import { Fragment, useContext } from 'react';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { formatDate, formatMoney } from 'services/utils';
import './WithdrawalTransactionsTableRow.scss';

const WithdrawalTransactionsTableRow = ({ isLoading, withdrawalTransactionData }) => {
  const i18n = useContext(i18nContext);

  const getWithdrawalTransactionInfoBlock = (withdrawalTransactionBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'withdrawal-transactions-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (Array.isArray(withdrawalTransactionBlockInfo)) {
      return (
        <p>
          {withdrawalTransactionBlockInfo.map((item, index) => {
            if (item) {
              return (
                <Fragment key={index}>
                  {item}
                  {index < withdrawalTransactionBlockInfo?.length - 1 && <br />}
                </Fragment>
              );
            }
          })}
        </p>
      );
    } else {
      return <p>{withdrawalTransactionBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'withdrawal-transactions-table-cell'}>
        {getWithdrawalTransactionInfoBlock(withdrawalTransactionData?.transaction_id)}
      </td>
      <td className={'withdrawal-transactions-table-cell'}>
        {getWithdrawalTransactionInfoBlock(formatDate(withdrawalTransactionData?.created_at))}
      </td>
      <td className={'withdrawal-transactions-table-cell'}>
        {getWithdrawalTransactionInfoBlock(
          i18n.getMessage(`paymentProvider.${withdrawalTransactionData?.payment_provider}`)
        )}
      </td>
      <td className={'withdrawal-transactions-table-cell'}>
        {getWithdrawalTransactionInfoBlock(formatMoney(withdrawalTransactionData?.amount, 2))}
      </td>
      <td className={'withdrawal-transactions-table-cell'}>
        {getWithdrawalTransactionInfoBlock(withdrawalTransactionData?.currency)}
      </td>
      <td className={'withdrawal-transactions-table-cell'}>
        {getWithdrawalTransactionInfoBlock(withdrawalTransactionData?.comment)}
      </td>
    </tr>
  );
};

WithdrawalTransactionsTableRow.propTypes = {
  isLoading: PropTypes.bool,
  withdrawalTransactionData: PropTypes.object
};

export default WithdrawalTransactionsTableRow;
