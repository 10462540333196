import PropTypes from 'prop-types';
import { ReactComponent as EmptyTablePlugIcon } from 'assets/empty-table-plug.svg';
import './EmptyTableContentPlug.scss';

export const EmptyTableContentPlug = ({ headerText, descriptionText }) => {
  return (
    <div className={'plug-container'}>
      <EmptyTablePlugIcon className={'plug-icon'} />
      <div className={'plug-info-wrapper'}>
        <h2 className={'plug-info-header'}>{headerText}</h2>
        <p className={'plug-info-description'}>{descriptionText}</p>
      </div>
    </div>
  );
};

EmptyTableContentPlug.propTypes = {
  headerText: PropTypes.string,
  descriptionText: PropTypes.string
};

export default EmptyTableContentPlug;
