import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Table from 'react-bootstrap/Table';
import PaymentsCutoffTimesTableHeadRow from './components/PaymentsCutoffTimesTableHeadRow';
import PaymentsCutoffTimesTableRow from './components/PaymentsCutoffTimesTableRow';
import Loader from 'components/Loader';
import i18nContext from 'components/i18n-context';
import Button from 'uikit/Button/Button';
import './PaymentsCutoffTimesTab.scss';

const PaymentsCutoffTimesTab = ({ settingsStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();

  useEffect(() => {
    if (!settingsStore.isInitialized) {
      settingsStore.getApplicationProperties();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsStore.isInitialized]);

  useEffect(() => {
    if (settingsStore.isInitialized) {
      settingsStore.resetSettingsStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (!settingsStore.paymentWorkTimeList) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <div className={'payments-cutoff-times-tab-wrapper'}>
      <Table responsive>
        <thead>
          <PaymentsCutoffTimesTableHeadRow />
        </thead>
        <tbody>
          {settingsStore.paymentWorkTimeList?.length > 0 &&
            settingsStore.paymentWorkTimeList.map((paymentWorkTime, index) => (
              <PaymentsCutoffTimesTableRow
                key={index}
                type={'list'}
                paymentWorkTimeData={paymentWorkTime}
                handleTimeChange={settingsStore.setPaymentWorkTime}
                handleNonWorkingDaysChange={settingsStore.setPaymentNonWorkingDays}
              />
            ))}
        </tbody>
      </Table>
      <div className={'payments-cutoff-times-tab-actions-wrapper'}>
        <Button
          className={'payments-cutoff-times-tab-button'}
          size={'medium'}
          type={'primary'}
          roleType={'button'}
          onClick={settingsStore.updatePaymentWorkTimeList}
          isDisabled={!settingsStore.isInitialized || settingsStore.isLoading}
        >
          {settingsStore.isLoading ? <Loader /> : i18n.getMessage('settings.paymentsCutoffTimes.button.saveChanges')}
        </Button>
      </div>
    </div>
  );
};

PaymentsCutoffTimesTab.propTypes = {
  settingsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  settingsStore: stores.settingsStore
}))(observer(PaymentsCutoffTimesTab));
