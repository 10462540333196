import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowSelect } from 'assets/arrow-select.svg';
import i18nContext from 'components/i18n-context';
import './PaymentObligationsTableHead.scss';

export const PAYMENT_OBLIGATIONS_TABLE_HEADER = [
  {
    label: 'obligationId'
  },
  {
    label: 'transactionId'
  },
  {
    label: 'amount',
    sortBy: 'amount'
  },
  {
    label: 'currency',
    sortBy: 'currency'
  },
  {
    label: 'status',
    sortBy: 'status'
  },
  {
    label: 'customerId',
    sortBy: 'account_number'
  },
  {
    label: 'comment'
  },
  {
    label: 'createdAt',
    sortBy: 'created_at'
  },
  {
    label: 'updatedAt',
    sortBy: 'updated_at'
  },
  {
    label: 'actions'
  }
];

const PaymentObligationsTableHead = ({ handleOnSortTable, sortBy, direction }) => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {PAYMENT_OBLIGATIONS_TABLE_HEADER.map(
        (head) =>
          head.label && (
            <th key={head.label} className={'payment-obligations-table-head'}>
              <div className={'payment-obligations-table-head-block'}>
                <span>{i18n.getMessage(`paymentObligations.table.header.${head.label}`)}</span>
                {head.sortBy && (
                  <ArrowSelect
                    onClick={() => handleOnSortTable(head.sortBy)}
                    className={`payment-obligations-sorting-arrow-icon ${sortBy === head.sortBy && direction}`}
                  />
                )}
              </div>
            </th>
          )
      )}
    </tr>
  );
};

PaymentObligationsTableHead.propTypes = {
  handleOnSortTable: PropTypes.func,
  sortBy: PropTypes.string,
  direction: PropTypes.string
};

export default PaymentObligationsTableHead;
