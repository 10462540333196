import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { AddRepresentativeScheme } from './PopUpSchemes/AddRepresentativeScheme';
import { DeleteUserScheme } from './PopUpSchemes/DeleteUserScheme';
import { ErrorAddingYourselfScheme } from './PopUpSchemes/ErrorAddingYourselfScheme';
import { ErrorAlreadyAddedScheme } from './PopUpSchemes/ErrorAlreadyAddedScheme';
import { ErrorInvalidEmail } from './PopUpSchemes/ErrorInvalidEmail';
import { ErrorNoAccountScheme } from './PopUpSchemes/ErrorNoAccountScheme';
import { ErrorNotVerifiedScheme } from './PopUpSchemes/ErrorNotVerifiedScheme';
import { LinkedSuccessScheme } from './PopUpSchemes/LinkedSuccessScheme';
import { PermissionScheme } from './PopUpSchemes/PermissionScheme';
import { RepresentativesTableHeadRow } from './components/RepresentativesTableHeadRow';
import { RepresentativesTableContentRow } from './components/RepresentativesTableContentRow';
import { REPRESENTATIVE_POP_UP } from 'components/constants';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { useApplicationContext } from 'contexts/ApplicationContext';
import Button from 'uikit/Button/Button';
import { PopUp } from 'uikit/PopUp/PopUp';
import './RepresentativesTab.scss';

const REPRESENTATIVE_SUCCESS_ACTION_TYPE = {
  CHANGE: 'CHANGE',
  DELETE: 'DELETE',
  ADD: 'ADD'
};

const RepresentativesTab = ({
  isLoading,
  representatives,
  handleAddRepresentative,
  handleRemoveRepresentative,
  handleUpdateRepresentativePermissions,
  handleSetRepresentativePopUpType,
  representativePopUpType
}) => {
  const i18n = useContext(i18nContext);
  const { constants } = useApplicationContext();
  const [representativeEmail, setRepresentativeEmail] = useState('');
  const [representativeSuccessActionType, setRepresentativeSuccessActionType] = useState(null);
  const [representativeForUpdate, setRepresentativeForUpdate] = useState(null);

  const closePopUp = () => {
    handleSetRepresentativePopUpType(null);
    setRepresentativeSuccessActionType(null);
    setRepresentativeForUpdate(null);
    setRepresentativeEmail('');
  };

  const handleSetPopUpType = (type) => {
    handleSetRepresentativePopUpType(type);
  };

  const handleSaveRepresentativeEmail = (email) => {
    setRepresentativeEmail(email);
    handleSetPopUpType(REPRESENTATIVE_POP_UP.PERMISSION);
  };

  const handleChangeRepresentativeFunc = (representative) => {
    handleSetRepresentativePopUpType(REPRESENTATIVE_POP_UP.PERMISSION);
    setRepresentativeForUpdate(representative);
  };

  const handleRemoveRepresentativeFunc = (representative) => {
    handleSetRepresentativePopUpType(REPRESENTATIVE_POP_UP.DELETE);
    setRepresentativeForUpdate(representative);
  };

  const handlePermissionConfirm = async (permissions) => {
    if (!representativeForUpdate) {
      await handleAddRepresentative(representativeEmail, permissions);
      setRepresentativeSuccessActionType(REPRESENTATIVE_SUCCESS_ACTION_TYPE.ADD);
    } else {
      await handleUpdateRepresentativePermissions(representativeForUpdate.id, permissions);
      setRepresentativeSuccessActionType(REPRESENTATIVE_SUCCESS_ACTION_TYPE.CHANGE);
    }
  };

  const handleAcceptRemove = async () => {
    await handleRemoveRepresentative(representativeForUpdate.id);
    setRepresentativeSuccessActionType(REPRESENTATIVE_SUCCESS_ACTION_TYPE.DELETE);
  };

  const getSuccessPopupMessage = () => {
    if (representativeSuccessActionType === REPRESENTATIVE_SUCCESS_ACTION_TYPE.ADD) {
      return i18n.getMessage('representatives.header.message.successLinked');
    }
    if (representativeSuccessActionType === REPRESENTATIVE_SUCCESS_ACTION_TYPE.CHANGE) {
      return i18n.getMessage('representatives.header.message.successUpdated');
    }
    if (representativeSuccessActionType === REPRESENTATIVE_SUCCESS_ACTION_TYPE.DELETE) {
      return i18n.getMessage('representatives.header.message.successRemoved');
    }
    return '';
  };

  const renderPopUpScheme = () => {
    switch (representativePopUpType) {
      case REPRESENTATIVE_POP_UP.ADD: {
        return (
          <AddRepresentativeScheme
            addNewClick={handleSaveRepresentativeEmail}
            closePopUp={closePopUp}
            // error={representativeStore.error}
          />
        );
      }
      case REPRESENTATIVE_POP_UP.ERROR_USER_NOT_APPLICATION: {
        return <ErrorNoAccountScheme closePopUp={closePopUp} />;
      }
      case REPRESENTATIVE_POP_UP.ERROR_NOT_VERIFIED: {
        return <ErrorNotVerifiedScheme closePopUp={closePopUp} />;
      }
      case REPRESENTATIVE_POP_UP.ERROR_ALREADY_ADDED: {
        return <ErrorAlreadyAddedScheme closePopUp={closePopUp} />;
      }
      case REPRESENTATIVE_POP_UP.ERROR_ADDING_YOURSELF_AS_REPRESENTATIVE: {
        return <ErrorAddingYourselfScheme closePopUp={closePopUp} />;
      }
      case REPRESENTATIVE_POP_UP.ERROR_INVALID_EMAIL: {
        return <ErrorInvalidEmail closePopUp={closePopUp} />;
      }
      case REPRESENTATIVE_POP_UP.PERMISSION: {
        return (
          <PermissionScheme
            email={representativeEmail}
            submitPermissions={handlePermissionConfirm}
            permissions={constants?.representativePermissions}
            closePopUp={closePopUp}
            representativeForUpdate={representativeForUpdate}
          />
        );
      }
      case REPRESENTATIVE_POP_UP.SUCCESS: {
        return <LinkedSuccessScheme message={getSuccessPopupMessage()} closePopUp={closePopUp} />;
      }
      case REPRESENTATIVE_POP_UP.DELETE: {
        return (
          <DeleteUserScheme
            closePopUp={closePopUp}
            representativeMail={representativeEmail}
            handleRemoveClick={handleAcceptRemove}
            message={getSuccessPopupMessage()}
            // error={representativeStore.error}
            // clearError={() => representativeStore.clearConfirmationPopupError()}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  useEffect(() => {
    if (representativePopUpType === REPRESENTATIVE_POP_UP.ERROR) {
      closePopUp();
    }
  }, [representativePopUpType]);

  if (!representatives) {
    return <Loader className={'application-loader representatives-tab-loader'} />;
  }

  return (
    <div className={'representatives-details-container representatives-table'}>
      <Table responsive>
        <thead>
          <RepresentativesTableHeadRow />
        </thead>
        <tbody>
          {representatives.map((representativeDetails, index) => (
            <RepresentativesTableContentRow
              key={index}
              isLoading={false}
              representativeDetails={representativeDetails}
              handleChangeRepresentative={handleChangeRepresentativeFunc}
              handleRemoveRepresentative={handleRemoveRepresentativeFunc}
            />
          ))}
        </tbody>
      </Table>

      <div className={'representatives-details-button'}>
        <Button
          type={'primary'}
          size={'small'}
          onClick={() => handleSetPopUpType(REPRESENTATIVE_POP_UP.ADD)}
          className={'representatives-actions-button'}
        >
          {i18n.getMessage('representativesTab.table.action.addRepresentative')}
        </Button>
      </div>

      <PopUp alignOnCenter show={representativePopUpType}>
        {renderPopUpScheme()}
      </PopUp>
    </div>
  );
};

RepresentativesTab.propTypes = {
  isLoading: PropTypes.bool,
  representatives: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])]),
  handleAddRepresentative: PropTypes.func,
  handleRemoveRepresentative: PropTypes.func,
  handleUpdateRepresentativePermissions: PropTypes.func,
  handleSetRepresentativePopUpType: PropTypes.func,
  representativePopUpType: PropTypes.string
};

export default RepresentativesTab;
