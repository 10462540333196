import { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import './MarkupGroupDetailsTableHeadRow.scss';

export const MARKUP_GROUP_DETAILS_TABLE_HEADER = [{ label: 'from' }, { label: 'to' }, { label: 'markup' }];

export const MarkupGroupDetailsTableHeadRow = () => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {MARKUP_GROUP_DETAILS_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'markup-group-details-table-head'}>
          <div className={'markup-group-details-table-head-block'}>
            <span>{i18n.getMessage(`markupGroupDetails.table.header.${head.label}`)}</span>
          </div>
        </th>
      ))}
    </tr>
  );
};
