import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import newTariffModel from '../models/newTariffModel';
import TariffGroupDetailsTable from '../TariffGroupDetailsTable';
import { MAX_NEW_TARIFF_GROUP_NAME_LENGTH } from 'components/constants';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import PopUpAddNewTariffToTariffGroupScheme from 'components/PopUpScheme/PopUpAddNewTariffToTariffGroupScheme';
import { ROUTE_PATHS } from 'routes/constants';
import { hasRole, TARIFF_GROUPS_CREATE } from 'services/roles';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { PopUp } from 'uikit/PopUp/PopUp';
import './CreateTariffGroupPage.scss';

const CreateTariffGroupPage = ({ tariffsStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const [isAddNewTariffButtonClicked, setIsAddNewTariffButtonClicked] = useState(false);
  const [parentGroupOptions, setParentGroupOptions] = useState([]);
  const [tariffGroupDetailsForCreate, setTariffGroupDetailsForCreate] = useState(null);
  const [isShowAddNewTariffPopUp, setIsShowAddNewTariffPopUp] = useState(false);

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      parentGroup: '',
      newTariffGroupName: ''
    },
    validationSchema: Yup.object({
      parentGroup: Yup.string().notRequired().nullable(),
      newTariffGroupName: Yup.string()
        .required(i18n.getMessage('createTariffGroup.validation.isRequired'))
        .max(
          MAX_NEW_TARIFF_GROUP_NAME_LENGTH,
          i18n.getMessage('createTariffGroup.validation.maxSize', {
            size: MAX_NEW_TARIFF_GROUP_NAME_LENGTH
          })
        )
        .test('unique-name', i18n.getMessage('createTariffGroup.validation.duplicateName'), function (value) {
          return !parentGroupOptions.some((option) => option.value === value);
        })
    }),
    onSubmit: async (values) => {
      const tariffs =
        tariffGroupDetailsForCreate && tariffGroupDetailsForCreate?.tariffs?.length > 0
          ? tariffGroupDetailsForCreate.tariffs.reduce((acc, tariff) => {
              if (!tariff.systemTariffKey) {
                acc.push(newTariffModel(tariff));
              }
              return acc;
            }, [])
          : [];

      await tariffsStore.createTariffGroup({
        tariffGroupId: values.newTariffGroupName,
        tariffs
      });
    }
  });

  const { values, errors, handleSubmit, handleChange, setFieldValue, validateField } = form;

  useEffect(() => {
    if (!tariffsStore.isInitialized) {
      tariffsStore.getTariffGroupList(1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffsStore.isInitialized]);

  useEffect(() => {
    if (tariffsStore.isInitialized) {
      tariffsStore.resetTariffsStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (tariffsStore?.tariffGroups) {
      const newParentGroupOptions = tariffsStore?.tariffGroups.map((tariffGroup) => {
        return {
          key: tariffGroup.tariffGroupId,
          value: tariffGroup.tariffGroupId
        };
      });
      setParentGroupOptions(newParentGroupOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffsStore.tariffGroups]);

  useEffect(() => {
    if (values.parentGroup) {
      tariffsStore.getTariffGroupTariffListById(values.parentGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.parentGroup]);

  useEffect(() => {
    if (tariffsStore.currentTariffGroupDetails) {
      setTariffGroupDetailsForCreate((prevState) => {
        const isNewTariffs = prevState?.tariffs?.filter((tariff) => tariff?.isNewTariff) || [];
        const newParentTariffs = tariffsStore.currentTariffGroupDetails.tariffs || [];

        return {
          ...tariffsStore.currentTariffGroupDetails,
          tariffs: [...newParentTariffs, ...isNewTariffs]
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffsStore.currentTariffGroupDetails]);

  useEffect(() => {
    if (tariffsStore.isNewTariffGroupSuccessfullyCreated) {
      tariffsStore.setIsNewTariffGroupSuccessfullyCreated(false);
      navigate(`${ROUTE_PATHS.TARIFFS}/${values.newTariffGroupName}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffsStore.isNewTariffGroupSuccessfullyCreated]);

  const handleClickBackButton = () => {
    navigate(ROUTE_PATHS.TARIFFS);
  };

  const handleAddNewTariff = async () => {
    await validateField('newTariffGroupName');
    setIsAddNewTariffButtonClicked(true);
  };

  useEffect(() => {
    if (isAddNewTariffButtonClicked && !errors?.newTariffGroupName) {
      setIsShowAddNewTariffPopUp(true);
      setIsAddNewTariffButtonClicked(false);
    } else {
      setIsAddNewTariffButtonClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddNewTariffButtonClicked]);

  const handleCloseAddNewTariffPopUp = () => {
    setIsShowAddNewTariffPopUp(false);
  };

  const handleAddNewTariffToTariffGroup = async (newTariff) => {
    const updatedNewTariff = { ...newTariff, isNewTariff: true };

    if (!tariffGroupDetailsForCreate) {
      setTariffGroupDetailsForCreate({
        tariffGroupId: updatedNewTariff.tariffGroupId,
        tariffs: [updatedNewTariff]
      });
    } else {
      setTariffGroupDetailsForCreate((previousState) => ({
        ...previousState,
        tariffs: [...previousState.tariffs, updatedNewTariff]
      }));
    }

    handleCloseAddNewTariffPopUp();
  };

  if (!tariffsStore.isInitialized || !tariffsStore.tariffGroups) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container className={'create-tariff-group-container'} header={i18n.getMessage('container.createTariffGroup')}>
      <form className={'create-tariff-group-form-wrapper'} onSubmit={handleSubmit}>
        <table className={'create-tariff-group-form-table-wrapper'}>
          <tbody className={'create-tariff-group-form-table-body'}>
            <tr className={'create-tariff-group-form-table-row'}>
              <td colSpan={2} className={'create-tariff-group-form-table-cell create-tariff-group-form-subsection'}>
                {i18n.getMessage('createTariffGroup.form.title')}
              </td>
            </tr>
            <tr className={'create-tariff-group-form-table-row'}>
              <td className={'create-tariff-group-form-table-cell'}>
                {i18n.getMessage('createTariffGroup.form.input.parentTariffGroup')}{' '}
                <i>{i18n.getMessage('createTariffGroup.form.input.parentTariffGroup.optional')}</i>
              </td>
              <td className={'create-tariff-group-form-table-cell'}>
                <div className={'create-tariff-group-form-table-input-wrapper'}>
                  <InputDropDown
                    className={'create-tariff-group-form-table-cell-input'}
                    id={'parentGroup'}
                    name={'parentGroup'}
                    options={parentGroupOptions}
                    value={values.parentGroup}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(TARIFF_GROUPS_CREATE)}
                    error={errors?.parentGroup}
                  />
                </div>
              </td>
            </tr>
            <tr className={'create-tariff-group-form-table-row'}>
              <td className={'create-tariff-group-form-table-cell'}>
                {i18n.getMessage('createTariffGroup.form.input.groupName')}
              </td>
              <td className={'create-tariff-group-form-table-cell'}>
                <div className={'create-tariff-group-form-table-input-wrapper'}>
                  <Input
                    className={'create-tariff-group-form-table-cell-input'}
                    name={'newTariffGroupName'}
                    value={values.newTariffGroupName}
                    onChange={handleChange}
                    onBlur={() => validateField('newTariffGroupName')}
                    max={MAX_NEW_TARIFF_GROUP_NAME_LENGTH}
                    isDisabled={!hasRole(TARIFF_GROUPS_CREATE)}
                    error={errors?.newTariffGroupName}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {tariffGroupDetailsForCreate && (
          <TariffGroupDetailsTable
            isLoading={tariffsStore.isLoading}
            isUpdateTariffGroup={false}
            tariffGroupDetails={tariffGroupDetailsForCreate}
            setTariffGroupDetails={setTariffGroupDetailsForCreate}
            handleClickBackButton={handleClickBackButton}
          />
        )}

        <div className={'create-tariff-group-form-table-actions-wrapper'}>
          <Button
            className={'create-tariff-group-form-table-button'}
            roleType={'button'}
            size={'medium'}
            type={'primary'}
            onClick={handleAddNewTariff}
            isDisabled={tariffsStore.isLoading || !hasRole(TARIFF_GROUPS_CREATE)}
          >
            {i18n.getMessage('tariffGroupDetails.button.addNewTariff')}
          </Button>
          <Button
            className={'create-tariff-group-form-table-button'}
            roleType={'submit'}
            size={'medium'}
            type={'primary'}
            isDisabled={tariffsStore.isLoading || !hasRole(TARIFF_GROUPS_CREATE)}
          >
            {i18n.getMessage('createTariffGroup.button.createGroup')}
          </Button>
        </div>
      </form>

      <PopUp alignOnCenter show={isShowAddNewTariffPopUp}>
        <PopUpAddNewTariffToTariffGroupScheme
          isLoading={tariffsStore.isLoading}
          isTariffGroupCreation={true}
          handleClose={handleCloseAddNewTariffPopUp}
          handleAddNewTariffToTariffGroup={handleAddNewTariffToTariffGroup}
          tariffFormData={tariffsStore.tariffFormData}
          handleGetTariffFormData={tariffsStore.getTariffFormData}
        />
      </PopUp>
    </Container>
  );
};

CreateTariffGroupPage.propTypes = {
  tariffsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  tariffsStore: stores.tariffsStore
}))(observer(CreateTariffGroupPage));
