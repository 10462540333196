import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowSelect } from 'assets/arrow-select.svg';
import i18nContext from 'components/i18n-context';
import './MarkupGroupsTableHeadRow.scss';

export const MARKUP_GROUPS_TABLE_HEADER = [
  {
    label: 'markupGroup',
    sortBy: 'markupGroupId'
  },
  {
    label: 'createDate',
    sortBy: 'createdAt'
  },
  {
    label: 'lastUpdate',
    sortBy: 'updatedAt'
  },
  {
    label: 'customersCount'
  },
  {
    label: 'actions'
  }
];

export const MarkupGroupsTableHeadRow = ({ handleOnSortTable, sortBy, direction }) => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {MARKUP_GROUPS_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'markup-groups-table-head'}>
          <div className={'markup-groups-table-head-block'}>
            <span>{i18n.getMessage(`markupGroups.table.header.${head.label}`)}</span>
            {head.sortBy && (
              <ArrowSelect
                onClick={() => handleOnSortTable(head.sortBy)}
                className={`arrow-icon ${sortBy === head.sortBy && direction}`}
              />
            )}
          </div>
        </th>
      ))}
    </tr>
  );
};

MarkupGroupsTableHeadRow.propTypes = {
  handleOnSortTable: PropTypes.func,
  sortBy: PropTypes.string,
  direction: PropTypes.string
};
