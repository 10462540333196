import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import queryString from 'query-string';
import TariffGroupDetailsFiltersBar from '../TariffGroupDetailsTable/components/TariffGroupDetailsFiltersBar';
import TariffGroupDetailsTable from '../TariffGroupDetailsTable';
import EmptyTableContentPlug from 'components/EmptyTableContentPlug';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import PopUpAddNewTariffToTariffGroupScheme from 'components/PopUpScheme/PopUpAddNewTariffToTariffGroupScheme';
import { ROUTE_PATHS } from 'routes/constants';
import { hasRole, TARIFF_GROUPS_UPDATE } from 'services/roles';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import { PopUp } from 'uikit/PopUp/PopUp';
import './TariffGroupDetailsPage.scss';

const TariffGroupDetailsPage = ({ tariffsStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const { id: tariffGroupId } = useParams();
  const [tariffGroupDetailsForUpdate, setTariffGroupDetailsForUpdate] = useState(
    tariffsStore.currentTariffGroupDetails || null
  );
  const [isShowAddNewTariffPopUp, setIsShowAddNewTariffPopUp] = useState(false);

  const updateTariffListFiltersInUrl = () => {
    const queryParams = tariffsStore.prepareTariffListFiltersParams();

    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadTariffFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    tariffsStore.setTariffListFiltersFromUrl(params);
  };

  useEffect(() => {
    loadTariffFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tariffsStore.isInitialized) {
      tariffsStore.getTariffGroupList(1000);
      tariffsStore.getTariffFormData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffsStore.isInitialized]);

  useEffect(() => {
    if (tariffsStore.isInitialized) {
      tariffsStore.resetTariffsStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateTariffListFiltersInUrl();
    if (tariffGroupId && tariffsStore.isInitialized) {
      tariffsStore.getTariffGroupTariffListById(tariffGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tariffGroupId,
    tariffsStore.isInitialized,
    tariffsStore.tariffListFilters.fee_type,
    tariffsStore.tariffListFilters.scheduled,
    tariffsStore.tariffListFilters.transactional,
    tariffsStore.tariffListFilters.transaction_type,
    tariffsStore.tariffListFilters.transfer_provider,
    tariffsStore.tariffListFilters.transfer_type,
    tariffsStore.tariffListFilters.payment_method,
    tariffsStore.tariffListFilters.currency,
    tariffsStore.tariffListFilters.network,
    tariffsStore.tariffListFilters.enabled
  ]);

  useEffect(() => {
    if (tariffsStore.currentTariffGroupDetails) {
      setTariffGroupDetailsForUpdate(tariffsStore.currentTariffGroupDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffsStore.currentTariffGroupDetails]);

  const handleAddNewTariff = () => {
    setIsShowAddNewTariffPopUp(true);
  };

  const handleClickBackButton = () => {
    navigate(ROUTE_PATHS.TARIFFS);
  };

  const handleUpdateTariffGroup = async (tariffs) => {
    await tariffsStore.updateTariffGroup(tariffGroupId, tariffs);
  };

  const handleCloseAddNewTariffPopUp = () => {
    setIsShowAddNewTariffPopUp(false);
  };

  const handleAddNewTariffToTariffGroup = async (values) => {
    await tariffsStore.addNewTariffToTariffGroup(tariffGroupId, values);
  };

  useEffect(() => {
    if (tariffsStore.isNewTariffToTariffGroupSuccessfullyCreated) {
      handleCloseAddNewTariffPopUp();
      tariffsStore.setIsNewTariffToTariffGroupSuccessfullyCreated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffsStore.isNewTariffToTariffGroupSuccessfullyCreated]);

  if (!tariffGroupDetailsForUpdate || !tariffsStore.tariffFormData) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container
      className={'tariff-group-details-container'}
      header={tariffsStore?.currentTariffGroupDetails?.tariffGroupId}
    >
      <div className={'tariff-group-details-actions-wrapper'}>
        <Button
          className={'tariff-group-details-button'}
          size={'medium'}
          type={'primary'}
          onClick={handleAddNewTariff}
          isDisabled={!tariffsStore.isInitialized || tariffsStore.isLoading || !hasRole(TARIFF_GROUPS_UPDATE)}
        >
          {i18n.getMessage('tariffGroupDetails.button.addNewTariff')}
        </Button>
      </div>

      <TariffGroupDetailsFiltersBar
        isLoading={tariffsStore.isLoading}
        tariffListFilters={tariffsStore.tariffListFilters}
        tariffFormData={tariffsStore.tariffFormData}
        handleSetTariffListFilters={tariffsStore.setTariffListFilter}
      />

      {!tariffGroupDetailsForUpdate?.tariffs || tariffGroupDetailsForUpdate?.tariffs?.length === 0 ? (
        <EmptyTableContentPlug
          headerText={i18n.getMessage('tariffGroupDetails.table.emptyPlug.header')}
          descriptionText={i18n.getMessage('tariffGroupDetails.table.emptyPlug.description')}
        />
      ) : (
        <TariffGroupDetailsTable
          isLoading={tariffsStore.isLoading}
          isUpdateTariffGroup={true}
          tariffGroupDetails={tariffGroupDetailsForUpdate}
          setTariffGroupDetails={setTariffGroupDetailsForUpdate}
          handleUpdateTariffGroup={handleUpdateTariffGroup}
          handleDeleteTariffFromTariffGroup={tariffsStore.deleteTariffFromTariffGroup}
          handleClickBackButton={handleClickBackButton}
        />
      )}

      <PopUp alignOnCenter show={isShowAddNewTariffPopUp}>
        <PopUpAddNewTariffToTariffGroupScheme
          isLoading={tariffsStore.isLoading}
          isTariffGroupCreation={false}
          handleClose={handleCloseAddNewTariffPopUp}
          handleAddNewTariffToTariffGroup={handleAddNewTariffToTariffGroup}
          tariffFormData={tariffsStore.tariffFormData}
          handleGetTariffFormData={tariffsStore.getTariffFormData}
        />
      </PopUp>
    </Container>
  );
};

TariffGroupDetailsPage.propTypes = {
  tariffsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  tariffsStore: stores.tariffsStore
}))(observer(TariffGroupDetailsPage));
