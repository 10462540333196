import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { hasRole, LIMIT_GROUPS_UPDATE } from 'services/roles';
import { formatDateTime } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import './LimitGroupsTableRowContent.scss';

export const LimitGroupsTableRowContent = ({
  isLoading,
  handleChangeLimitGroupStatus,
  handleDeleteLimitGroup,
  handleRenameLimitGroup,
  limitGroupData
}) => {
  const i18n = useContext(i18nContext);

  const getLimitGroupInfoBlock = (
    limitGroupBlockInfo,
    isLimitGroupId = false,
    isStatus = false,
    isDeleteLimitGroup = false
  ) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'limit-groups-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (isLimitGroupId) {
      return (
        <NavLink className={'limit-groups-title-link'} to={`${ROUTE_PATHS.LIMITS}/${limitGroupData.limitGroupId}`}>
          {limitGroupBlockInfo}
        </NavLink>
      );
    } else if (isStatus) {
      return (
        <CheckBox
          className={'limit-groups-status-checkbox'}
          name={'hidden'}
          isActive={limitGroupBlockInfo}
          isReverseView={true}
          showCheckBox={true}
          onChange={() => handleChangeLimitGroupStatus(limitGroupData.limitGroupId, !limitGroupBlockInfo)}
          value={i18n.getMessage('limitGroups.table.rowContent.status.enabled')}
          disabled={isLoading || !hasRole(LIMIT_GROUPS_UPDATE)}
        />
      );
    } else if (isDeleteLimitGroup) {
      return (
        <div className={'limit-groups-table-cell-actions'}>
          <Button onClick={() => handleRenameLimitGroup(limitGroupData.limitGroupId)} type={'primary'} size={'small'}>
            {i18n.getMessage('limitGroups.table.rowContent.actions.rename')}
          </Button>
          <Button onClick={() => handleDeleteLimitGroup(limitGroupData.limitGroupId)} type={'primary'} size={'small'}>
            {i18n.getMessage('limitGroups.table.rowContent.actions.delete')}
          </Button>
        </div>
      );
    } else {
      return <p>{limitGroupBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'limit-groups-table-cell'}>{getLimitGroupInfoBlock(limitGroupData.limitGroupId, true)}</td>
      <td className={'limit-groups-table-cell'}>{getLimitGroupInfoBlock(formatDateTime(limitGroupData.createdAt))}</td>
      <td className={'limit-groups-table-cell'}>{getLimitGroupInfoBlock(formatDateTime(limitGroupData.updatedAt))}</td>
      <td className={'limit-groups-table-cell'}>{getLimitGroupInfoBlock(limitGroupData.customersCount)}</td>
      <td className={'limit-groups-table-cell'}>{getLimitGroupInfoBlock(limitGroupData.enabled, false, true)}</td>
      <td className={'limit-groups-table-cell'}>{getLimitGroupInfoBlock(limitGroupData, false, false, true)}</td>
    </tr>
  );
};

LimitGroupsTableRowContent.propTypes = {
  isLoading: PropTypes.bool,
  handleChangeLimitGroupStatus: PropTypes.func,
  handleDeleteLimitGroup: PropTypes.func,
  handleRenameLimitGroup: PropTypes.func,
  limitGroupData: PropTypes.shape({
    limitGroupId: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    enabled: PropTypes.bool,
    customersCount: PropTypes.number
  })
};
