import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import i18nContext from 'components/i18n-context';
import {
  AMOUNT_INPUT_VALUE_TYPE,
  COMMON_CURRENCY_PRECISION,
  CRYPTO_CURRENCY_PRECISION,
  FEE_TYPE
} from 'components/constants';
import { momentFromDateAndTime } from 'services/utils';
import AmountInput from 'uikit/AmountInput';
import { CustomTimePicker } from 'uikit/CustomTimePicker';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './TariffInputControl.scss';

export function TariffInputControl({ handleChangeValue, tariffIndex, editable, tariff, isCryptoProvider }) {
  const i18n = useContext(i18nContext);
  const [day, setDay] = useState(1);
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    if (tariff?.cronExpression) {
      const cronParts = tariff.cronExpression.split(' ');

      if (cronParts.length < 5) {
        // eslint-disable-next-line no-console
        console.error('Invalid cron expression:', tariff.cronExpression);
        return;
      }

      const dayValue = cronParts[3];
      const hourValue = Number(cronParts[2]);
      const minuteValue = Number(cronParts[1]);

      if (isNaN(hourValue) || isNaN(minuteValue)) {
        setDay(1);
        setTime(moment().minute(0).hour(0));
      } else {
        const dateTime = momentFromDateAndTime(dayValue, hourValue, minuteValue, 'UTC');

        setDay(dateTime.date());
        setTime(dateTime.toDate());
      }
    }
  }, [tariff]);

  return (
    <div className={'tariff-input-control'}>
      {tariff.feeType === FEE_TYPE.PERCENT && (
        <>
          <div className={'tariff-fixed-value-wrapper'}>
            <AmountInput
              className={'table-cell-input'}
              name={'percentValue'}
              returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
              value={tariff.percentValue}
              placeholder={i18n.getMessage('tariffGroupDetails.table.rowContent.inputValue.placeholder')}
              isDisabled={!editable}
              onChange={(e) => handleChangeValue(tariffIndex, 'percentValue', e.target.value)}
            />
            <div className={'tariff-fixed-value-currency'}>
              {i18n.getMessage('tariffGroupDetails.table.rowContent.inputLabel.percentValue')}
            </div>
          </div>

          <div className={'tariff-fixed-value-wrapper'}>
            <AmountInput
              className={'table-cell-input'}
              name={'min'}
              returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
              value={tariff.min}
              placeholder={i18n.getMessage('tariffGroupDetails.table.rowContent.inputValue.placeholder')}
              isDisabled={!editable}
              onChange={(e) => handleChangeValue(tariffIndex, 'min', e.target.value)}
              precision={isCryptoProvider ? CRYPTO_CURRENCY_PRECISION : COMMON_CURRENCY_PRECISION}
            />
            <div className={'tariff-fixed-value-currency'}>
              {i18n.getMessage('tariffGroupDetails.table.rowContent.inputLabel.min')}
            </div>
          </div>

          <div className={'tariff-fixed-value-wrapper'}>
            <AmountInput
              className={'table-cell-input'}
              name={'max'}
              returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
              value={tariff.max}
              placeholder={i18n.getMessage('tariffGroupDetails.table.rowContent.inputValue.placeholder')}
              isDisabled={!editable}
              onChange={(e) => handleChangeValue(tariffIndex, 'max', e.target.value)}
              precision={isCryptoProvider ? CRYPTO_CURRENCY_PRECISION : COMMON_CURRENCY_PRECISION}
            />
            <div className={'tariff-fixed-value-currency'}>
              {i18n.getMessage('tariffGroupDetails.table.rowContent.inputLabel.max')}
            </div>
          </div>
        </>
      )}

      {(tariff.feeType === FEE_TYPE.FIXED || tariff.scheduled) && (
        <>
          <div className={'tariff-fixed-value-wrapper'}>
            <AmountInput
              className={'table-cell-input'}
              name={'fixedValue'}
              returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
              value={tariff.fixedValue}
              isDisabled={!editable}
              onChange={(e) => handleChangeValue(tariffIndex, 'fixedValue', e.target.value)}
              precision={isCryptoProvider ? CRYPTO_CURRENCY_PRECISION : COMMON_CURRENCY_PRECISION}
            />
            <div className={'tariff-fixed-value-currency'}>{tariff.currency}</div>
          </div>

          {tariff.scheduled && (
            <table className={'tariff-scheduled-table-wrapper'}>
              <tbody className={'tariff-scheduled-table-body'}>
                <tr className={'tariff-scheduled-table-row'}>
                  <td className={'tariff-scheduled-table-cell'}>
                    {i18n.getMessage('tariffGroupDetails.table.rowContent.inputLabel.dayOfMonth')}
                  </td>
                  <td className={'tariff-scheduled-table-cell'}>
                    <div className={'tariff-scheduled-table-input-wrapper'}>
                      <InputDropDown
                        className={'tariff-scheduled-table-cell-input'}
                        id={'day'}
                        name={'day'}
                        options={[...Array(31).keys()].map((day) => {
                          return {
                            key: day + 1,
                            value: day + 1
                          };
                        })}
                        value={day}
                        onChange={(name, value) =>
                          handleChangeValue(tariffIndex, 'cronExpression', { day: value, time: time })
                        }
                        isDisabled={!editable}
                      />
                    </div>
                  </td>
                </tr>

                <tr className={'tariff-scheduled-table-row'}>
                  <td className={'tariff-scheduled-table-cell'}>
                    {i18n.getMessage('tariffGroupDetails.table.rowContent.inputLabel.time')}
                  </td>
                  <td className={'tariff-scheduled-table-cell'}>
                    <div className={'tariff-scheduled-table-input-wrapper'}>
                      <CustomTimePicker
                        className={'tariff-scheduled-table-cell-input-timepicker'}
                        autoComplete={'false'}
                        id={'time'}
                        name={'time'}
                        value={time}
                        onChange={(name, value) =>
                          handleChangeValue(tariffIndex, 'cronExpression', { day: day, time: value })
                        }
                        timeIntervals={1}
                        isShowDeleteTimeButton={false}
                        isDisabled={!editable}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
}

TariffInputControl.propTypes = {
  handleChangeValue: PropTypes.func,
  editable: PropTypes.bool,
  isCryptoProvider: PropTypes.bool,
  tariffIndex: PropTypes.number,
  tariff: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      tariffGroupId: PropTypes.string,
      systemTariffKey: PropTypes.string,
      feeType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      scheduled: PropTypes.bool,
      transactional: PropTypes.bool,
      transactionType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      transferProvider: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      transferType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      paymentMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      currency: PropTypes.string,
      network: PropTypes.string,
      fixedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      percentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      cronExpression: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      enabled: PropTypes.bool,
      individualTariff: PropTypes.bool
    }),
    PropTypes.oneOf([null])
  ])
};
