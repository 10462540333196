import { I18nManager } from '@opuscapita/i18n';
import translations from './translations';

const locale =
  navigator.languages && navigator.languages?.length
    ? navigator.languages[0]
    : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';

const i18n = new I18nManager({ locale });
i18n.register('Finmatic', translations);
export default i18n;
