import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from 'react-bootstrap/Table';
import { TariffGroupDetailsTableHeadRow } from './components/TariffGroupDetailsTableHeadRow';
import { TariffGroupDetailsTableRowContent } from './components/TariffGroupDetailsTableRowContent';
import { POP_UP_TYPE } from 'components/constants';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { PopConfirmationScheme } from 'components/PopUpScheme/PopConfirmationScheme';
import { hasRole, TARIFF_GROUPS_UPDATE } from 'services/roles';
import Button from 'uikit/Button/Button';
import { PopUp } from 'uikit/PopUp/PopUp';
import './TariffGroupDetailsTable.scss';

const popUpInitialData = {
  isShowPopUp: false,
  popUpType: '',
  title: '',
  description: '',
  confirmButtonLabel: '',
  cancelButtonLabel: '',
  onCancel: () => {},
  onConfirm: () => {}
};

const TariffGroupDetailsTable = ({
  isLoading,
  isUpdateTariffGroup,
  tariffGroupDetails,
  setTariffGroupDetails,
  handleUpdateTariffGroup,
  handleDeleteTariffFromTariffGroup,
  handleClickBackButton
}) => {
  const i18n = useContext(i18nContext);
  const [popUpData, setPopUpData] = useState(popUpInitialData);

  const handleChangeTariffValue = (tariffIndex, field, value) => {
    setTariffGroupDetails((prevState) => ({
      ...prevState,
      tariffs: prevState.tariffs.map((tariff, index) => {
        if (index !== tariffIndex) return tariff;

        if ((field === 'min' || field === 'max') && (value === '' || value === null)) {
          return {
            ...tariff,
            [field]: null
          };
        }

        if (field === 'cronExpression') {
          const { day, time } = value;

          const parseOutDate = (date) => {
            return date > 28 ? 'L' : date;
          };

          const utcTime = moment(time).utc();

          return {
            ...tariff,
            cronExpression: `0 ${utcTime.minute()} ${utcTime.hour()} ${parseOutDate(day)} * ? *`
          };
        }

        return {
          ...tariff,
          [field]: value
        };
      })
    }));
  };

  const handleChangeTariffStatus = (tariffIndex, tariffStatus) => {
    setTariffGroupDetails({
      ...tariffGroupDetails,
      tariffs: tariffGroupDetails.tariffs.map((tariff, index) => {
        if (index === tariffIndex) {
          return {
            ...tariff,
            enabled: tariffStatus
          };
        }
        return tariff;
      })
    });
  };

  const handleDeleteTariff = (tariffIndex) => {
    setTariffGroupDetails({
      ...tariffGroupDetails,
      tariffs: tariffGroupDetails.tariffs.filter((tariff, index) => index !== tariffIndex)
    });
  };

  const resetPopUpData = () => {
    setPopUpData(popUpInitialData);
  };

  const handleConfirmTariffDeletion = async (tariffIndexOrTariffId) => {
    setPopUpData({
      isShowPopUp: true,
      popUpType: POP_UP_TYPE.CONFIRMATION,
      title: i18n.getMessage('tariffGroupDetails.confirmModal.deleteTariff.title'),
      description: i18n.getMessage('tariffGroupDetails.confirmModal.deleteTariff.message'),
      confirmButtonLabel: i18n.getMessage('tariffGroupDetails.confirmModal.button.confirm'),
      cancelButtonLabel: i18n.getMessage('tariffGroupDetails.confirmModal.button.cancel'),
      onConfirm: async () => {
        if (isUpdateTariffGroup) {
          await handleDeleteTariffFromTariffGroup(tariffGroupDetails.tariffGroupId, tariffIndexOrTariffId);
        } else {
          handleDeleteTariff(tariffIndexOrTariffId);
        }
        resetPopUpData();
      },
      onCancel: resetPopUpData
    });
  };

  const handleClickSaveChangesButton = async () => {
    await handleUpdateTariffGroup(tariffGroupDetails.tariffs);
  };

  if (!tariffGroupDetails) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <div className={'tariff-group-details-table-wrapper'}>
      <Table responsive>
        <thead>
          <TariffGroupDetailsTableHeadRow isUpdateTariffGroup={isUpdateTariffGroup} />
        </thead>
        <tbody>
          {tariffGroupDetails?.tariffs?.map((tariffData, index) => (
            <TariffGroupDetailsTableRowContent
              key={index}
              type={'list'}
              isLoading={isLoading}
              isUpdateTariffGroup={isUpdateTariffGroup}
              tariffData={tariffData}
              tariffIndex={index}
              handleChangeTariffValue={handleChangeTariffValue}
              handleChangeTariffGroupStatus={handleChangeTariffStatus}
              handleDeleteTariff={handleConfirmTariffDeletion}
            />
          ))}
        </tbody>
      </Table>

      {isUpdateTariffGroup && (
        <div className={'tariff-group-details-table-actions-wrapper'}>
          <Button
            className={'tariff-group-details-table-button'}
            size={'medium'}
            type={'outline'}
            onClick={handleClickBackButton}
            isDisabled={isLoading}
          >
            {i18n.getMessage('tariffGroupDetails.button.back')}
          </Button>
          <Button
            className={'tariff-group-details-table-button'}
            roleType={'button'}
            size={'medium'}
            type={'primary'}
            onClick={handleClickSaveChangesButton}
            isDisabled={isLoading || !hasRole(TARIFF_GROUPS_UPDATE)}
          >
            {i18n.getMessage('tariffGroupDetails.button.saveChanges')}
          </Button>
        </div>
      )}

      <PopUp alignOnCenter show={popUpData.isShowPopUp}>
        <PopConfirmationScheme
          isLoading={isLoading}
          title={popUpData.title}
          description={popUpData.description}
          onConfirmButtonTitle={popUpData.confirmButtonLabel}
          onCloseButtonTitle={popUpData.cancelButtonLabel}
          onConfirm={popUpData.onConfirm}
          onClose={popUpData.onCancel}
        />
      </PopUp>
    </div>
  );
};

TariffGroupDetailsTable.propTypes = {
  isLoading: PropTypes.bool,
  isUpdateTariffGroup: PropTypes.bool,
  handleUpdateTariffGroup: PropTypes.func,
  handleDeleteTariffFromTariffGroup: PropTypes.func,
  handleClickBackButton: PropTypes.func,
  setTariffGroupDetails: PropTypes.func,
  tariffGroupDetails: PropTypes.oneOfType([
    PropTypes.shape({
      tariffGroupId: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      enabled: PropTypes.bool,
      customersCount: PropTypes.number,
      tariffs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          tariffGroupId: PropTypes.string,
          systemTariffKey: PropTypes.string,
          feeType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          scheduled: PropTypes.bool,
          transactional: PropTypes.bool,
          transactionType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          transferProvider: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          transferType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          paymentMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          currency: PropTypes.string,
          network: PropTypes.string,
          fixedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          percentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          cronExpression: PropTypes.string,
          createdAt: PropTypes.string,
          updatedAt: PropTypes.string,
          enabled: PropTypes.bool,
          individualTariff: PropTypes.bool
        })
      )
    }),
    PropTypes.shape({
      tariffGroupId: PropTypes.string,
      tariffs: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          transactionType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          transferProvider: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          transferType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          paymentMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          currency: PropTypes.string,
          network: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
          fixedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])]),
          percentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])]),
          min: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])]),
          max: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])]),
          enabled: PropTypes.bool
        })
      )
    }),
    PropTypes.oneOf([null])
  ])
};

export default TariffGroupDetailsTable;
