import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { hasRole, TARIFF_GROUPS_UPDATE } from 'services/roles';
import { formatDateTime } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import './TariffGroupsTableRowContent.scss';

export const TariffGroupsTableRowContent = ({
  isLoading,
  handleChangeTariffGroupStatus,
  handleDeleteTariffGroup,
  handleRenameTariffGroup,
  tariffGroupData
}) => {
  const i18n = useContext(i18nContext);

  const getTariffGroupInfoBlock = (
    tariffGroupBlockInfo,
    isTariffGroupId = false,
    isStatus = false,
    isDeleteTariffGroup = false
  ) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'tariff-groups-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (isTariffGroupId) {
      return (
        <NavLink className={'tariff-groups-title-link'} to={`${ROUTE_PATHS.TARIFFS}/${tariffGroupData.tariffGroupId}`}>
          {tariffGroupBlockInfo}
        </NavLink>
      );
    } else if (isStatus) {
      return (
        <CheckBox
          className={'tariff-groups-status-checkbox'}
          name={'hidden'}
          isActive={tariffGroupBlockInfo}
          isReverseView={true}
          showCheckBox={true}
          onChange={() => handleChangeTariffGroupStatus(tariffGroupData.tariffGroupId, !tariffGroupBlockInfo)}
          value={i18n.getMessage('tariffGroups.table.rowContent.status.enabled')}
          disabled={isLoading || !hasRole(TARIFF_GROUPS_UPDATE)}
        />
      );
    } else if (isDeleteTariffGroup) {
      return (
        <div className={'tariff-groups-table-cell-actions'}>
          <Button
            onClick={() => handleRenameTariffGroup(tariffGroupData.tariffGroupId)}
            type={'primary'}
            size={'small'}
          >
            {i18n.getMessage('tariffGroups.table.rowContent.actions.rename')}
          </Button>
          <Button
            onClick={() => handleDeleteTariffGroup(tariffGroupData.tariffGroupId)}
            type={'primary'}
            size={'small'}
          >
            {i18n.getMessage('tariffGroups.table.rowContent.actions.delete')}
          </Button>
        </div>
      );
    } else {
      return <p>{tariffGroupBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'tariff-groups-table-cell'}>{getTariffGroupInfoBlock(tariffGroupData.tariffGroupId, true)}</td>
      <td className={'tariff-groups-table-cell'}>
        {getTariffGroupInfoBlock(formatDateTime(tariffGroupData.createdAt))}
      </td>
      <td className={'tariff-groups-table-cell'}>
        {getTariffGroupInfoBlock(formatDateTime(tariffGroupData.updatedAt))}
      </td>
      <td className={'tariff-groups-table-cell'}>{getTariffGroupInfoBlock(tariffGroupData.customersCount)}</td>
      <td className={'tariff-groups-table-cell'}>{getTariffGroupInfoBlock(tariffGroupData.enabled, false, true)}</td>
      <td className={'tariff-groups-table-cell'}>{getTariffGroupInfoBlock(tariffGroupData, false, false, true)}</td>
    </tr>
  );
};

TariffGroupsTableRowContent.propTypes = {
  isLoading: PropTypes.bool,
  handleChangeTariffGroupStatus: PropTypes.func,
  handleDeleteTariffGroup: PropTypes.func,
  handleRenameTariffGroup: PropTypes.func,
  tariffGroupData: PropTypes.shape({
    tariffGroupId: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    enabled: PropTypes.bool,
    customersCount: PropTypes.number
  })
};
