import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import debounce from 'lodash-es/debounce';
import Table from 'react-bootstrap/Table';
import queryString from 'query-string';
import { MarkupGroupsTableHeadRow } from './components/MarkupGroupsTableHeadRow';
import { MarkupGroupsTableRowContent } from './components/MarkupGroupsTableRowContent';
import PopUpRenameMarkupGroupScheme from './components/PopUpRenameMarkupGroupScheme';
import { DIRECTION, POP_UP_TYPE } from 'components/constants';
import CustomSearchInput from 'components/CustomSearchInput';
import EmptyTableContentPlug from 'components/EmptyTableContentPlug';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import { PopConfirmationScheme } from 'components/PopUpScheme/PopConfirmationScheme';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import { PopUp } from 'uikit/PopUp/PopUp';
import './MarkupGroupsTable.scss';

const popUpInitialData = {
  isShowPopUp: false,
  markupGroupId: null,
  popUpType: '',
  title: '',
  description: '',
  confirmButtonLabel: '',
  cancelButtonLabel: '',
  onCancel: () => {},
  onConfirm: () => {}
};

const MarkupGroupsTable = ({ markupsStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(
    markupsStore.filters.searchText ? markupsStore.filters.searchText : ''
  );
  const [popUpData, setPopUpData] = useState(popUpInitialData);

  const updateFiltersInUrl = (filters, pagination) => {
    const queryParams = {
      page: pagination.page,
      size: pagination.size,
      sort_direction: pagination.direction
    };

    if (filters.searchText) {
      queryParams.searchText = filters.searchText;
    }

    if (pagination.sortBy) {
      queryParams.sort_column = pagination.sortBy;
    }

    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    markupsStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!markupsStore.isInitialized) {
      markupsStore.getMarkupGroupsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markupsStore.isInitialized]);

  useEffect(() => {
    if (markupsStore.isInitialized) {
      markupsStore.resetMarkupsStore();
      loadFiltersFromUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(markupsStore.filters, markupsStore.pagination);
    if (markupsStore.isInitialized) {
      markupsStore.getMarkupGroupsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    markupsStore.filters.searchText,
    markupsStore.pagination.sortBy,
    markupsStore.pagination.direction,
    markupsStore.pagination.size,
    markupsStore.pagination.page
  ]);

  useEffect(() => {
    if (markupsStore.isMarkupGroupSuccessfullyDeleted) {
      markupsStore.getMarkupGroupsList();
      markupsStore.setIsMarkupGroupSuccessfullyDeleted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markupsStore.isMarkupGroupSuccessfullyDeleted]);

  const handleClickCreateMarkupGroupButton = () => {
    navigate(ROUTE_PATHS.MARKUPS_CREATE_GROUP);
  };

  const handleSetCurrentPage = (pageNumber) => {
    markupsStore.setMarkupGroupsPage(pageNumber);
  };

  const handleSetPageSize = (size) => {
    markupsStore.setMarkupGroupsPageSize(size);
  };

  const handleOnSortTable = (newSortBy) => {
    markupsStore.setSortData({
      sortBy: newSortBy,
      direction:
        markupsStore.pagination.sortBy !== newSortBy
          ? DIRECTION.ASC
          : markupsStore.pagination.direction === DIRECTION.ASC
            ? DIRECTION.DESC
            : DIRECTION.ASC
    });
  };

  const debouncedSearch = useRef(
    debounce((name, value) => {
      markupsStore.setFilter(name, value);
    }, 400)
  ).current;

  const handleSearchTextInputChange = ({ target: { name, value } }) => {
    setSearchValue(value);

    debouncedSearch(name, value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const resetPopUpData = () => {
    setPopUpData(popUpInitialData);
  };

  const handleConfirmMarkupDeletion = async (markupGroupId) => {
    setPopUpData({
      isShowPopUp: true,
      popUpType: POP_UP_TYPE.CONFIRMATION,
      title: i18n.getMessage('markupGroups.confirmModal.deleteMarkup.title'),
      description: i18n.getMessage('markupGroups.confirmModal.deleteMarkup.message'),
      confirmButtonLabel: i18n.getMessage('markupGroups.confirmModal.button.confirm'),
      cancelButtonLabel: i18n.getMessage('markupGroups.confirmModal.button.cancel'),
      onConfirm: async () => {
        await markupsStore.deleteMarkupGroup(markupGroupId);
        resetPopUpData();
      },
      onCancel: resetPopUpData
    });
  };

  const handleRenameMarkupGroup = async (markupGroupId) => {
    setPopUpData({
      isShowPopUp: true,
      popUpType: 'RENAME_LIMIT_GROUP',
      markupGroupId: markupGroupId,
      onConfirm: async (markupGroupId, data) => {
        await markupsStore.renameMarkupGroup(markupGroupId, data);
        resetPopUpData();
      },
      onCancel: resetPopUpData
    });
  };

  if (!markupsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <div className={'markup-groups-wrapper'}>
      <div className={'markup-groups-actions-wrapper'}>
        <CustomSearchInput
          wrapperClassName={'search-wrapper'}
          inputClassName={'markup-groups-filters-wrapper'}
          label={i18n.getMessage('markupGroups.filter.search.label')}
          placeholder={i18n.getMessage('markupGroups.filter.search.placeholder')}
          name={'searchText'}
          value={searchValue}
          onChange={handleSearchTextInputChange}
          totalElements={markupsStore.pagination.totalElements}
        />

        <Button
          className={'markup-groups-button'}
          size={'medium'}
          type={'primary'}
          onClick={handleClickCreateMarkupGroupButton}
          isDisabled={!markupsStore.isInitialized}
        >
          {i18n.getMessage('markupGroups.button.createNewMarkupGroup')}
        </Button>
      </div>

      {!markupsStore.markupGroups || markupsStore.markupGroups?.length === 0 ? (
        <EmptyTableContentPlug
          headerText={i18n.getMessage('markupGroups.table.emptyPlug.header')}
          descriptionText={i18n.getMessage('markupGroups.table.emptyPlug.description')}
        />
      ) : (
        <>
          <Pagination
            isShowPaginationSize={true}
            paginationSize={markupsStore.pagination.size}
            handleChangePaginationSize={handleSetPageSize}
            isLoading={markupsStore.isLoading}
            currentPage={markupsStore.pagination.page}
            numberOfPages={markupsStore.pagination.totalPages}
            setCurrentPage={handleSetCurrentPage}
          />

          <Table responsive>
            <thead>
              <MarkupGroupsTableHeadRow
                handleOnSortTable={handleOnSortTable}
                sortBy={markupsStore.pagination.sortBy}
                direction={markupsStore.pagination.direction}
              />
            </thead>
            <tbody>
              {markupsStore.markupGroups?.map((markupGroup, index) => (
                <MarkupGroupsTableRowContent
                  key={index}
                  type={'list'}
                  isLoading={markupsStore.isLoading}
                  markupGroupData={markupGroup}
                  handleDeleteMarkupGroup={handleConfirmMarkupDeletion}
                  handleRenameMarkupGroup={handleRenameMarkupGroup}
                />
              ))}
            </tbody>
          </Table>

          <Pagination
            isShowPaginationSize={true}
            paginationSize={markupsStore.pagination.size}
            handleChangePaginationSize={handleSetPageSize}
            isLoading={markupsStore.isLoading}
            currentPage={markupsStore.pagination.page}
            numberOfPages={markupsStore.pagination.totalPages}
            setCurrentPage={handleSetCurrentPage}
          />
        </>
      )}

      <PopUp alignOnCenter show={popUpData.isShowPopUp}>
        {popUpData.popUpType === POP_UP_TYPE.CONFIRMATION ? (
          <PopConfirmationScheme
            isLoading={markupsStore.isLoading}
            title={popUpData.title}
            description={popUpData.description}
            onConfirmButtonTitle={popUpData.confirmButtonLabel}
            onCloseButtonTitle={popUpData.cancelButtonLabel}
            onConfirm={popUpData.onConfirm}
            onClose={popUpData.onCancel}
          />
        ) : (
          <PopUpRenameMarkupGroupScheme
            isLoading={markupsStore.isLoading}
            markupGroupId={popUpData.markupGroupId}
            handleClose={popUpData.onCancel}
            handleRenameMarkupGroup={popUpData.onConfirm}
          />
        )}
      </PopUp>
    </div>
  );
};

MarkupGroupsTable.propTypes = {
  markupsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  markupsStore: stores.markupsStore
}))(observer(MarkupGroupsTable));
