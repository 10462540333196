import { useContext } from 'react';
import PropTypes from 'prop-types';
import { YES_OR_NO } from 'components/constants';
import i18nContext from 'components/i18n-context';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';
import './CustomerTariffDetailsFiltersBar.scss';

const CustomerTariffDetailsFiltersBar = ({
  isLoading,
  tariffListFilters,
  handleSetTariffListFilters,
  tariffFormData
}) => {
  const i18n = useContext(i18nContext);

  const feeTypeOptions = tariffFormData?.feeTypes?.map((feeType) => {
    return {
      key: feeType,
      value: i18n.getMessage('tariffFeeType.' + feeType)
    };
  });

  const scheduledOptions = Object.values(YES_OR_NO).map((value) => ({
    key: value,
    value: i18n.getMessage(`${value}`)
  }));

  const transactionalOptions = Object.values(YES_OR_NO).map((value) => ({
    key: value,
    value: i18n.getMessage(`${value}`)
  }));

  const transactionsTypeOptions = tariffFormData?.transactionTypes?.map((type) => {
    return {
      key: type,
      value: i18n.getMessage('transactionType.' + type)
    };
  });

  const transferProviderOptions = tariffFormData?.transferProviders?.map((provider) => {
    return {
      key: provider,
      value: i18n.getMessage('paymentProvider.' + provider)
    };
  });

  const transferTypeOptions = tariffFormData?.transferTypes?.map((type) => {
    return {
      key: type,
      value: i18n.getMessage('transferType.' + type)
    };
  });

  const paymentMethodOptions = tariffFormData?.paymentMethods?.map((method) => {
    return {
      key: method,
      value: i18n.getMessage('paymentMethod.' + method)
    };
  });

  const currencyOptions = tariffFormData?.currencies?.map((currency) => {
    return {
      key: currency,
      value: currency
    };
  });

  const networkOptions = tariffFormData?.networks?.map((network) => {
    return {
      key: network,
      value: network
    };
  });

  return (
    <div className={'customer-tariffs-filters-wrapper'}>
      <InputDropDown
        className={'customer-tariffs-filter-wrapper'}
        label={i18n.getMessage('tariffs.details.filter.feeType.label')}
        name={'fee_type'}
        value={tariffListFilters.fee_type}
        options={feeTypeOptions}
        onChange={handleSetTariffListFilters}
        isMulti
        isDisabled={isLoading}
      />
      <InputDropDown
        className={'customer-tariffs-filter-wrapper'}
        label={i18n.getMessage('tariffs.details.filter.scheduled.label')}
        name={'scheduled'}
        value={tariffListFilters.scheduled}
        options={scheduledOptions}
        onChange={handleSetTariffListFilters}
        isDisabled={isLoading}
      />
      <InputDropDown
        className={'customer-tariffs-filter-wrapper'}
        label={i18n.getMessage('tariffs.details.filter.transactional.label')}
        name={'transactional'}
        value={tariffListFilters.transactional}
        options={transactionalOptions}
        onChange={handleSetTariffListFilters}
        isDisabled={isLoading}
      />
      <InputDropDown
        className={'customer-tariffs-filter-wrapper'}
        label={i18n.getMessage('tariffs.details.filter.transactionType.label')}
        name={'transaction_type'}
        value={tariffListFilters.transaction_type}
        options={transactionsTypeOptions}
        onChange={handleSetTariffListFilters}
        isMulti
        isDisabled={isLoading}
      />
      <InputDropDown
        className={'customer-tariffs-filter-wrapper'}
        label={i18n.getMessage('tariffs.details.filter.transferProvider.label')}
        name={'transfer_provider'}
        value={tariffListFilters.transfer_provider}
        options={transferProviderOptions}
        onChange={handleSetTariffListFilters}
        isMulti
        isDisabled={isLoading}
      />
      <InputDropDown
        className={'customer-tariffs-filter-wrapper'}
        label={i18n.getMessage('tariffs.details.filter.transferType.label')}
        name={'transfer_type'}
        value={tariffListFilters.transfer_type}
        options={transferTypeOptions}
        onChange={handleSetTariffListFilters}
        isMulti
        isDisabled={isLoading}
      />
      <InputDropDown
        className={'customer-tariffs-filter-wrapper'}
        label={i18n.getMessage('tariffs.details.filter.paymentMethod.label')}
        name={'payment_method'}
        value={tariffListFilters.payment_method}
        options={paymentMethodOptions}
        onChange={handleSetTariffListFilters}
        isMulti
        isDisabled={isLoading}
      />
      <InputDropDownSearch
        className={'customer-tariffs-filter-wrapper-input-dropdown-search'}
        label={i18n.getMessage('tariffs.details.filter.currency.label')}
        name={'currency'}
        value={tariffListFilters.currency}
        options={currencyOptions}
        onChange={handleSetTariffListFilters}
        isMulti
        isDisabled={isLoading}
      />
      <InputDropDown
        className={'customer-tariffs-filter-wrapper'}
        label={i18n.getMessage('tariffs.details.filter.network.label')}
        name={'network'}
        value={tariffListFilters.network}
        options={networkOptions}
        onChange={handleSetTariffListFilters}
        isMulti
        isDisabled={isLoading}
      />
      <CheckBox
        className={'customer-tariffs-filter-checkbox'}
        name={'enabled'}
        isActive={tariffListFilters.enabled}
        isReverseView={false}
        showCheckBox={true}
        onChange={() => handleSetTariffListFilters('enabled', !tariffListFilters.enabled)}
        value={i18n.getMessage('tariffs.details.filter.enabled.label')}
        disabled={isLoading}
      />
    </div>
  );
};

CustomerTariffDetailsFiltersBar.propTypes = {
  isLoading: PropTypes.bool,
  isInitialized: PropTypes.bool,
  tariffListFilters: PropTypes.object,
  tariffFormData: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  handleSetTariffListFilters: PropTypes.func
};

export default CustomerTariffDetailsFiltersBar;
