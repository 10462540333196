import { FEE_TYPE } from 'components/constants';

export default (tariffData, isNewTariff = false) => ({
  transactionType: tariffData.transactionType || null,
  transferProvider: tariffData.transferProvider || null,
  transferType: tariffData.transferType || null,
  paymentMethod: tariffData.paymentMethod || null,
  currency: tariffData.currency || null,
  network: tariffData.network || null,
  enabled: tariffData.enabled ?? true,
  ...(isNewTariff
    ? {
        feeType: tariffData.feeType
      }
    : {
        type: tariffData.feeType
      }),
  ...(tariffData.feeType === FEE_TYPE.FIXED && {
    fixedValue: tariffData.fixedValue
  }),
  ...(tariffData.feeType === FEE_TYPE.PERCENT && {
    percentValue: tariffData.percentValue,
    min: tariffData.min ?? null,
    max: tariffData.max ?? null
  })
});
